import React, { useEffect } from "react";
import './App.css'
import VisitingCard from "./components/VisitingCard";
import { Route, Routes } from "react-router-dom";
import AdminPage from "./pages/AdminPage";
import AdminHome from "./pages/admin/AdminHome";
import Addproduct from "./pages/admin/AddProduct";
import ManageProducts from "./pages/admin/ManageProducts";
import CreateCard from "./pages/admin/CreateCard";
import Ratings from "./pages/visitingCcard/Ratings";
import { useDispatch, useSelector } from "react-redux";
import Homepage from './pages/Home/Homepage';
import ManageCards from "./pages/admin/ManageCards";
import ViewCard from "./pages/admin/ViewCard";
import { Actions } from "./redux/store";
import EditProduct from './pages/admin/EditProduct';
import ManageCategories from "./pages/admin/ManageCategories";
import ManageMyCard from "./pages/admin/ManageMyCard";
import EditCard from "./pages/admin/EditCard";
import ManageRental from "./pages/admin/ManageRental";
import ManageQuotes from "./pages/admin/ManageQuotes";
import ViewQuote from "./pages/admin/ViewQuote";
import Login from "./pages/client/Login";
import PostService from "./pages/client/PostService";
import GetAllServices from "./pages/admin/GetAllServices";
import ViewServices from "./pages/admin/ViewService";
import AddUser from "./pages/admin/AddUser";
import ViewProduct from "./pages/ViewProduct";
import Store from "./pages/client/Store";
import Search from "./pages/client/Search";
import EngineerHome from "./pages/Home/EngineerHome";
import ViewServiceEng from "./pages/client/ViewServiceEng";
import ManageUsers from "./pages/admin/ManageUsers";
import UpdateUser from "./pages/admin/UpdateUser";
import Cart from "./pages/client/Cart";
import TermsConditions from "./pages/Home/Terms&Conditions";
import RefundPolicy from "./pages/Home/RefundPolicy";
import ShippingPolicy from "./pages/Home/ShippingPolicy";
import Checkout from "./pages/client/Checkout";
import CustLogin from './pages/customer/Login'
import Register from "./pages/customer/Register";
import Success from "./pages/status/Success";
import Orders from "./pages/admin/Orders";
import ViewOrder from "./pages/admin/ViewOrder";
import ViewOrders from "./pages/controllers/ViewOrders";
import ViewService from "./pages/controllers/ViewServices";
import MyOrders from "./pages/customer/MyOrders";
import Failure from "./pages/status/Failure";

const App = () =>{
  const adminState = useSelector(state=>state.adminLogin);
  const customerState = useSelector(state=>state.CustomerLogin);
  const dispatch = useDispatch();
  const CheckAdminAuth = () =>{
    const admin = sessionStorage.getItem("admin");
    if(admin){
      dispatch(Actions.setAdmin(true));
    }
  };
  const CheckCustomerAuth = () =>{
    const customer = localStorage.getItem("SMS_USER_TK");
    if(customer){
      dispatch(Actions.setCustomerLogin(true));
    }
  };
  useEffect(()=>{
    CheckAdminAuth();
    CheckCustomerAuth();
  },[]);
  return(
    <>
      <Routes>
        <Route path="/" element={<Homepage/>}/>
        <Route path="/admin" element={<AdminPage/>}/>
        <Route path="/login" element={<CustLogin/>}/>
        <Route path="/register" element={<Register/>}/>
        {customerState && <Route path="/success/:id/:email/:status/:name" element={<Success/>}/>}
        {adminState && <Route path="/admin/home" element={<AdminHome/>}>
          <Route path="/admin/home/addproduct" element={<Addproduct/>}/>
          <Route path="/admin/home/removeproduct" element={<ManageProducts/>}/>
          <Route path="/admin/home/createCard" element={<CreateCard/>}/>
          <Route path="/admin/home/managecards" element={<ManageCards/>}/>
          <Route path="/admin/home/editproduct/:id" element={<EditProduct/>}/>
          <Route path="/admin/home/manageCategories" element={<ManageCategories/>}/>
          <Route path="/admin/home/managemycard" element={<ManageMyCard/>}/>
          <Route path="/admin/home/managerental" element={<ManageRental/>}/>
          <Route path="/admin/home/editcard/:id" element={<EditCard/>}/>
          <Route path="/admin/home/manageQuotes" element={<ManageQuotes/>}/>
          <Route path="/admin/home/viewQuote/:id" element={<ViewQuote/>}/>
          <Route path="/admin/home/getallservices" element={<GetAllServices/>}/>
          <Route path="/admin/home/viewservice/:id" element={<ViewServices/>}/>
          <Route path="/admin/home/adduser" element={<AddUser/>}/>
          <Route path="/admin/home/manageusers" element={<ManageUsers/>}/>
          <Route path="/admin/home/updateuser/:id" element={<UpdateUser/>}/>
          <Route path="/admin/home/orders" element={<Orders/>}/>
          <Route path="/admin/home/orders/vieworder/:id" element={<ViewOrder/>}/>
        </Route>}
        <Route path="/visitingCard/ratings" element={<Ratings/>}/> 
        <Route path="/mycard" element={<VisitingCard/>}/>   
        <Route path="/services/login" element={<Login/>}/>
        <Route path="/viewcard/:id" element={<ViewCard/>}/>
        <Route path="/services" element={<PostService/>}/>
        <Route path="/product/:id" element={<ViewProduct/>}/>
        <Route path="/store" element={<Store/>}/>
        <Route path="/search/:keyword" element={<Search/>}/>
        <Route path="/engineer" element={<EngineerHome/>}/>
        <Route path="/viewServiceEng/:id" element={<ViewServiceEng/>}/>
        <Route path="/cart" element={<Cart/>}/>
        <Route path="/terms-and-conditions" element={<TermsConditions/>}/>
        <Route path="/refundPolicy" element={<RefundPolicy/>}/>
        <Route path="/shippingPolicy" element={<ShippingPolicy/>}/>
        {customerState && <Route path="/checkout" element={<Checkout/>}/>}
        <Route path="/controller/vieworders" element={<ViewOrders/>}/>
        <Route path="/controller/viewservices" element={<ViewService/>}/>
        <Route path="/myorders" element={<MyOrders/>}/>
        <Route path="/failure/:id" element={<Failure/>}/>
        <Route path="*" element={<h1>404 Page Not found !</h1>}/>
      </Routes>
    </>
  );
};

export default App;