import React, { useEffect, useState } from "react";
import Logo from "../assets/VisitingCard/logo.jpg";
import OldTypicalPhone from "../assets/VisitingCard/old-typical-phone.png";
import Mail from "../assets/VisitingCard/mail.png";
import WhatsApp from "../assets/VisitingCard/whatsapp.png";
import Web from "../assets/VisitingCard/web.png";
import Iphone from "../assets/VisitingCard/iphone.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FaceBook from "../assets/VisitingCard/facebook-circular-logo.png";
import Instagram from "../assets/VisitingCard/instagram.png";
import linkedin from "../assets/VisitingCard/linkedin.png";
import qr from "../assets/VisitingCard/qr.png";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import cardqr from '../assets/VisitingCard/cardqr.png';
import axios from 'axios';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Rating,
  Avatar,
  Dialog,
  Box,
  LinearProgress,
  CircularProgress,
} from "@mui/material";
import BASE from "../api/apis";
import { useNavigate } from "react-router-dom";
import { v4 } from "uuid";
import Product from "./VisitingCard/Product";
import Rental from "./VisitingCard/Rental";
import PaymentQR from '../assets/VisitingCard/paymentQR.jpeg'

const VisitingCard = () => {
  const [qrDialog,setqrDialog] = useState(false);
  const [products, setProducts] = useState([]);
  const [rental,setRental] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formState,setformState] = React.useState(false);
  const [share,setShare] = React.useState(false);
  const [vcardqr,setvcardqr] = useState(false);
  const [cardLoad,setCardLoad] = useState(false);
  const [formItem,setformItem] = useState("");
  const [card,setCard] = useState({});
  const VCF = 'https://smsmd.co.in/kishanrajbojja.vcf';
  const [quoteData,setQuoteData] = useState({
    Name:"",
    Email:"",
    Mobile:"",
    Whatsapp:"",
    quantity:"",
    Product:""
  });

  const changeQuoteHandler = e =>{
    setQuoteData({...quoteData,[e.target.name]:e.target.value});
  }

  const [quoteLoading,setQuoteLoading] = useState(false);

  const FetchProducts = async () => {
    setLoading(true);
    await fetch(`${BASE}/product/`,{
      method:"GET",
      headers:{
        "Content-Type":"application/json"
      }
    }).then(res=>{
      res.json().then(dta=>{
        setLoading(false);
        setProducts(dta.products);
      })
    }).catch(err=>{
      setLoading(false);
      console.log(err);
    })
  };

  const RegisterQuote = async () =>{
    setQuoteLoading(true);
    axios.post(`${BASE}/quote`,quoteData).then(resp=>{
      setQuoteLoading(false)
      alert(resp.data.message);
    }).catch(err=>{
      setQuoteLoading(false)
      console.log(err);
    })  
  };

  const FetchRental = async () =>{
    await fetch(`${BASE}/product/rental`,{
      method:"GET",
      headers:{
        "Content-Type":"application/json"
      }
    }).then(resp=>{
      resp.json().then((dta)=>{
        setRental(dta.products);
      })
    })
  };

  const FetchCard = async () =>{
    setCardLoad(true);
    await fetch(`${BASE}/visitingcard/651a474b1fcffe4c50f9736b`,{
      method:"GET",
      headers:{
        "Content-Type":"application/json"
      }
    }).then(data=>{
      data.json().then(dta=>{
        setCard(dta.card);
        setCardLoad(false);
        FetchProducts();
        FetchRental();
      }).catch(err=>{
        console.log(err);
      })
    }).catch(err=>{
      console.log(err);
    })
  }
  useEffect(() => {
    FetchCard();
  }, []);

  const navigate = useNavigate();

  return (
    <>
      <div className="h-16 bg-theme hidden md:block"></div>
      <div className=" bg-blue-200 h-64 flex-row items-end justify-end pr-20 pb-10 gap-5 hidden md:flex deskcover">
        <div className="flex flex-row items-center gap-2 cursor-pointer p-4 bg-theme rounded-md text-white">
          <span className="material-symbols-outlined">share</span>
          <p>Share</p>
        </div>
        <a href={VCF} download>
          <div className="flex flex-row items-center gap-2 cursor-pointer p-4 bg-theme rounded-md text-white" onClick={()=>{
            
          }}>
            <span className="material-symbols-outlined">person_add</span>
            <p>Add to Contacts</p>
          </div>
        </a>
      </div>
      {cardLoad && <LinearProgress/>}
      <div className="container mx-auto md:flex md:flex-row">
        {!cardLoad && <div>
          <div className="mainCon border border-gray-300 md:w-96 bg-white rounded-lg md:-mt-32 md:ml-20">
            <div className="flex flex-row border justify-between items-center p-2">
              <div className="flex flex-row items-center gap-2">
                <img src={Logo} alt="logo" width="50px" height="50px" />
                <div className="flex flex-col">
                  <p className="font-semibold">{card.businessName}</p>
                  <p className="text-sm text-gray-600">Medical Equipment Sales & Service</p>
                </div>
              </div>
              <a href={card.location} target="_blank">
              <div className="flex flex-col cursor-pointer">
                <span className="material-symbols-outlined p-2 bg-theme rounded-full text-white">
                  location_on
                </span>
              </div>
              </a>
            </div>
            <div className="h-24 bg-blue-300 mainCover"></div>
            <div className="flex flex-row">
              <img
                src={card.profile}
                alt="profile"
                width="110px"
                height="110px"
                className="outline-2 outline-double outline-offset-2 outline-blue-700 mt-2 mx-2"
              />
              <div>
                <h1 className="text-2xl mt-7 mx-2">{card.firstname} {card.lastname}</h1>
                <p className="ml-2">{card.jobtitle}</p>
              </div>
              <div className="cursor-pointer">
                <span className="material-symbols-outlined p-2 bg-theme rounded-full text-white mt-7 mr-2" onClick={()=>setShare(true)}>
                  share
                </span>
                <div className="flex flex-col">
                  <span className="material-symbols-outlined p-2 bg-theme rounded-full text-white mt-7 mr-2">
                    store
                  </span>
                  <h1 className="text-sm">Store</h1>
                </div>
              </div>
              <Dialog open={share} onClose={()=>setShare(false)}>
                <Box sx={{width:"300px",height:"100px",display:"flex",justifyContent:"center",alignItems:"center"}}>
                  <a href="whatsapp://send?text=https://smsmd.co.in/mycard" data-action="share/whatsapp/share"><div className="p-2"><WhatsAppIcon className="text-green-700 cursor-pointer"/> Share on Whatsapp</div></a>
                </Box>
              </Dialog>
            </div>
            <div>
              <div className="flex flex-row justify-center pt-4 gap-5">
                <a href={`tel:+91${card.office}`}>
                  <div className="flex flex-col items-center">
                    <img
                      src={OldTypicalPhone}
                      alt="Office"
                      width="40px"
                      height="40px"
                    />
                    <p className="text-xs">Office</p>
                  </div>
                </a>
                <a href={`mailto:${card.email}`}>
                  <div className="flex flex-col items-center">
                    <img src={Mail} alt="email" width="40px" height="40px" />
                    <p className="text-xs">Email</p>
                  </div>
                </a>
                <a href={`https://api.whatsapp.com/send?phone=+91${card.whatsapp}&text=Hello !`}>
                  <div className="flex flex-col items-center">
                    <img
                      src={WhatsApp}
                      alt="whatsapp"
                      width="40px"
                      height="40px"
                    />
                    <p className="text-xs">Whatsapp</p>
                  </div>
                </a>
                <a href={card.website} target="_blank">
                <div className="flex flex-col items-center">
                  <img src={Web} alt="web" width="40px" height="40px" />
                  <p className="text-xs">Website</p>
                </div>
                </a>
                <a href={`tel:${card.mobile}`}>
                <div className="flex flex-col items-center">
                  <img
                    src={Iphone}
                    alt="phone"
                    width="40px"
                    height="40px"
                    className="ml-4"
                  />
                  <p className="text-xs">Mobile</p>
                </div>
                </a>
              </div>
              {/*second*/}
              <div className="flex flex-row justify-center pt-4 gap-2">
                <div className="flex flex-col items-center">
                  <img src={FaceBook} alt="Office" width="40px" height="40px" />
                  <p className="text-xs">FaceBook</p>
                </div>
                <div className="flex flex-col items-center" onClick={()=>setvcardqr(true)}>
                  <img src={qr} alt="web" width="40px" height="40px" />
                  <p className="text-xs text-center">VisitingCard QR</p>
                </div>
                <Dialog open={vcardqr} onClose={()=>setvcardqr(false)}>
                  <Box className="w-80 h-80">
                    <span className="material-symbols-outlined absolute right-0 font-bold cursor-pointer" onClick={()=>{
                      setvcardqr(false);
                    }}>close</span>
                    <img src={cardqr}/>
                  </Box>
                </Dialog>
                <div className="flex flex-col items-center">
                  <img src={Instagram} alt="email" width="40px" height="40px" />
                  <p className="text-xs">Instagram</p>
                </div>
                <div className="flex flex-col items-center">
                  <img
                    src={linkedin}
                    alt="whatsapp"
                    width="40px"
                    height="40px"
                  />
                  <p className="text-xs">Linkedin</p>
                </div>
                <div className="flex flex-col items-center cursor-pointer" onClick={()=>{
                  setqrDialog(true);
                }}>
                  <img
                    src={qr}
                    alt="phone"
                    width="40px"
                    height="40px"
                    className="ml-4"
                  />
                  <p className="text-xs text-center">Payment QR</p>
                </div>
                <Dialog open={qrDialog} onClose={()=>{
                  setqrDialog(false);
                }}>
                  <Box sx={{width:"250px"}}>
                    <span className="material-symbols-outlined cursor-pointer font-bold absolute right-0" onClick={()=>{
                      setqrDialog(false);
                    }}>close</span>
                    <img src={PaymentQR} alt="" />
                  </Box>
                </Dialog>
              </div>
            </div>
            <div>
              {card.productsGallery && <div>
                <Splide
                  options={{
                    type: "loop",
                    gap: "10px",
                    drag: "free",
                    arrows: false,
                    pagination: false,
                    perPage: 1,
                    autoScroll: {
                      pauseOnHover: false,
                      pauseOnFocus: false,
                      rewind: false,
                      speed: 3,
                    },
                  }}
                  extensions={{ AutoScroll }}
                >
                  {
                    card.productsGallery.map(prd=>{
                      return(
                        <SplideSlide><img src={prd} className="w-ful h-40"/></SplideSlide>
                      );
                    })
                  }
                </Splide>
              </div>}
              {card.hospitalsGallery ? <div className="mt-2">
                <Splide options={{
                  type: "loop",
                  gap: "10px",
                  drag: "free",
                  arrows: false,
                  pagination: false,
                  perPage: 1,
                  autoScroll: {
                    pauseOnHover: false,
                    pauseOnFocus: false,
                    rewind: false,
                    speed: 3,
                  },
                }}
                  extensions={{ AutoScroll }}>
                  {
                    card.hospitalsGallery.map(itm => {
                      return (
                        <SplideSlide><img src={itm} className="w-full h-40" /></SplideSlide>
                      );
                    })
                  }
                </Splide>
              </div> : ""}
              <div className="md:hidden">
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>About</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      {card.businessDescription}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className="md:hidden">
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    {" "}
                    <Typography>Products</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="flex flex-row overflow-scroll pdiv">
                      {!loading && <div className="flex flex-row gap-2">
                        {products.length > 0
                          ? products.map((prdct) => {
                              return <Product data={prdct} key={v4()} />;
                            })
                          : ""}
                      </div>}
                      {
                        loading && <CircularProgress size={20} className="mx-auto"/>
                      }
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    {" "}
                    <Typography>Training</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="flex flex-row items-center">
                      <span className="material-symbols-outlined text-blue-600">
                        chevron_right
                      </span>
                      <Typography>Internships</Typography>
                    </div>
                    <div className="flex flex-row items-center">
                      <span className="material-symbols-outlined text-blue-600">
                        chevron_right
                      </span>
                      <Typography>Training & Placement</Typography>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className="md:hidden">
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Rental</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="flex flex-row overflow-scroll">
                      {!loading && <div className="flex flex-row gap-2">
                        {rental.length > 0
                          ? rental.map((prdct) => {
                              return(<div
                                className="w-52 border hover:border-gray-400 hover:shadow-2xl p-3"
                                key={v4()}
                              >
                                <img src={prdct.img} width="100%" className="h-40" />
                                <h1 className="p-1 text-xs">
                                  <span className="text-blue-800 font-bold">
                                    Name :
                                  </span>{" "}
                                  {prdct.Name}
                                </h1>
                                <h1 className="text-xs p-1">
                                  <span className="text-blue-800 font-bold">
                                    Make :{" "}
                                  </span>
                                  {prdct.Make}
                                </h1>
                                <h1 className="text-xs p-1">
                                  <span className="text-blue-800 font-bold">
                                    Model :{" "}
                                  </span>
                                  {prdct.Model}
                                </h1>
                                <button className="w-full mt-2 bg-blue-700 p-1 text-white hover:bg-blue-500" onClick={()=>{
                                  setformState(true);
                                  setformItem(prdct.Name);
                                  setQuoteData({...quoteData,Product:prdct._id});
                                }}>
                                  Get a Quote
                                </button>
                              </div>);
                            })
                          : ""}
                      </div>}
                      {
                        loading && <CircularProgress size={20} className="mx-auto"/>
                      }
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    Ratings
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="flex flex-row overflow-scroll ratingCon">
                      <div className="flex flex-row gap-2">
                        <div className="p-2 flex flex-row gap-4 border w-72">
                          <Avatar />
                          <div>
                            <h1>P Sai Prasad</h1>
                            <Rating readOnly value={5} size="small" />
                            <p className="text-xs">
                              Quality products & Good Service
                            </p>
                          </div>
                        </div>
                        <div className="p-2 flex flex-row gap-4 border w-72">
                          <Avatar />
                          <div>
                            <h1>Chitra</h1>
                            <Rating readOnly value={5} size="small" />
                            <p className="text-xs">Good Service</p>
                          </div>
                        </div>
                        <div className="p-2 flex flex-row gap-4 border w-72">
                          <Avatar />
                          <div>
                            <h1>Ravikumar</h1>
                            <Rating readOnly value={5} size="small" />
                          </div>
                        </div>
                        <div className="p-2 flex flex-row gap-4 border w-72">
                          <Avatar />
                          <div>
                            <h1>Avinash</h1>
                            <Rating readOnly value={5} size="small" />
                            <p className="text-xs">Good Quality !</p>
                          </div>
                        </div>
                        <div className="p-2 flex flex-row gap-4 border w-72">
                          <Avatar />
                          <div>
                            <h1>Jayatheertha</h1>
                            <Rating readOnly value={5} size="small" />
                          </div>
                        </div>
                        <div className="p-2 flex flex-row gap-4 border w-72">
                          <Avatar />
                          <div>
                            <h1>Sahil Jain</h1>
                            <Rating readOnly value={5} size="small" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="p-2 text-white bg-blue-800 mt-2 w-32 text-center mx-auto"
                      onClick={() => navigate("/visitingCard/ratings")}
                    >
                      <h1>View More</h1>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon/>}>Services</AccordionSummary>
                  <AccordionDetails>
                    <ul>
                      <li onClick={()=>navigate("/services/login")} className="cursor-pointer">Raise a Complaint</li>
                    </ul>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
            <hr />
            <div className="p-3 bg-theme text-white">
              <h1 className="text-center text-xs">Powered By Sree Medi Systems</h1>
            </div>
          </div>
        </div>}
        <div className="p-3 rightCon hidden md:block">
          {!loading && <div>
            {!cardLoad && <h1 className="text-center font-bold">About</h1>}
            {!cardLoad && <p>
              we started our business in year 2000, as service providers for
              Medical Equipment. we started R & D for various Medical Equipment.
              we started our own Manufacturing division after having experience
              of dealer ship for Sales & Service with various branded medical
              equipment. the Vision is to Manufacture.
            </p>}
          </div>}
          {!cardLoad && <h1 className="text-center font-bold">Our Products</h1>}
          <div className="flex flex-row mt-3">
            <div className="flex flex-row overflow-scroll">
              {products.length > 0 && !loading ? (
                <div className="flex flex-row gap-2">
                  {products.map((prdct) => {
                    return (
                      <div
                        className="w-52 border hover:border-gray-400 hover:shadow-2xl p-3"
                        key={v4()}
                        onClick={()=>navigate(`/product/${prdct._id}`)}
                      >
                        <img src={prdct.img} width="100%" className="h-40" />
                        <h1 className="p-1 text-xs">
                          <span className="text-blue-800 font-bold">
                            Name :
                          </span>{" "}
                          {prdct.Name}
                        </h1>
                        <h1 className="text-xs p-1">
                          <span className="text-blue-800 font-bold">
                            Make :{" "}
                          </span>
                          {prdct.Make}
                        </h1>
                        {prdct.Price && <h1 className="text-xs p-1">
                          <span className="text-blue-800 font-bold">
                            Price : {" "}
                          </span>
                          ₹ {prdct.Price} /-
                        </h1>}
                        <h1 className="text-xs p-1">
                          <span className="text-blue-800 font-bold">
                            Stock : {" "}
                          </span>
                          {prdct.Stock}
                        </h1>
                        <h1 className="text-xs p-1">
                          <span className="text-blue-800 font-bold">
                            Model :{" "}
                          </span>
                          {prdct.Model}
                        </h1>
                        {(prdct.Features.length > 0) && <h1 className="text-xs p-1">Features : </h1>}
                        <ul>
                          {prdct.Features && prdct.Features.map((ft) => {
                            return (
                              <div className="flex flex-row" key={v4()}>
                                <span className="material-symbols-outlined text-blue-900">
                                  arrow_right
                                </span>
                                <h1 className="text-xs">{ft}</h1>
                              </div>
                            );
                          })}
                        </ul>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div>

                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="hidden md:block">
        {!cardLoad && <h1 className="text-center mt-2 font-bold">Rental</h1>}
        <div className="p-3">
          <div className="flex flex-row mt-3">
            <div className="flex flex-row overflow-scroll">
              {rental.length > 0 ? (
                <div className="flex flex-row gap-2">
                  {rental.map((prdct) => {
                    return (
                      <div
                        className="w-52 border hover:border-gray-400 hover:shadow-2xl p-3"
                        key={v4()}
                      >
                        <img src={prdct.img} width="100%" className="h-40" />
                        <h1 className="p-1 text-xs">
                          <span className="text-blue-800 font-bold">
                            Name :
                          </span>{" "}
                          {prdct.Name}
                        </h1>
                        <h1 className="text-xs p-1">
                          <span className="text-blue-800 font-bold">
                            Make :{" "}
                          </span>
                          {prdct.Make}
                        </h1>
                        <h1 className="text-xs p-1">
                          <span className="text-blue-800 font-bold">
                            Model :{" "}
                          </span>
                          {prdct.Model}
                        </h1>
                        <button className="w-full mt-2 bg-blue-700 p-1 text-white hover:bg-blue-500" onClick={()=>{
                          setformState(true);
                          setformItem(prdct.Name);
                          setQuoteData({...quoteData,Product:prdct._id});
                        }}>
                          Get a Quote
                        </button>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="flex flex-row items-center">

                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Dialog open={formState}>
        <Box sx={{width:"350px",height:"auto",padding:"10px"}}>
          <span className="material-symbols-outlined absolute right-1 cursor-pointer" onClick={()=>{
            setformState(false);
          }}>close</span>
          <h1 className="text-center p-1">Get a Quote</h1>
          <div className="flex flex-col items-center justify-center gap-2">
            <input
              type="text"
              placeholder="Enter Name"
              className="border p-2 outline-blue-700 w-70p"
              name="Name"
              onChange={changeQuoteHandler}
            />
            <input
              type="text"
              placeholder="Enter Email"
              className="border p-2 outline-blue-700 w-70p"
              name="Email"
              onChange={changeQuoteHandler}
            />
            <input
              type="number"
              placeholder="Enter Mobile"
              className="border p-2 outline-blue-700 w-70p"
              name="Mobile"
              onChange={changeQuoteHandler}
            />
            <input
              type="number"
              placeholder="Enter Whatsapp Number"
              className="border p-2 outline-blue-700 w-70p"
              name="Whatsapp"
              onChange={changeQuoteHandler}
            />
            <input
              type="text"
              placeholder="Product Name"
              className="border p-2 outline-blue-700 w-70p"
              name="productname"
              value={formItem}
              disabled
            />
            <input
              type="number"
              placeholder="Quantity"
              className="border p-2 outline-blue-700 w-70p"
              name="quantity"
              onChange={changeQuoteHandler}
            />
            {!quoteLoading ? <button
              className="mt-2 bg-blue-700 text-white rounded p-2 hover:bg-blue-500 w-70p"
              onClick={RegisterQuote}>
              Submit
            </button> : <CircularProgress size={24}/>}
          </div>
        </Box>
      </Dialog>
    </>
  );
};

export default VisitingCard;
