import React, { useEffect, useState } from "react";
import Logo from "../../assets/VisitingCard/logo.jpg";
import banner1 from "../../assets/store/banner1.png";
import banner2 from "../../assets/store/banner2.png";
import { Badge } from "@mui/material";
import axios from "axios";
import BASE from "../../api/apis";
import { useNavigate } from "react-router-dom";
import { v4 } from "uuid";
import Footer from "../../components/Home/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import cat1 from '../../assets/store/anesthesia-machine.jpg';
import cat2 from '../../assets/store/anesthesia-workstation.png';
import cat3 from '../../assets/store/patientmonitor.png';
import cat4 from '../../assets/store/dia.png';
import cat5 from '../../assets/store/infusionpump.png';
import cat6 from '../../assets/store/ecg.png';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

const Store = () => {
  const [products, setproducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchItem, setSearchItem] = useState();
  const customerLogin = useSelector((state) => state.CustomerLogin);
  const [cartItems, setCartItems] = useState(
    JSON.parse(localStorage.getItem("cartitems"))?.length
  );
  const navigate = useNavigate();

  const FetchProducts = () => {
    setLoading(true);
    axios.get(`${BASE}/product`).then((resp) => {
      setproducts(resp.data.products);
      setLoading(false);
    });
  };

  const AddToCart = (itm) => {
    const data = JSON.parse(localStorage.getItem("cartitems"));
    if (data === null) {
      const itms = new Array();
      itms.push(itm);
      localStorage.setItem("cartitems", JSON.stringify(itms));
      if (cartItems === undefined) {
        setCartItems(1);
      } else {
        setCartItems((prev) => prev + 1);
      }
    } else {
      data.push(itm);
      localStorage.setItem("cartitems", JSON.stringify(data));
      setCartItems((prev) => prev + 1);
    }
  };

  useEffect(() => {
    FetchProducts();
  }, []);

  return (
    <div>
      <ToastContainer />
      <div className="p-2 flex flex-row justify-between items-center bg-theme">
        <div className="flex flex-row gap-3 items-center">
          <img
            src={Logo}
            alt="logo"
            width="50px"
            height="50px"
            className="rounded-full"
          />
          <h1 className="uppercase text-white">Sree Medi Systems</h1>
        </div>
        <div className="pr-3 flex flex-row gap-3">
          {!customerLogin && (
            <div className="flex flex-row gap-2">
              <h1
                className="text-white cursor-pointer"
                onClick={() => navigate("/login")}
              >
                Login
              </h1>
            </div>
          )}
          <Badge badgeContent={cartItems} color="primary">
            <span
              className="material-symbols-outlined text-white cursor-pointer"
              onClick={() => navigate("/cart")}
            >
              shopping_bag
            </span>
          </Badge>
        </div>
      </div>
      <div>
        <div className="flex flex-row justify-between p-2 items-center gap-2">
          <input
            type="text"
            placeholder="search for products"
            className="border p-2 outline-theme w-full"
            value={searchItem}
            onChange={(e) => {
              setSearchItem(e.target.value);
            }}
          />
          <span
            className="material-symbols-outlined text-blue-900 cursor-pointer"
            onClick={() => {
              navigate(`/search/${searchItem}`);
            }}
          >
            search
          </span>
        </div>
      </div>
      <div>
        <Carousel>
          <div onClick={()=>navigate("/product/64e32dbe70ce03dcb53fd130")}>
            <img src={banner1} alt="" />
          </div>
          <div onClick={()=>navigate("/product/64e1e2e2772300aca989bb50")}>
            <img src={banner2} alt="" />
          </div>
        </Carousel>
      </div>
      <div className="flex flex-row overflow-scroll p-3 md:justify-center">
      <div className="flex flex-row gap-4 p-3">
        <div className="flex flex-col gap-1 justify-center items-center" onClick={()=>navigate("/search/Anesthesia Machines")}>
          <img src={cat1} alt="" className="w-16 h-16 rounded-full md:w-20 md:h-20"/>
          <h1 className="text-xs text-center">Anesthesia Machines</h1>
        </div>
        <div className="flex flex-col gap-1 items-center" onClick={()=>navigate("/search/Anesthesia WorkStations")}>
          <img src={cat2} alt="" className="w-16 h-16 rounded-full md:w-20 md:h-20"/>
          <h1 className="text-xs text-center">Anesthesia WorkStations</h1>
        </div>
        <div className="flex flex-col gap-1 items-center" onClick={()=>navigate("/search/Patient Monitors")}>
          <img src={cat3} alt="" className="w-16 h-16 rounded-full md:w-20 md:h-20"/>
          <h1 className="text-xs text-center">Patient Monitors</h1>
        </div>
        <div className="flex flex-col gap-1 items-center" onClick={()=>navigate("/search/Diathermy Machines")}>
          <img src={cat4} alt="" className="w-16 h-16 rounded-full md:w-20 md:h-20"/>
          <h1 className="text-xs text-center">Diathermy Machines</h1>
        </div>
        <div className="flex flex-col gap-1 items-center" onClick={()=>navigate("/search/Infusion Pumps")}>
          <img src={cat5} alt="" className="w-16 h-16 rounded-full md:w-20 md:h-20"/>
          <h1 className="text-xs text-center">Infusion Pumps</h1>
        </div>
        <div className="flex flex-col gap-1 items-center" onClick={()=>navigate("/search/ECGs")}>
          <img src={cat6} alt="" className="w-16 h-16 rounded-full md:w-20 md:h-20"/>
          <h1 className="text-xs text-center">ECGs</h1>
        </div>
      </div>
      </div>
      {!loading && products.length > 0 && (
        <div>
          <h1 className="p-2 uppercase text-gray-600 md:text-center">
            Best Quality Cots
          </h1>
          <div className="flex flex-row overflow-scroll p-3">
            <div className="flex flex-row gap-2">
              {products.map((prdct) => {
                if (prdct.category === "cots") {
                  return (
                    <div
                      className="w-48 border hover:border-gray-400 hover:shadow-2xl p-3 rounded"
                      key={v4()}
                    >
                      <img
                        src={prdct.img}
                        width="100%"
                        className="h-40"
                        onClick={() => navigate(`/product/${prdct._id}`)}
                      />
                      <h1 className="p-1 text-xs">
                        <span className="text-blue-800 font-bold">Name :</span>{" "}
                        {prdct.Name}
                      </h1>
                      <h1 className="text-xs p-1">
                        <span className="text-blue-800 font-bold">Make : </span>
                        {prdct.Make}
                      </h1>
                      {prdct.Price && (
                        <h1 className="text-xs p-1">
                          <span className="text-blue-800 font-bold">
                            Price :{" "}
                          </span>
                          ₹ {prdct.Price} /-
                        </h1>
                      )}
                      <h1 className="text-xs p-1">
                        <span className="text-blue-800 font-bold">
                          Stock :{" "}
                        </span>
                        {prdct.Stock}
                      </h1>
                      <h1 className="text-xs p-1">
                        <span className="text-blue-800 font-bold">
                          Model :{" "}
                        </span>
                        {prdct.Model}
                      </h1>
                      <div className="">
                        <button className="bg-blue-800 w-full p-2 text-white">
                          Buy
                        </button>
                        <button
                          className="bg-white w-full mt-2 flex flex-row items-center justify-center gap-2"
                          onClick={() => {
                            AddToCart(prdct);
                            toast("Added to cart", {
                              type: "success",
                            });
                          }}
                        >
                          <span className="material-symbols-outlined text-base">
                            shopping_bag
                          </span>
                          Add to Cart
                        </button>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>
          <h1 className="p-2 uppercase text-gray-600 md:text-center">
            Our Products
          </h1>
          <div className="flex flex-row mt-2 pl-1">
            <div className="flex flex-row flex-wrap gap-2 justify-center">
              {products.map((prdct) => {
                if (prdct.category !== "cots")
                  return (
                    <div
                      className="md:w-44 w-36 border hover:border-gray-400 hover:shadow-2xl p-3 rounded"
                      key={v4()}
                    >
                      <img
                        src={prdct.img}
                        width="100%"
                        className="h-40"
                        onClick={() => navigate(`/product/${prdct._id}`)}
                      />
                      <h1 className="p-1 text-xs">
                        <span className="text-blue-800 font-bold">Name :</span>{" "}
                        {prdct.Name}
                      </h1>
                      <h1 className="text-xs p-1">
                        <span className="text-blue-800 font-bold">Make : </span>
                        {prdct.Make}
                      </h1>
                      {prdct.Price && (
                        <h1 className="text-xs p-1">
                          <span className="text-blue-800 font-bold">
                            Price :{" "}
                          </span>
                          ₹ {prdct.Price} /-
                        </h1>
                      )}
                      <h1 className="text-xs p-1">
                        <span className="text-blue-800 font-bold">
                          Stock :{" "}
                        </span>
                        {prdct.Stock}
                      </h1>
                      <h1 className="text-xs p-1">
                        <span className="text-blue-800 font-bold">
                          Model :{" "}
                        </span>
                        {prdct.Model}
                      </h1>
                      <div className="">
                        <button className="bg-blue-800 w-full p-2 text-white">
                          Buy
                        </button>
                        <button
                          className="bg-white w-full mt-2 flex flex-row items-center justify-center gap-2"
                          onClick={() => {
                            AddToCart(prdct);
                            toast("Added to cart", {
                              type: "success",
                            });
                          }}
                        >
                          <span className="material-symbols-outlined text-base">
                            shopping_bag
                          </span>
                          Add to Cart
                        </button>
                      </div>
                    </div>
                  );
              })}
            </div>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default Store;
