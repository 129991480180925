import React from "react";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const Footer = () => {
  return (
    <React.Fragment>
        <div className="flex lg:flex-row justify-evenly flex-col p-10 items-center">
      <div className="h-full lg:w-1/3 text-center">
        <h1 className="font-signika text-bgTheme text-2xl">
          SREE MEDI SYSTEMS
        </h1>
        <p>We Provide Quality Medical Equipment & Service.</p>
        <p>
          Sree Medi Systems., are a customer-centric organization, and focused
          on delivering innovative medical life care products and medical
          equipment to our clients.
        </p>
      </div>
      <div className="lg:w-1/3 text-center font-bold">
        <h1 className="font-signika">Contacts</h1>
        <div className="flex flex-col gap-3 items-center mt-5">
          <div className="flex flex-row gap-3">
            <PhoneIcon className="text-bgTheme" />
            <p>+91 9849146432</p>
          </div>
          <div className="flex flex-row gap-3">
            <LocationOnIcon className="text-bgTheme" />
            <p>#15-3-56, Rangampet,Warangal</p>
          </div>
        </div>
      </div>
      <div className="lg:w-1/3 text-center font-bold">
        <h1 className="text-xl">SUBSCRIBE OUR NEWSLETTER</h1>
        <input type="text" placeholder="Enter Email" className="newInp" />
        <div className="p-4 mt-4 w-32 mx-auto cursor-pointer bg-white hover:text-black border border-bgTheme border-1 transition-all">
          <p>SUBSCRIBE</p>
        </div>
      </div>
    </div>
    <div className="h-16 bg-bgTheme p-3">
        <h1 className="text-center text-white">Copyright © 2023. Sree Medi Systems All Rights Reserved.</h1>
    </div>
    <div className="p-3 flex flex-row justify-center gap-2">
        <ul className="flex flex-row gap-8 items-center text-xs list-disc">
          <li><a href="/terms-and-conditions">Terms & Conditions</a></li>
          <li><a href="/refundPolicy">Refund Policy</a></li>
          <li><a href="/shippingPolicy">Shipping Policy</a></li>
        </ul>
    </div>
    </React.Fragment>
  );
};


export default Footer;
