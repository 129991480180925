import React, { useEffect } from "react";
import Header from "../../components/Home/Header";
import ContactTab from "../../components/Home/ContactTab";
import { useNavigate, useParams } from "react-router-dom";
import Order from "../../assets/order.png";
import emailjs from "@emailjs/browser";

const Success = () => {
  const { id, status, name, email } = useParams();
  const navigate = useNavigate();
  const SendMail = async () => {
    const form = new FormData();
    form.append("from_name", "vishwas");
    form.append("tr_status", "success");
    form.append("cust_email", "vishwas@mail.com");
    var templateParams = {
      from_name: name,
      tr_status: status,
      cust_email: email,
    };
    await emailjs
      .send(
        "service_tjjt3bj",
        "template_noi152a",
        templateParams,
        "xhqbZrKgXbReICTQw"
      )
      .then((resp) => {
        
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (!id) {
      navigate("/");
    } else {
      if (status === "true") {
        SendMail();
      } else {
        navigate("/");
      }
    }
  }, []);
  return (
    <div>
      <ContactTab />
      <Header />
      <div className="flex flex-col justify-center items-center">
        <img src={Order} alt="" className="w-32 mt-40" />
        <h1 className="text-2xl text-gray-800">Order Placed !</h1>
        <h1>order ID : {id}</h1>
        <h1>Delivery Expected within 1 - 7 Days</h1>
        <h1
          className="p-2 bg-theme text-white mt-4 cursor-pointer"
          onClick={() => navigate("/")}
        >
          {" "}
          Go Home{" "}
        </h1>
      </div>
    </div>
  );
};

export default Success;
