import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import BASE from '../../api/apis';
import { LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

const ViewServices = () =>{

    const { id } = useParams();
    const [loading,setLoading] = useState(false);

    const [service,setService] = useState();

    const FetchService = () =>{
        setLoading(true);
        axios.get(`${BASE}/service/${id}`).then(resp=>{
            if(resp.data.success){
                setLoading(false);
                setService(resp.data.service);
            }else{
                alert(resp.data.message)
            }
        }).catch(err=>{
            alert(err.message)
        })
    }

    const updateService = (status) => {
        axios
          .put(`${BASE}/service/status/${id}?status=${status}`)
          .then((resp) => {
            setService({...service,status:resp.data.status});
            alert(resp.data.message);
          })
          .catch((err) => {
            console.log(err);
          });
    };
    
    useEffect(()=>{
        FetchService();
    },[])
    
    return(
        <div>
            <h1 className='font-bold text-center mt-2'>Service info</h1>
            <div>
                {loading && <LinearProgress/>}
            </div>
            {
                !loading ? <div className='flex flex-col'>
                    <div className='flex gap-2 flex-wrap'>
                        {
                            service && service.img.map(img=>{
                                return(
                                    <img src={img} className='mx-auto' key={img}/>
                                );
                            })
                        }
                    </div>
                    <div>
                        {service && <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Description</TableCell>
                                        <TableCell>User</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell>Mobile</TableCell>
                                        <TableCell>ServiceType</TableCell>
                                        <TableCell>Serviced</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>{service.Description}</TableCell>
                                        <TableCell>{service.user.Name}</TableCell>
                                        <TableCell>{service.user.Email}</TableCell>
                                        <TableCell>{service.user.Mobile}</TableCell>
                                        <TableCell>{service.serviceType}</TableCell>
                                        {!service.status ? <TableCell>
                                            <span className="material-symbols-outlined cursor-pointer hover:bg-green-400 p-2 bg-green-800 text-white mr-2 mb-2" onClick={()=>updateService(true)}>done</span>
                                            <span className="material-symbols-outlined cursor-pointer hover:bg-red-400 p-2 bg-red-800 text-white" onClick={()=>updateService(false)}>close</span>
                                        </TableCell> : <TableCell>
                                                <p className='text-green-600 font-bold text-center p-2'>Serviced</p>
                                            </TableCell>}
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>}
                    </div>
                    {service && <div className='p-2'>
                        <h1 className='font-bold'>Statement</h1>
                        <p>{service.statement}</p>
                    </div>}
                </div> : ""
            }
        </div>
    );
};

export default ViewServices;