// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from 'firebase/storage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCPGX2J1WZAe6GrM8Hs_LO9icJqm4ewxOA",
  authDomain: "sreemedisystems-22955.firebaseapp.com",
  projectId: "sreemedisystems-22955",
  storageBucket: "sreemedisystems-22955.appspot.com",
  messagingSenderId: "97828975364",
  appId: "1:97828975364:web:c78f1b244f27983f82d651",
  measurementId: "G-Z1GN7SND5D"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const storage = getStorage(app);

export { storage };