import React, { useEffect, useState } from 'react';
import BASE from '../../api/apis';
import { Box, LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const ManageQuotes = () =>{

    const [quotes,setQuotes] = useState([]);
    const [loading,setLoading] = useState(false);
    const navigate = useNavigate();
    
    const FetchQuotes = async () =>{
        setLoading(true);
        await fetch(`${BASE}/quote`,{
            headers:{
                "Content-Type":"application/json"
            }
        }).then(resp=>{
            setLoading(false);
            resp.json().then(dta=>{
                setQuotes(dta.Quotes);
            })
        })
    }

    const DeleteQuote = id =>{
        const index = quotes.findIndex(obj=>obj._id===id);
        const dta = [...quotes];
        dta.splice(index,1);
        setQuotes(dta);
        axios.delete(`${BASE}/quote/${id}`).then(resp=>{
            alert(resp.data.message);
        })
    }

    useEffect(()=>{
        FetchQuotes();
    },[])

    return(
        <div>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Product</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Phone</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading && <TableRow>
                            <TableCell colSpan={5}>
                                <LinearProgress/>
                            </TableCell>
                        </TableRow>}
                        {
                            quotes.length > 0 ? quotes.map(qte=>{
                                console.log(qte)
                                return(
                                    <TableRow key={qte._id}>
                                        <TableCell>
                                            <img src={qte.Product.img} width="50px" height="auto"/>
                                        </TableCell>
                                        <TableCell>{qte.Name}</TableCell>
                                        <TableCell>{qte.Email}</TableCell>
                                        <TableCell>{qte.Mobile}</TableCell>
                                        <TableCell>
                                            <Box className="flex flex-row gap-2">
                                                <button className="p-2 bg-theme text-white hover:bg-blue-500" onClick={()=>navigate(`/admin/home/viewquote/${qte._id}`)}>View</button>
                                                <button className="p-2 bg-red-800 text-white" onClick={()=>DeleteQuote(qte._id)}>Delete</button>
                                            </Box>
                                        </TableCell>
                                    </TableRow> 
                                );
                            }) : <h1 className='text-center mt-3'>No Quotes Found !</h1>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};  

export default ManageQuotes;