import React from 'react';
import { useNavigate } from 'react-router-dom';

const SideBar = () =>{
    const navigate = useNavigate();
    return(
        <div style={{width:"190px",display:"flex",flexDirection:"column",gap:6,paddingRight:4,paddingBottom:7}} className='border hidden md:block cursor-pointer h-full' id="adminsidebar">
            <div className='flex flex-row justify-end text-gray-600'>
                <span className='material-symbols-outlined' onClick={()=>{
                    document.getElementById("adminsidebar").style.marginLeft = "-190px";
                }}>close</span>
            </div>
            <h1 className='p-2 text-xs uppercase text-gray-500'>Products</h1>
            <div className='flex flex-row items-center gap-2 text-xs pl-2'>
                <span className="material-symbols-outlined text-theme">add_box</span>
                <p onClick={()=>navigate("/admin/home/addproduct")} className='font-bold'>Add product</p>
            </div>
            <div className='flex flex-row items-center gap-2 text-xs pl-2'>
                <span className="material-symbols-outlined text-theme">inventory_2</span>
                <p onClick={()=>navigate('/admin/home/removeproduct')}className='font-bold'>Manage products</p>
            </div>
            <div className='flex flex-row items-center gap-2 text-xs pl-2' onClick={()=>navigate('/admin/home/manageCategories')}>
                <span className="material-symbols-outlined text-theme">category</span>
                <p className='font-bold'>Manage Categories</p>
            </div>
            <h1 className='p-2 text-xs uppercase text-gray-500'>Users</h1>
            <div className='flex flex-row items-center gap-2 text-xs pl-2'>
                <span className="material-symbols-outlined text-theme">person_add</span>
                <p className='font-bold' onClick={()=>navigate("/admin/home/adduser")}>Add User</p>
            </div>
            <div className='flex flex-row items-center gap-2 text-xs pl-2'>
                <span className="material-symbols-outlined text-theme">person_remove</span>
                <p className='font-bold' onClick={()=>navigate("/admin/home/manageusers")}>Manage Users</p>
            </div>
            <h1 className='p-2 text-xs uppercase text-gray-500'>Visiting Cards</h1>
            <div className='flex flex-row items-center gap-2 text-xs pl-2'>
                <span className="material-symbols-outlined text-theme">add</span>
                <p className='font-bold' onClick={()=>navigate('/admin/home/createCard')}>Add Visiting Card</p>
            </div>
            <div className='flex flex-row items-center gap-2 text-xs pl-2' onClick={()=>navigate('/admin/home/managecards')}>
                <span className="material-symbols-outlined text-theme">manage_accounts</span>
                <p className='font-bold'>Manage Visiting Cards</p>
            </div>
            <div className='flex flex-row items-center gap-2 text-xs pl-2' onClick={()=>navigate('/admin/home/managemycard')}>
                <span className="material-symbols-outlined text-theme">badge</span>
                <p className='font-bold'>Manage My Card</p>
            </div>
            <h1 className='p-2 text-xs uppercase text-gray-500'>Rental</h1>
            <div className='flex flex-row items-center gap-2 text-xs pl-2'>
                <span className="material-symbols-outlined text-theme">add_box</span>
                <p onClick={()=>navigate("/admin/home/addproduct")} className='font-bold'>Add product</p>
            </div>
            <div className='flex flex-row items-center gap-2 text-xs pl-2'>
                <span className="material-symbols-outlined text-theme">inventory_2</span>
                <p onClick={()=>navigate('/admin/home/managerental')}className='font-bold'>Manage products</p>
            </div>
            <h1 className='p-2 text-xs uppercase text-gray-500'>Quotes</h1>
            <div className='flex flex-row items-center gap-2 text-xs pl-2'>
                <span className="material-symbols-outlined text-theme">inventory_2</span>
                <p onClick={()=>navigate('/admin/home/manageQuotes')}className='font-bold'>Manage Quotes</p>
            </div>
            <h1 className='p-2 text-xs uppercase text-gray-500'>Services</h1>
            <div className='flex flex-row items-center gap-2 text-xs pl-2'>
                <span className="material-symbols-outlined text-theme">medical_information</span>
                <p className='font-bold' onClick={()=>navigate("/admin/home/getallservices")}>Manage Services</p>
            </div>
            <h1 className='p-2 text-xs uppercase text-gray-500'>Orders</h1>
            <div className='flex flex-row items-center gap-2 text-xs pl-2'>
                <span className="material-symbols-outlined text-theme">orders</span>
                <p className='font-bold' onClick={()=>navigate("/admin/home/orders")}>Manage Orders</p>
            </div>
        </div>  
    );
};

export default SideBar;