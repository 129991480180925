import axios from 'axios';
import React, { useEffect, useState } from 'react';
import BASE from '../../api/apis';
import { Avatar, Box, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, MenuItem, LinearProgress, Dialog, CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

const GetAllServices = () =>{

    const [Services,setServices] = useState([]);
    const navigate = useNavigate();
    const [serviceType,setServiceType] = useState("select");
    const [loading,setLoading] = useState(false);
    const [users,setUsers] = useState([]);
    const [dial,setDial] = React.useState(false);
    const [service,setService] = useState();

    const FetchServices = () =>{
        axios.get(`${BASE}/service`).then(dta=>{
            setServices(dta.data.Services);
        })
    }
    useEffect(()=>{
        FetchServices();
    },[])

    const DeleteService = async service =>{
        const index = Services.indexOf(service);
        const services = [...Services];
        services.splice(index,1);
        setServices(services);
        await axios.delete(`${BASE}/service/${service._id}`).then(resp=>{
            if(resp.data.success){
                toast("service Deleted !",{
                    type:"success"
                })
            }else{
                console.log("Something Went Wrong !");
            }
        }).catch(err=>{
            toast("Something went Wrong",{
                type:"error"
            })
        })
    }

    const FetchByType = () =>{
        if(serviceType !== "select"){
            setLoading(true);
            axios.get(`${BASE}/service/servicetype/${serviceType}`).then(resp=>{
                setServices(resp.data.services);
                setLoading(false);
            })
        }
    }


    const FetchuserData = async ()=>{
        axios.get(`${BASE}/user/`).then(resp=>{
            setUsers(resp.data.Users);
        })
    }

    const AssignTask = async (id,service) =>{
        document.getElementById("assign_btn").innerText = "Please Wait..."
        axios.put(`${BASE}/user/assign/${id}`,service).then(resp=>{
            if(resp.data.success){
                document.getElementById("assign_btn").innerText = "Assigned !"
            }else{
                document.getElementById("assign_btn").innerText = "Assigned !"
                alert(resp.data.message);
            }
        })
    }

    return(
        <div>
            <Dialog open={dial} onClose={()=>setDial(false)}>
                <Box sx={{width:"270px",padding:"4px"}}>
                    <div className='flex flex-row justify-center p-2'>
                        {
                            !users.length > 0 && <CircularProgress size={23}/>
                        }
                    </div>
                    <div>
                        {
                            users.length > 0 && 
                            <TableContainer>
                                <Table>
                                    {
                                        users.map(usr=>{
                                            if(usr.userType==="ENGINEER"){
                                                return(
                                                    <TableRow key={usr._id}>
                                                        <TableCell>{usr.Name}</TableCell>
                                                        <TableCell>
                                                            <p className='text-white bg-theme rounded text-center p-2 cursor-pointer hover:bg-blue-600' id="assign_btn" onClick={()=>{
                                                                AssignTask(usr._id,service);
                                                            }}>Assign</p>
                                                        </TableCell>
                                                    </TableRow>   
                                                )  
                                            }
                                        })
                                    }
                                </Table>    
                            </TableContainer>
                        }
                    </div>
                </Box>               
            </Dialog>
            <h1 className='font-bold p-2'>Select Service Type</h1>
            <div className='flex flex-row gap-2 p-2'>
                <Select className='w-full ml-2' value={serviceType} onChange={(e)=>{setServiceType(e.target.value)}}>
                    <MenuItem value="select">select</MenuItem>
                    <MenuItem value="AMC">AMC</MenuItem>
                    <MenuItem value="CMC">CMC</MenuItem>
                    <MenuItem value="oncall">Oncall</MenuItem>
                </Select>
                <button className='p-2 text-white text-center bg-theme w-32' onClick={()=>FetchByType()}>Go</button>
            </div>
            <div>
                {loading && <LinearProgress/>}  
            </div>
            {
                Services.length > 0 ? <div>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell><p className='text-center'>Image</p></TableCell>
                                    <TableCell><p className='text-center'>Description</p></TableCell>
                                    <TableCell><p className='text-center'>User</p></TableCell>
                                    <TableCell><p className='text-center'>Service Type</p></TableCell>
                                    <TableCell><p className='text-center'>Status</p></TableCell>
                                    <TableCell><p className='text-center'>Actions</p></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    Services.map(srv=>{
                                        return(
                                            <TableRow key={srv._id}>
                                                <TableCell>
                                                    <div className='flex flex-row justify-center'>
                                                        <Avatar src={srv.img[0]} variant="square"/>
                                                    </div>
                                                </TableCell>
                                                <TableCell><p className='text-center'>{srv.Description}</p></TableCell>
                                                <TableCell><p className='text-center'>{srv.user ? srv.user.Name : ""}</p></TableCell>
                                                <TableCell><p className='text-center'>{srv.serviceType}</p></TableCell>
                                                <TableCell>{srv.status === false ? <p className='text-red-600 font-bold text-center p-2'>Pending</p> : <p className='text-green-600 font-bold text-center p-2'>Serviced</p>}</TableCell>
                                                <TableCell>
                                                    <Box className="flex flex-row gap-2 justify-center">
                                                        <button className="p-2 text-white text-center bg-blue-800" onClick={()=>navigate(`/admin/home/viewservice/${srv._id}`)}>View</button>
                                                        <button className="p-2 text-white text-center bg-red-800" onClick={()=>DeleteService(srv)}>Delete</button>
                                                        <button className="p-2 text-white text-center bg-green-800" onClick={()=>{
                                                            FetchuserData()
                                                            setService(srv);
                                                            setDial(true);
                                                            }}>Assign</button>
                                                    </Box>
                                                </TableCell>
                                            </TableRow> 
                                        );
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div> : <h1 className='p-2'>No Services Found !</h1>
            }
        </div>
    );
};

export default GetAllServices;