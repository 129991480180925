import React from "react";
import Logo from '../../assets/VisitingCard/logo.jpg';

const TermsConditions = () => {
  return (
    <div>
      <div className="p-2 bg-theme flex flex-row gap-2 items-center">
        <img
          src={Logo}
          alt="logo"
          width="50px"
          height="50px"
          className="rounded-full"
        />
        <h1 className="text-white">Sree Medi Systems</h1>
      </div>
      <div className="p-2">
        <h1 className="font-bold p-2 text-center">Terms & Conditions for SreeMediSystems</h1>
        <p className="text-center">Welcome to sreemedisystems. These terms and conditions outline the rules and regulations for the use of our website. By accessing or using our website, you agree to comply with these terms. Please read them carefully.</p>
        <h1 className="p-2 font-semibold">1. Acceptance of Terms</h1>
        <p>By using this website, you agree to be bound by these terms and conditions, our privacy policy, and any other policies or guidelines posted on this site. If you do not agree with any part of these terms, please do not use our website.</p>
        <h1 className="p-2 font-semibold">2. Product Information</h1>
        <h1 className="p-2 font-semibold">1. Accuracy:</h1>
        <p className="p-2">We strive to provide accurate and up-to-date information about our products. However, we do not guarantee the accuracy, completeness, or reliability of any product  font-semibolddescriptions, pricing, or other content on our website.</p>
        <h1 className="p-2 font-semibold">2. Availability:</h1>
        <p className="p-2">Product availability is subject to change without notice. We reserve the right to discontinue any product at any time.</p>
        <h1 className="p-2 font-semibold">3. Ordering and Payment</h1>
        <p className="p-2">1. Order Confirmation:
   Upon placing an order, you will receive an order confirmation via email. This confirmation does not guarantee acceptance of your order, and we reserve the right to cancel or limit quantities at our discretion.</p>
      <h1 className="p-2 font-semibold">2. Payment:</h1>
      <p className="p-2">Payment is required at the time of purchase. We accept UPI,Phonepe,CreditCard,DebitCard,NetBanking. Your payment information is securely processed, and we do not store any payment details.</p>
      <h1 className="p-2 font-semibold">4. User Accounts</h1>
      <h1 className="p-2 font-semibold">1. Account Creation:</h1>
      <p className="p-2"> To access certain features of our website, you may need to create a user account. You are responsible for maintaining the confidentiality of your account information and password.</p>
      <h1 className="p-2 font-semibold">2. Account Termination:</h1>
      <p className="p-2">  We reserve the right to terminate accounts, refuse service, or cancel orders at our discretion</p>
      <h1 className="p-2 font-semibold">5. Intellectual Property</h1>
      <h1 className="p-2 font-semibold">1. Copyright:</h1>
      <p className="p-2">All content on this website, including text, images, logos, and graphics, is the property of sreemedisystems and is protected by copyright laws.</p>
      <h1 className="p-2 font-semibold">2. Trademarks:</h1>
      <p className="p-2">   All trademarks, service marks, and trade names used on our website are the property of sreemedisystems or their respective owners.</p>
      <h1 className="p-2 font-semibold">6. Limitation of Liability</h1>
      <h1 className="p-2 font-semibold">1. Disclaimer:</h1>
      <p className="p-2"> We make no warranties or representations about the accuracy or completeness of this site's content. Your use of this site is at your own risk.</p>
      <h1 className="p-2 font-semibold">2. Limitation of Liability:</h1>
      <p className="p-2"> In no event shall sreemedisystems be liable for any direct, indirect, incidental, consequential, or punitive damages arising from the use of, or inability to use, this website.</p>
      <h1 className="p-2 font-semibold">Contact Information</h1>
      <p className="p-2">If you have any questions or concerns about our terms and conditions, please contact us at sreemedisystems@gmail.com or 9849146432.</p>
      <p className="p-2">Thank you for choosing sreemedisystems. We appreciate your business and hope you enjoy your shopping experience with us.</p>
      </div>
    </div>
  );
};

export default TermsConditions;
