import React, { useEffect, useState } from 'react';
import Logo from '../../assets/VisitingCard/logo.jpg';
import { Avatar, Box, CircularProgress, Table, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { jwtDecode }from 'jwt-decode';
import cookies from 'js-cookie';
import axios from 'axios';
import BASE from '../../api/apis';
import { useNavigate } from 'react-router-dom';

const EngineerHome = () =>{

    const [User,setUser] = useState();
    const [loading,setLoading] = useState(false);
    const navigate = useNavigate();

    const GetUserData = ()=>{
        setLoading(true);
        const token = sessionStorage.getItem("SMS_TK");
        if(token){
            const user = jwtDecode(token);  
            getuser(user.id);
        }
    }

    const getuser = async (id) =>{
        axios.get(`${BASE}/user/${id}`).then(resp=>{
            setUser(resp.data.user);
            setLoading(false); 
        }).catch(err=>{
            console.log(err);
        })
    }

    useEffect(()=>{
        GetUserData();
    },[])

    return(
        <div>
            <div className='p-2 bg-theme flex flex-row justify-between items-center'>
                <div className='flex flex-row gap-2 items-center'>
                    <img src={Logo} alt="logo" width="50px" height="50px" className='rounded-full' />
                    <h1 className='text-white'>Sree Medi Systems</h1>
                </div>
                <h1 className='text-white'>Hello ! {User ? User.Name : ""}</h1>
            </div>
            {(!loading && User) && <div>
                <div>
                    <h1 className='text-center mt-2'>List of Services !</h1>
                    {User.AssignedServices.length > 0 ? <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Image</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            {
                                User.AssignedServices.length > 0 &&  User.AssignedServices.map(srv=>{
                                    return(
                                        <TableRow>
                                            <TableCell>{srv.serviceID.Description ? srv.serviceID.Description : ""}</TableCell>
                                            <TableCell><Avatar src={srv.serviceID.img[0]} variant='square'/></TableCell>
                                            <TableCell>{srv.serviceID.status ? <p className='text-green-600 font-bold p-2'>Serviced</p> : <p className='text-red-600 font-bold'>Pending</p>}</TableCell>
                                            <TableCell>
                                                <Box>
                                                    <button className="p-2 text-white text-center bg-blue-800" onClick={()=>navigate(`/viewServiceEng/${srv.serviceID._id}`)}>View</button>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                            }
                        </Table>
                    </TableContainer> : <h1>No Services Assigned !</h1>}
                </div>
            </div>}
            <div className='flex flex-row justify-center mt-2'>
                {
                    loading && <CircularProgress size={25} className='mx-auto'/>
                }
            </div>
        </div>
    );
};

export default EngineerHome;