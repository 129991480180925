import React, { useState, useEffect } from "react";
import Logo from "../../assets/VisitingCard/logo.jpg";
import { useParams } from "react-router-dom";
import BASE from "../../api/apis";
import {
  LinearProgress,
  Table,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  TableBody,
} from "@mui/material";
import axios from "axios";

const ViewServiceEng = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [service, setService] = useState();
  const [statement,setStatement] = useState("");

  const FetchService = () => {
    setLoading(true);
    axios
      .get(`${BASE}/service/${id}`)
      .then((resp) => {
        if (resp.data.success) {
          setLoading(false);
          setService(resp.data.service);
          setStatement(resp.data.service.statement);
        } else {
          alert(resp.data.message);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  useEffect(() => {
    FetchService();
  }, []);

  const updateService = (status) => {
    axios
      .put(`${BASE}/service/status/${id}?status=${status}`)
      .then((resp) => {
        setService({...service,status:resp.data.status});
        alert(resp.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <div className="flex flex-row gap-2 items-center bg-theme p-2">
        <img
          src={Logo}
          alt="logo"
          width="50px"
          height="50px"
          className="rounded-full"
        />
        <h1 className="text-white">Sree Medi Systems</h1>
      </div>
      <div>
        <h1 className="font-bold text-center mt-2">Service info</h1>
        <div>{loading && <LinearProgress />}</div>
        {!loading ? (
          <div className="flex flex-col">
            <div className="flex gap-2 flex-wrap">
              {service &&
                service.img.map((img) => {
                  return <img src={img} className="mx-auto" key={img} />;
                })}
            </div>
            <div>
              {service && (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Description</TableCell>
                        <TableCell>User</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Mobile</TableCell>
                        <TableCell>ServiceType</TableCell>
                        <TableCell>Serviced</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>{service.Description}</TableCell>
                        <TableCell>{service.user.Name}</TableCell>
                        <TableCell>{service.user.Email}</TableCell>
                        <TableCell>{service.user.Mobile}</TableCell>
                        <TableCell>{service.serviceType}</TableCell>
                        {!service.status ? (
                          <TableCell>
                            <span
                              className="material-symbols-outlined cursor-pointer hover:bg-green-400 p-2 bg-green-800 text-white mr-2 mb-2"
                              onClick={() => updateService(true)}
                            >
                              done
                            </span>
                            <span
                              className="material-symbols-outlined cursor-pointer hover:bg-red-400 p-2 bg-red-800 text-white"
                              onClick={() => updateService(false)}
                            >
                              close
                            </span>
                          </TableCell>
                        ) : (
                          <TableCell>
                            <p className="text-green-600 font-bold p-2">
                              Serviced
                            </p>
                          </TableCell>
                        )}
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
              <div>
                {service && <div className="p-4 flex flex-col gap-2 items-center">
                    <label htmlFor="tf">Statement</label>
                  <input
                    type="text"
                    placeholder="Write any Statement "
                    className="border p-2 outline-blue-700 w-full"
                    value={statement}
                    id="tf"
                    onChange={(e)=>{
                      setStatement(e.target.value);
                    }}
                  />
                  <button className="w-24 border p-2 bg-theme text-white hover:bg-blue-700 cursor-pointer" onClick={(e)=>{
                    axios.put(`${BASE}/service/statement/${id}`,{
                        statement:statement
                    }).then(resp=>{
                        alert(resp.data.message);
                    }).catch(err=>{
                        console.log(err);
                    })
                  }}>
                    Submit
                  </button>
                </div>}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default ViewServiceEng;
