import React from 'react';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { IconButton } from "@mui/material";
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import { NavLink } from "react-router-dom";

const ContactTab = () =>{
    return(
        <div className="md:h-14 bg-blue-800 flex-row flex-wrap justify-evenly items-center text-white h-auto gap-2 text-sm hidden md:flex">
            <div className="flex flex-row gap-3">
                <PhoneIcon/>
                <p>+91 9849146432</p>
            </div>
            <div className="flex flex-row gap-3">
                <LocationOnIcon/>
                <p>#15-3-56, Rangampet,Warangal</p>
            </div>
            <div>
                <NavLink to="#">
                    <IconButton>
                        <FacebookIcon className="text-white"/>
                    </IconButton>
                </NavLink>
                <IconButton>
                    <TwitterIcon className="text-blue-500"/>
                </IconButton>
                <IconButton>
                    <InstagramIcon className="text-purple-300"/>
                </IconButton>
            </div>
        </div>
    );
};
export default  ContactTab;
