import AE from '../assets/Home/ae.png';
import AW from '../assets/Home/aw.png';
import SE from '../assets/Home/se.png';
import OTT from '../assets/Home/ott.png';
import DM from '../assets/Home/dm.png';
import AV from '../assets/Home/av.png';
import EM from '../assets/Home/em.png';
import PM from '../assets/Home/pm.png';
import FM from '../assets/Home/fm.png';

const Product_Categories = [
    {
        name:"Anaesthesia Equipments",
        image:AE
    },
    {
        name:"Anaesthesia Workstation",
        image:AW
    },
    {
        name:"Sterilization Equipment",
        image:SE
    },
    {
        name:"Operation Theatre Tables & Lights",
        image:OTT
    },
    {
        name:"Diathermy Machine",
        image:DM
    },
    {
        name:"Anesthesia Ventilators",
        image:AV
    },
    {
        name:"ECG Machine",
        image:EM
    },
    {
        name:"Patient Monitors",
        image:PM
    },
    {
        name:"Fetal Monitors",
        image:FM
    }
];

export { Product_Categories };