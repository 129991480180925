import axios from "axios";
import React, { useEffect, useState } from "react";
import BASE from "../../api/apis";
import { Avatar, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ManageUsers = () =>{

    const [users,setUsers] = useState([]);
    const navigate = useNavigate();

    const FetchUsers = async () =>{
        await axios.get(`${BASE}/user`).then(resp=>{
            setUsers(resp.data.Users);
        }).catch(err=>{
            console.log(err);
        })
    }

    const DeleteUser = (id) =>{
        const index = users.findIndex(obj=>obj._id.toString()===id);
        users.splice(index,1);
        axios.delete(`${BASE}/user/${id}`).then(resp=>{
            alert(resp.data.message);
            window.location.reload();
        }).catch(err=>{
            console.log(err);
        })
    }

    useEffect(()=>{
        FetchUsers();
    },[])

    return(
        <div className="p-2">
            <h1 className="font-bold mt-2 text-center">Manage Users</h1>
            {
                ( users && users.length > 0) && <div>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Profile</TableCell>
                                    <TableCell>Mobile</TableCell>
                                    <TableCell>User Type</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    users.map(usr=>{
                                        return(
                                            <TableRow>
                                                <TableCell>
                                                    <p>{usr.Name}</p>
                                                </TableCell>
                                                <TableCell>
                                                    <p>{usr.Email}</p>
                                                </TableCell>
                                                <TableCell>
                                                    <p><Avatar src={usr.Profile}/></p>
                                                </TableCell>
                                                <TableCell>
                                                    <p>{usr.Mobile}</p>
                                                </TableCell>
                                                <TableCell>
                                                    <p>{usr.userType}</p>
                                                </TableCell>
                                                <TableCell>
                                                    <Box className="flex gap-2 flex-wrap">
                                                        <button className="p-2 bg-theme text-white" onClick={()=>navigate(`/admin/home/updateuser/${usr._id}`)}>Update</button>
                                                        <button className="p-2 bg-red-800 text-white" onClick={()=>DeleteUser(usr._id)}>Delete</button>
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            }
        </div>
    );
};

export default ManageUsers;