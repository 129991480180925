import { createSlice, configureStore } from '@reduxjs/toolkit';


const Slice = createSlice({
    name:"slice",
    initialState:{
        adminLogin:false,
        Drawer:false,
        UserLogin:false,
        CustomerLogin:false,
        controllerLogin:false,
        serviceControllerLogin:false
    },
    reducers:{
        setAdmin(state,actions){
            state.adminLogin = actions.payload;
        },
        setDrawer(state,actions){
            state.Drawer = actions.payload;
        },
        setUserLogin(state,actions){
            state.UserLogin = actions.payload;
        },
        setCustomerLogin(state,actions){
            state.CustomerLogin = actions.payload;
        },
        setControllerLogin(state,actions){
            state.controllerLogin = actions.payload;
        },
        setServiceControllerLogin(state,actions){
            state.serviceControllerLogin = actions.payload;
        }
    }
});

const Store = configureStore({
    reducer:Slice.reducer
});

const Actions = Slice.actions;

export { Actions, Store };



