import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Logo from "../assets/VisitingCard/logo.jpg"
import axios from 'axios';
import BASE from '../api/apis';
import { LinearProgress, Badge, Dialog, Box } from '@mui/material';
import { v4 } from 'uuid';
import Footer from '../components/Home/Footer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useSelector } from 'react-redux';

const ViewProduct = () => {

    const { id } = useParams();
    const [product, setProduct] = useState();
    const [relatedProducts, setRelatedProducts] = useState([]);
    const customerLogin = useSelector(state=>state.CustomerLogin);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [dial,setDial] = useState(false);

    const [cartItems , setCartItems] = useState(JSON.parse(localStorage.getItem("cartitems"))?.length);
    
    const FetchProduct = async () => {
        setLoading(true);
        await axios.get(`${BASE}/product/${id}`).then(async resp => {
            setLoading(false);
            if (resp.data.success) {
                setProduct(resp.data.prdct);
                await fetch(`${BASE}/product/category/${resp.data.prdct.category}`, {
                    method: "GET",
                    "Content-Type": "application/json"
                }).then(resp => {
                    resp.json().then(dta => {
                        if (dta.success) {
                            setRelatedProducts(dta.products)
                        }
                    })
                })
            }
        })
    } 

    const AddToCart = (itm) =>{
        const data = JSON.parse(localStorage.getItem("cartitems"));
        if(data === null){
            const itms = new Array();
            itms.push({...itm,Quantity:1});
            localStorage.setItem("cartitems",JSON.stringify(itms));
            if(cartItems === undefined){
                setCartItems(1);
            }else{
                setCartItems(prev=>prev+1);
            }
        }else{
            const index = data.findIndex(obj=>obj._id === itm._id);
            if(index===-1){
                data.push({...itm,Quantity:1});
                localStorage.setItem("cartitems",JSON.stringify(data));
                setCartItems(prev=>prev+1);
                toast("Added to cart ",{
                    type:"success"
                })
            }else{
                toast("Item is already in the cart !",{
                    type:"info"
                })
            }
        }

    }

    useEffect(() => {
        FetchProduct();
    }, [])

    const SetProduct = (product) =>{
        sessionStorage.setItem("buy",JSON.stringify([{...product,Quantity:1}]));
        customerLogin ? navigate("/checkout") : navigate("/login");
    }

    return (
        <div>
            <ToastContainer/>
            <Dialog open={dial} onClose={()=>setDial(false)}>
                <Box sx={{ width: "300px", height: "100px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <a href={`whatsapp://send?text=${window.location.href}`} data-action="share/whatsapp/share"><div className="p-2"><WhatsAppIcon className="text-green-700 cursor-pointer" /> Share on Whatsapp</div></a>
                </Box>
            </Dialog>
            <div className='p-2 bg-theme flex flex-row items-center justify-between'>
                <div className="flex flex-row gap-2 items-center">
                    <img src={Logo} alt="logo" width="50px" height="50px" className='rounded-full' />
                    <h1 className="text-white">Sree Medi Systems</h1>
                </div>
                <div className="pr-3">
                    <Badge color="primary" badgeContent={cartItems}>
                        <span className="material-symbols-outlined text-white cursor-pointer" onClick={()=>navigate("/cart")}>shopping_bag</span>     
                    </Badge>   
                </div>
            </div>
            <div>
                <ul className='md:flex flex-row p-3 justify-evenly mb-2 hidden cursor-pointer'>
                    <li className='hover:text-blue-800 hover:font-medium'>Anaesthesia Equipments</li>
                    <li className='hover:text-blue-800 hover:font-medium'>Anaesthesia Workstation</li>
                    <li className='hover:text-blue-800 hover:font-medium'>Sterilization Equipment</li>
                    <li className='hover:text-blue-800 hover:font-medium'>Operation Theatre Tables & Lights</li>
                    <li className='hover:text-blue-800 hover:font-medium'>ECG Machine</li>
                    <li className='hover:text-blue-800 hover:font-medium'>Patient Monitors</li>
                </ul>
            </div>
            {loading && <LinearProgress />}
            {(!loading && product) && <div className='container border h-full mx-auto p-2 md:columns-2'>
                <div className='md:p-3'>
                    <div className='flex flex-row justify-end'>
                        <span className="material-symbols-outlined p-2 rounded-full text-blue-800 md:mt-7 md:mr-5 cursor-pointer mb-3" onClick={()=>setDial(true)}>
                            share
                        </span>
                    </div>
                    <img src={product.img} alt="" className='mx-auto rounded-sm object-contain max-h-96' />
                    <h1 className='text-2xl text-center mt-4 font-bold'>{product.Name}</h1>
                </div>
                <div className='p-4'>
                    <h1 className='text-left mt-4'><span className='font-bold'>Description : </span>{product.Description}</h1>
                    <h1 className='text-left mt-4'><span className='font-bold'>Model : </span>{product.Model}</h1>
                    <h1 className='text-left mt-4'><span className='font-bold'>Make : </span>{product.Make}</h1>
                    <h1 className='text-left mt-4'><span className='font-bold'>Price : </span>{product.Price} {product.Price && <span>/-</span>}</h1>
                    <h1 className='text-left mt-4'><span className='font-bold'>Warranty : </span>{product.Warranty} Years</h1>
                    <h1 className='font-bold text-xl mt-4'>Features</h1>
                    {
                        product.Features.map(ft => {
                            return (
                                <li className='mt-1' key={v4()}>{ft}</li>
                            );
                        })
                    }
                    <h1>{product.Stock > 0 ? <p className='text-green-700 font-bold p-2'>in stock</p> : ""}</h1>
                    <div className='flex flex-row gap-2 w-full'>
                        <div className='p-2 bg-theme mt-4 text-white cursor-pointer hover:bg-blue-500 hover:text-black hover:border hover:border-bgTheme hover:border-1 transition-all text-center' style={{ width: "50%" }} onClick={()=>{
                            SetProduct(product);
                        }}>
                            <p>Buy Now</p>
                        </div>
                        <div className='p-2 mt-4 text-black cursor-pointer hover:bg-white hover:text-black hover:border hover:border-bgTheme hover:border-1 transition-all text-center' style={{ width: "50%", border: "1px solid black" }} onClick={()=>{
                            AddToCart(product)
                        }}>
                            <p>Add to Cart</p>
                        </div>
                    </div>
                </div>
            </div>}
            <h1 className='p-2 text-center'>Related Products</h1>
            {relatedProducts.length > 0 && <div className='flex flex-row overflow-scroll md:justify-center'>
                <div className='flex flex-row gap-2 justify-center'>
                    {
                        relatedProducts.map(prdct => {
                            return (
                                <div
                                    className="w-48 border hover:border-gray-400 hover:shadow-2xl p-3 "
                                    key={v4()}
                                    >
                                    <img src={prdct.img} width="100%" className="h-40" onClick={() => {navigate(`/product/${prdct._id}`);window.location.reload();}}/>
                                    <h1 className="p-1 text-xs">
                                        <span className="text-blue-800 font-bold">
                                            Name :
                                        </span>{" "}
                                        {prdct.Name}
                                    </h1>
                                    <h1 className="text-xs p-1">
                                        <span className="text-blue-800 font-bold">
                                            Make :{" "}
                                        </span>
                                        {prdct.Make}
                                    </h1>
                                    {prdct.Price && <h1 className="text-xs p-1">
                                        <span className="text-blue-800 font-bold">
                                            Price : {" "}
                                        </span>
                                        ₹ {prdct.Price} /-
                                    </h1>}
                                    <h1 className="text-xs p-1">
                                        <span className="text-blue-800 font-bold">
                                            Stock : {" "}
                                        </span>
                                        {prdct.Stock}
                                    </h1>
                                    <h1 className="text-xs p-1">
                                        <span className="text-blue-800 font-bold">
                                            Model :{" "}
                                        </span>
                                        {prdct.Model}
                                    </h1>
                                    <div className=''>
                                        <button className='bg-blue-800 w-full p-2 text-white'>Buy</button>
                                        <button className='bg-white w-full mt-2' onClick={()=>{
                                            AddToCart(prdct);
                                        }}>Add to Cart</button>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </div>}
            <Footer/>
        </div>
    );
};

export default ViewProduct;