import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import axios from 'axios';
import BASE from '../../api/apis';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'

const ViewOrder = () =>{
    const { id } = useParams();
    const [order,setOrder] = useState(null);
    const [loading,setLoading] = useState(false);

    useEffect(()=>{
        axios.get(`${BASE}/orders/${id}`).then(resp=>{
            setLoading(true);
            if(resp.data.success){
                setOrder(resp.data.order);
                setLoading(false);
            }
        }).catch(err=>{
            console.log(err);
            setLoading(false);
        })
    },[]);

    return( 
        <div className='p-2'>
            <h1 className='text-center font-bold'>Customer Details</h1>
            {!loading && <div>
                <div>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell><p className='text-center'>Email</p></TableCell>
                                    <TableCell><p className='text-center'>Name</p></TableCell>
                                    <TableCell><p className='text-center'>Mobile Number</p></TableCell>
                                    <TableCell><p className='text-center'>User ID </p></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {order && <TableRow>
                                    <TableCell><p className='text-center'>{order.Email}</p></TableCell> 
                                    <TableCell><p className='text-center'>{order.Name}</p></TableCell>
                                    <TableCell><p className='text-center'>{order.MobileNumber}</p></TableCell>
                                    <TableCell><p className='text-center'>{order.userID}</p></TableCell> 
                                </TableRow>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div className='p-2'>
                    <h1 className='text-center font-bold'>Customer Address</h1>
                    {
                        (!loading && order) && <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableCell><p className='text-center'>Country</p></TableCell>
                                    <TableCell><p className='text-center'>State</p></TableCell>
                                    <TableCell><p className='text-center'>City</p></TableCell>
                                    <TableCell><p className='text-center'>Landmark</p></TableCell>
                                    <TableCell><p className='text-center'>Pincode</p></TableCell>
                                    <TableCell><p className='text-center'>Hno</p></TableCell>
                                    <TableCell><p className='text-center'>Street</p></TableCell>
                                </TableHead>
                                <TableBody>
                                    <TableCell><p className='text-center'>{order.Address.Country}</p></TableCell>
                                    <TableCell><p className='text-center'>{order.Address.State}</p></TableCell>
                                    <TableCell><p className='text-center'>{order.Address.City}</p></TableCell>
                                    <TableCell><p className='text-center'>{order.Address.Landmark}</p></TableCell>
                                    <TableCell><p className='text-center'>{order.Address.Pincode}</p></TableCell>
                                    <TableCell><p className='text-center'>{order.Address.hno}</p></TableCell>
                                    <TableCell><p className='text-center'>{order.Address.street}</p></TableCell>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                </div>
                <div>
                    <h1 className='text-center mt-2 font-bold'>Order Items</h1>
                    {
                        order && order.OrderItems.map(itm=>{
                            return(
                                <div className='container columns-md mb-3'>
                                    <div>
                                        <img src={itm.id.img} alt="" className='w-52'/>
                                    </div>
                                    <div>
                                        <h1 className='mt-2 font-bold'>Name : <span className='font-light'>{itm.id.Name}</span></h1>
                                        <h1 className='mt-2 font-bold'>Model : <span className='font-light'>{itm.id.Model}</span></h1>
                                        <h1 className='mt-2 font-bold'>Price : <span className='font-light'>{itm.id.Price}</span></h1>
                                        <h1 className='mt-2 font-bold'>Stock : <span className='font-light'>{itm.id.Stock}</span></h1>
                                        <h1 className='mt-2 font-bold'>Quantity : {itm.Quantity}</h1>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
                <div>
                    <h1 className='text-center mt-2 font-bold'>Transaction Details</h1>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell><p>Merchant ID </p></TableCell>
                                    <TableCell><p>Merchant TransactionID </p></TableCell>
                                    <TableCell><p>State </p></TableCell>
                                    <TableCell><p>Total Amount</p></TableCell>
                                    <TableCell><p>Transaction ID</p></TableCell>
                                    <TableCell><p>Order Date</p></TableCell>
                                </TableRow>
                            </TableHead>
                            {
                                order && <TableBody>
                                    <TableRow>
                                        <TableCell><p>{order.merchantId}</p></TableCell>
                                        <TableCell><p>{order.merchantTransactionId}</p></TableCell>
                                        <TableCell>
                                            <select name="" id="">
                                                <option value="pc">Order Placed</option>
                                                <option value="sh">Shipped</option>
                                                <option value="de">Delivered</option>
                                            </select>
                                        </TableCell>
                                        <TableCell><p>{order.totalAmount}/-</p></TableCell>
                                        <TableCell><p>{order.transactionId}</p></TableCell>
                                        <TableCell><p>{order.createdAt}</p></TableCell>
                                    </TableRow>
                                </TableBody>
                            }
                        </Table>
                    </TableContainer>
                </div>
                <div className='flex flex-row justify-center'>
                    <button className='m-4 mx-auto p-2 bg-theme text-white'>update</button>
                </div>
            </div>}
        </div>
    );
};

export default ViewOrder;   