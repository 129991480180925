import React from "react";
import Logo from '../../assets/VisitingCard/logo.jpg';

const RefundPolicy = () => {
  return (
    <div>
      <div className="p-2 bg-theme flex flex-row gap-2 items-center">
        <img
          src={Logo}
          alt="logo"
          width="50px"
          height="50px"
          className="rounded-full"
        />
        <h1 className="text-white">Sree Medi Systems</h1>
      </div>
      <div className="p-2">
        <h1 className="text-center mt-2 font-bold">
            Refund Policy
        </h1>
        <div>
            <p className="p-2">At SreeMediSystems, we want our customers to be completely satisfied with their purchases. This refund policy outlines the terms and conditions under which refunds are provided. Please read the following carefully to understand our refund process.</p>
            <h1 className="p-2 font-semibold">Eligibility for Refunds</h1>
            <h1 className="p-2 font-semibold ">1. Product Condition:</h1>
            <p className="p-2"> To be eligible for a refund, the product must be unused, in its original packaging, and in the same condition as received.</p>
            <h1 className="p-2 font-semibold ">2. Time frame:</h1>
            <p className="p-2"> Refund requests must be submitted within 3 days from the date of purchase. After 3 days, we unfortunately cannot offer you a refund or exchange.</p>
            <h1 className="p-2 font-semibold ">Refund Process</h1>
            <h1 className="p-2 font-semibold ">1. Contact Customer Support:</h1>
            <p className="p-2">To initiate a refund, please contact our customer support team at sreemedisystems@gmail.com  or 9849146432 . Provide your order number, details of the item(s) you wish to return, and the reason for the return.</p>
            <h1 className="p-2 font-semibold ">2. Return Authorization:</h1>
            <p className="p-2"> Our customer support team will guide you through the return process and provide you with a return authorization if your request is eligible.</p>
            <h1 className="p-2 font-semibold ">3. Return Shipment:</h1>
            <p className="p-2">   Once you receive the return authorization, securely package the item(s) and ship them to the address provided by our customer support team. The cost of return shipping is the responsibility of the customer</p>
            <h1 className="p-2 font-semibold ">4. Verification:</h1>
            <p className="p-2">  Upon receiving the returned item(s), we will inspect them to ensure they meet the eligibility criteria. If approved, we will process the refund.</p>
            <h1 className="p-2 font-semibold ">Refund Options</h1>
            <h1 className="p-2 font-semibold ">1. Original Payment Method:</h1>
            <p className="p-2">   Refunds will be issued to the original payment method used during the purchase.</p>
            <h1 className="p-2 font-semibold ">2. Processing Time:</h1>
            <p className="p-2">   Please allow 7 business days for the refund to be processed. The exact timeframe may vary depending on your financial institution.</p>
            <h1 className="p-2 font-semibold ">Non-Refundable Items</h1>
            <h1 className="p-2 font-semibold ">1. Gift Cards:</h1>
            <p className="p-2">   Gift cards are non-refundable.</p>
            <h1 className="p-2 font-semibold ">2. Downloadable Products:</h1>
            <p className="p-2">Downloadable products, such as software or digital goods, are non-refundable.</p>
            <p className="p-2">Damaged or Defective Items If you receive a damaged or defective item, please contact our customer support team immediately. We will work with you to provide a replacement or a refund, depending on product availability and your preference.</p>
            <h1 className="p-2 font-semibold ">Contact Information</h1>
            <p className="p-2">If you have any questions or concerns about our refund policy, please contact our customer support team at sreemedisystems@gmail.com or 9849146432.</p>
            <p className="p-2">Thank you for shopping at smsmd.co.in. We value your satisfaction and appreciate your understanding of our refund policy.</p>
        </div>
      </div>
    </div>
  );
};

export default RefundPolicy;
