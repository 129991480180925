import React, { useEffect, useState } from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import OldTypicalPhone from "../../assets/VisitingCard/old-typical-phone.png";
import Mail from "../../assets/VisitingCard/mail.png";
import WhatsApp from "../../assets/VisitingCard/whatsapp.png";
import Web from "../../assets/VisitingCard/web.png";
import Iphone from "../../assets/VisitingCard/iphone.png";
import FaceBook from "../../assets/VisitingCard/facebook-circular-logo.png";
import Instagram from "../../assets/VisitingCard/instagram.png";
import linkedin from "../../assets/VisitingCard/linkedin.png";
import qr from "../../assets/VisitingCard/qr.png";
import { v4 } from "uuid";
import { Avatar, CircularProgress, LinearProgress } from "@mui/material";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase/firebase";
import BASE from "../../api/apis";
import axios from "axios";
import { useParams } from "react-router-dom";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import { Splide, SplideSlide } from "@splidejs/react-splide";
const ManageMyCard = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [gallery, setGallery] = useState([]);
  const [galleryObj, setgalleryObj] = useState([]);
  const [updateLoading, setupdateLoading] = useState(false);
  const [scrllGall, setscrllGall] = useState([]);
  const [hsGall,sethsGall] = React.useState([]);
  const [scrllGallObj, setscrllGallObj] = useState([]);
  const [hsptlGallObj,sethsptlGallObj] = useState([]);

  const ConvertBase64 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setData({ ...data, logo: reader.result, logoObj: file });
    };
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${BASE}/visitingcard/651a474b1fcffe4c50f9736b`)
      .then((resp) => {
        setLoading(false);
        if (resp.data.success) {
          setData(resp.data.card);
          setGallery(resp.data.card.gallery);
          setscrllGall(resp.data.card.productsGallery);
          sethsGall(resp.data.card.hospitalsGallery);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  const ChangeHandler = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const removeSection = sec => {
    const dta = { ...data };
    const index = dta.section.indexOf(sec);
    dta.section.splice(index, 1);
    setData(dta);
  }

  const ChangeCover = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setData({ ...data, cover: reader.result, coverObj: file });
    };
  };

  const changeProfile = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setData({ ...data, profile: reader.result, profileObj: file });
    };
  };

  const popItem = itm => {
    const dta = { ...data };
    const index = dta.gallery.indexOf(dta);
    dta.gallery.splice(index, 1);
    setData(dta);
  }

  const CreateSection = () => {
    const dta = { ...data };
    dta.section.push({
      title: "",
      media: "",
      text: "",
      imgObj: {},
    });
    setData(dta);
  };

  const SectionChangeHandler = (obj, e) => {
    const index = data.section.indexOf(obj);
    const dta = { ...data };
    dta.section[index].title = e.target.value;
    setData(dta);
  };

  const SubmitHandler = async (e) => {
    setupdateLoading(true);
    if (data.section.length > 0) {
      data.section.map(async (sec) => {
        if (sec.imgObj !== undefined && sec.imgObj !== null) {
          const refer = ref(
            storage,
            `/visitingCards/${data.firstname + " " + data.lastname}/${sec.imgObj.name + v4()
            }`
          );
          await uploadBytes(refer, sec.imgObj)
            .then(async (resp) => {
              await getDownloadURL(resp.ref).then(async (url) => {
                const dta = { ...data };
                const index = dta.section.indexOf(sec);
                dta.section[index].media = url;
                dta.section[index].imgObj = null;
                setData(dta);
              });
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    }
    if (data.profileObj !== undefined) {
      const profileRef = ref(
        storage,
        `/visitingCards/${data.firstname + " " + data.lastname}/${data.profileObj.name + v4()
        }`
      );
      await uploadBytes(profileRef, data.profileObj).then(async resp => {
        await getDownloadURL(resp.ref).then(url => {
          data.profile = url;
        }).catch(err => {
          console.log(err);
        })
      }).catch(err => {
        console.log(err);
      })
    }
    if (data.logoObj !== undefined) {
      const logoRef = ref(
        storage,
        `/visitingCards/${data.firstname + " " + data.lastname}/${data.logoObj.name + v4()
        }`
      );
      await uploadBytes(logoRef, data.logoObj).then(async resp => {
        await getDownloadURL(resp.ref).then(url => {
          data.logo = url;
        }).catch(err => {
          console.log(err);
        })
      }).catch(err => {
        console.log(err);
      })
    }
    if (data.coverObj !== undefined) {
      const coverRef = ref(
        storage,
        `/visitingCards/${data.firstname + " " + data.lastname}/${data.coverObj.name + v4()
        }`
      );
      await uploadBytes(coverRef, data.coverObj).then(async resp => {
        await getDownloadURL(resp.ref).then(url => {
          data.cover = url;
        }).catch(err => {
          console.log(err);
        })
      }).catch(err => {
        console.log(err);
      })
    }
    if (galleryObj.length > 0) {
      galleryObj.map(async obj => {
        const refer = ref(storage, `/visitingCards/${data.firstname + " " + data.lastname}/${obj.name + v4()}`);
        await uploadBytes(refer, obj).then(async resp => {
          await getDownloadURL(resp.ref).then(url => {
            const dta = { ...data };
            dta.gallery.push(url);
            setData(dta);
          }).catch(err => {
            console.log(err);
          })
        })
      })

    }
    if (scrllGallObj.length > 0) {
      scrllGallObj.map(async dta => {
        const refer = ref(storage, `/visitingCards/${data.firstname + " " + data.lastname}/${dta.name + v4()}`)
        await uploadBytes(refer, dta).then(async resp => {
          await getDownloadURL(resp.ref).then(url => {
            const ddta = { ...data };
            ddta.productsGallery.push(url);
            setData(dta);
          })
        })
      })
    }
    if(hsptlGallObj.length > 0){
      hsptlGallObj.map(async dta => {
        const refer = ref(storage, `/visitingCards/${data.firstname + " " + data.lastname}/${dta.name + v4()}`)
        await uploadBytes(refer, dta).then(async resp => {
          await getDownloadURL(resp.ref).then(url => {
            const ddta = { ...data };
            ddta.hospitalsGallery.push(url);
            setData(dta);
          })
        })
      })
    }
    setTimeout(() => {
      axios
        .put(`${BASE}/visitingcard/651a474b1fcffe4c50f9736b`, data)
        .then((resp) => {
          if (resp.data.success) {
            alert("Card Saved !");
            window.location.reload();
          } else {
            alert(resp.data.message);
            window.location.reload();
          }
          setLoading(false);
        })
        .catch((er) => {
          setLoading(false);
          console.log(er);
        });
    }, 6000);
  };

  const handleText = (sec, txt) => {
    const dta = { ...data };
    const index = dta.section.indexOf(sec);
    dta.section[index].text = txt;
    setData(dta);
  };

  return (
    <div>
      {loading && <LinearProgress className="mt-2" />}
      {(!loading && data) && <div className="flex flex-row">
        <div className="p">
          <div className="w-96 border rounded-lg">
            <div className="border p-1 flex flex-row items-center gap-2">
              <Avatar src={data.logo} />
              <p>{data.businessName}</p>
            </div>
            <div className="h-24 bg-blue-300">
              {data.cover ? (
                <img src={data.cover} className="h-32 w-full" />
              ) : (
                <img
                  src="https://img.freepik.com/free-photo/gradient-blue-abstract-background-smooth-dark-blue-with-black-vignette-studio_1258-68032.jpg"
                  className="h-32 w-full"
                />
              )}
            </div>
            <div className="flex flex-row">
              <img
                src={
                  data.profile
                    ? data.profile
                    : "https://cdn2.iconfinder.com/data/icons/user-people-4/48/6-512.png"
                }
                alt="profile"
                width="110px"
                height="110px"
                className="outline-2 outline-double outline-offset-2 outline-blue-700 mt-2 mx-2"
              />
              <div>
                <div className="">
                  <h1 className="text-2xl mt-8 mx-2">
                    {data.firstname} {data.lastname}
                  </h1>
                  {data.designation ? <p className="text-right text-xs">{data.designation}</p> : ""}
                  <p className="ml-1">{data.jobtitle}</p>
                </div>
              </div>
            </div>
            <div>
              <div className="flex flex-row justify-center pt-4 gap-5">
                <div className="flex flex-col items-center">
                  <img
                    src={OldTypicalPhone}
                    alt="Office"
                    width="40px"
                    height="40px"
                  />
                  <p className="text-sm">Office</p>
                </div>
                <div className="flex flex-col items-center">
                  <img src={Mail} alt="email" width="40px" height="40px" />
                  <p className="text-sm">Email</p>
                </div>
                <div className="flex flex-col items-center">
                  <img src={WhatsApp} alt="whatsapp" width="40px" height="40px" />
                  <p className="text-sm">Whatsapp</p>
                </div>
                <div className="flex flex-col items-center">
                  <img src={Web} alt="web" width="40px" height="40px" />
                  <p className="text-sm">Website</p>
                </div>
                <div className="flex flex-col items-center">
                  <img
                    src={Iphone}
                    alt="phone"
                    width="40px"
                    height="40px"
                    className="ml-4"
                  />
                  <p className="text-sm">Mobile</p>
                </div>
              </div>
              {/*second*/}
              <div className="flex flex-row justify-center pt-4 gap-3">
                <div className="flex flex-col items-center">
                  <img src={FaceBook} alt="Office" width="40px" height="40px" />
                  <p className="text-sm">FaceBook</p>
                </div>
                <div className="flex flex-col items-center">
                  <img src={qr} alt="web" width="40px" height="40px" />
                  <p className="text-sm">QR code</p>
                </div>
                <div className="flex flex-col items-center">
                  <img src={Instagram} alt="email" width="40px" height="40px" />
                  <p className="text-sm">Instagram</p>
                </div>
                <div className="flex flex-col items-center">
                  <img src={linkedin} alt="whatsapp" width="40px" height="40px" />
                  <p className="text-sm">Linkedin</p>
                </div>
                <div className="flex flex-col items-center">
                  <img
                    src={qr}
                    alt="phone"
                    width="40px"
                    height="40px"
                    className="ml-4"
                  />
                  <p className="text-sm">QR code</p>
                </div>
              </div>
            </div>
            <div id="section">
              {data.section
                ? data.section.map((dta) => {
                  return (
                    <div key={v4()}>
                      <div className="flex flex-row justify-end"><p className="material-symbols-outlined text-right cursor-pointer" onClick={() => removeSection(dta)}>close</p></div>
                      <div className="border p-2 mt-2" >
                        <h1 className="text-center">{dta.title}</h1>
                        {dta.media ? (
                          <img
                            src={dta.media}
                            width="70%"
                            className="mx-auto"
                            height="60%"
                          />
                        ) : (
                          ""
                        )}
                        <div>
                          {dta.text ? <p className="p-2">{dta.text}</p> : ""}
                        </div>
                        <div className="p-2">
                          {dta.video ? (
                            <video src={dta.video} autoPlay></video>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })
                : ""}
            </div>
            <div>
              {scrllGall.length > 0 && <div className="flex flex-row justify-end"><span className="material-symbols-outlined cursor-pointer" onClick={() => {
                const dta = { ...data };
                dta.productsGallery.splice(0,dta.productsGallery.length);
                setData(dta);
                setscrllGall([]);
              }}>close</span></div>}
              {scrllGall.length > 0 ? <div>
                <Splide options={{
                  type: "loop",
                  gap: "10px",
                  drag: "free",
                  arrows: false,
                  pagination: false,
                  perPage: 1,
                  autoScroll: {
                    pauseOnHover: false,
                    pauseOnFocus: false,
                    rewind: false,
                    speed: 3,
                  },
                }}
                  extensions={{ AutoScroll }}>
                  {
                    scrllGall.map(itm => {
                      return (
                        <SplideSlide><img src={itm} className="w-full h-40" /></SplideSlide>
                      );
                    })
                  }
                </Splide>
              </div> : ""}
              {hsGall ? <div className="flex flex-row justify-end mt-2"><span className="material-symbols-outlined cursor-pointer" onClick={() => {
                const dta = { ...data };
                dta.hospitalsGallery.splice(0, dta.hospitalsGallery.length);
                setData(dta);
                sethsGall([])
              }}>close</span></div> : ""}
              {hsGall ? <div>
                <Splide options={{
                  type: "loop",
                  gap: "10px",
                  drag: "free",
                  arrows: false,
                  pagination: false,
                  perPage: 1,
                  autoScroll: {
                    pauseOnHover: false,
                    pauseOnFocus: false,
                    rewind: false,
                    speed: 3,
                  },
                }}
                  extensions={{ AutoScroll }}>
                  {
                    hsGall.map(itm => {
                      return (
                        <SplideSlide><img src={itm} className="w-full h-40" /></SplideSlide>
                      );
                    })
                  }
                </Splide>
              </div> : ""}
            </div>
            {gallery.length > 0 ? <div>
              <h1 className="p-2 font-bold">Gallery</h1>
              <div className="p-1 grid grid-cols-4 gap-1">
                {
                  gallery.map(itm => {
                    return (
                      <div key={v4()}>
                        <span class="material-symbols-outlined absolute ml-16 text-red-500 cursor-pointer" onClick={() => popItem(itm)}>close</span>
                        <img src={itm} className="w-24 h-24" />
                      </div>
                    );
                  })
                }
              </div>
            </div> : ""}
          </div>
        </div>
        {/* Right Container */}
        <div className="p-4">
          <div className="rcon border rounded-lg">
            <div className="p-2 items-center gap-2 border h-auto">
              <h1 className="font-bold text-center">Header Attatchments</h1>
              <div className="flex flex-row items-center gap-2">
                <label
                  htmlFor="logoinp"
                  className="p-2 bg-blue-800 text-white mt-2 hover:bg-blue-500 cursor-pointer"
                >
                  Add Logo
                </label>
                <input
                  type="file"
                  hidden
                  id="logoinp"
                  onChange={(e) => {
                    ConvertBase64(e.target.files[0]);
                  }}
                  accept="image/jpg , image/jpeg , image/png"
                />
                <h1>(Suggested png , jpg , jpeg formats)</h1>
              </div>
              <div className="mt-3 flex flex-row items-center gap-2">
                <label
                  htmlFor="coverinp"
                  className="p-2 bg-blue-800 text-white mt-2 hover:bg-blue-500 cursor-pointer"
                >
                  Add Cover Photo
                </label>
                <input
                  type="file"
                  hidden
                  id="coverinp"
                  onChange={(e) => {
                    ChangeCover(e.target.files[0]);
                  }}
                  accept="image/jpg , image/png , image/jpeg"
                />
                <h1>(Suggested png , jpg , jpeg formats)</h1>
              </div>
            </div>
            <h1 className="font-bold text-center">Profile Details</h1>
            <div className="flex flex-row gap-2 items-center p-2">
              <label
                htmlFor="profile"
                className="p-2 bg-blue-800 text-white hover:bg-blue-500 cursor-pointer"
              >
                Add Profile Photo
              </label>
              <input
                type="file"
                name="profile"
                id="profile"
                hidden
                accept="image/jpg , image/png , image/jpeg"
                onChange={(e) => {
                  changeProfile(e.target.files[0]);
                }}
              />
              <h1>(Suggested png , jpg , jpeg formats)</h1>
            </div>
            <div className="p-2">
              <div className="flex flex-row gap-2">
                <input
                  type="text"
                  placeholder="Enter Firstname"
                  className="border p-2 outline-blue-700"
                  name="firstname"
                  onChange={ChangeHandler}
                  value={data.firstname}

                />
                <input
                  type="text"
                  placeholder="Enter Lastname"
                  className="border p-2 outline-blue-700"
                  name="lastname"
                  onChange={ChangeHandler}
                  value={data.lastname}
                />
                <input
                  type="text"
                  placeholder="Enter Designation"
                  className="border p-2 outline-blue-700"
                  name="designation"
                  onChange={ChangeHandler}
                  value={data.designation}
                />
              </div>
              <div className="mt-2 flex flex-col gap-2">
                <input
                  type="text"
                  placeholder="Enter Job Title"
                  className="border p-2 outline-blue-700"
                  name="jobtitle"
                  onChange={ChangeHandler}
                  value={data.jobtitle}
                />

                <input
                  type="text"
                  placeholder="Enter Business Name"
                  className="border p-2 outline-blue-700"
                  onChange={ChangeHandler}
                  name="businessName"
                  value={data.businessName}
                />
                <textarea
                  name="businessAddress"
                  id="address"
                  cols="30"
                  rows="3"
                  className="border p-2 outline-blue-700"
                  placeholder="Enter Business Address"
                  onChange={ChangeHandler}
                  value={data.businessAddress}
                ></textarea>
                <textarea
                  name="businessDescription"
                  id="address"
                  cols="30"
                  rows="3"
                  className="border p-2 outline-blue-700"
                  placeholder="Enter Business Description"
                  onChange={ChangeHandler}
                  value={data.businessDescription}
                ></textarea>
              </div>
            </div>
            <h1 className="font-bold text-center">Primary Actions</h1>
            <div className="p-2 flex flex-col gap-2">
              <div className="flex flex-row items-center gap-2">
                <span className="material-symbols-outlined text-blue-800">
                  phone
                </span>
                <input
                  type="number"
                  name="mobile"
                  id="mobile"
                  placeholder="Enter Mobile Number"
                  className="border p-2 outline-blue-700 w-full"
                  onChange={ChangeHandler}
                  value={data.mobile}
                />
              </div>
              <div className="flex flex-row items-center gap-2 text-blue-800">
                <span className="material-symbols-outlined">apartment</span>
                <input
                  type="number"
                  name="office"
                  id="office"
                  placeholder="Enter Office Number"
                  className="border p-2 outline-blue-700 w-full"
                  onChange={ChangeHandler}
                  value={data.office}
                />
              </div>
              <div className="flex flex-row items-center gap-2">
                <WhatsAppIcon className="text-blue-800" />
                <input
                  type="number"
                  name="whatsapp"
                  id="whatsapp"
                  placeholder="Enter Whatsapp Number"
                  className="border p-2 outline-blue-700 w-full"
                  onChange={ChangeHandler}
                  value={data.whatsapp}
                />
              </div>
              <div className="flex flex-row items-center gap-2">
                <span className="material-symbols-outlined text-blue-800">
                  language
                </span>
                <input
                  type="url"
                  name="website"
                  id="URL"
                  placeholder="Enter Website URL"
                  className="border p-2 outline-blue-700 w-full"
                  onChange={ChangeHandler}
                  value={data.website}
                />
              </div>
              <div className="flex flex-row items-center gap-2">
                <FacebookIcon className="text-blue-800" />
                <input
                  type="url"
                  name="facebook"
                  id="fb"
                  placeholder="Enter Facebook URL"
                  className="border p-2 outline-blue-700 w-full"
                  onChange={ChangeHandler}
                  value={data.facebook}
                />
              </div>
              <div className="flex flex-row items-center gap-2">
                <LinkedInIcon className="text-blue-800" />
                <input
                  type="url"
                  name="linkedin"
                  id="linkedin"
                  placeholder="Enter Linkedin URL"
                  className="border p-2 outline-blue-700 w-full"
                  onChange={ChangeHandler}
                  value={data.linkedin}
                />
              </div>
              <div className="flex flex-row items-center gap-2">
                <InstagramIcon className="text-blue-800" />
                <input
                  type="url"
                  name="instagram"
                  id="insta"
                  placeholder="Enter Instagram URL"
                  className="border p-2 outline-blue-700 w-full"
                  onChange={ChangeHandler}
                  value={data.instagram}
                />
              </div>
              <div className="flex flex-row items-center gap-2">
                <span className="material-symbols-outlined text-blue-800">
                  email
                </span>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Enter Email ID "
                  className="border p-2 outline-blue-700 w-full"
                  onChange={ChangeHandler}
                  value={data.email}
                />
              </div>
            </div>
            <div>
              <h1 className="text-center font-bold">Body Content</h1>
              <div className="p-2">
                <div
                  className="p-2 text-blue-700 outline outline-1 outline-blue-700  cursor-pointer  flex flex-row gap-2 w-40"
                  onClick={CreateSection}
                >
                  <span className="material-symbols-outlined">add</span>
                  <h1>Add Section</h1>
                </div>
              </div>
              <div id="sectionData" className="p-2">
                {data.section
                  ? data.section.map((sec) => {
                    return (
                      <div className="mt-2">
                        <input
                          type="text"
                          placeholder="Enter Section Title"
                          className="mt-2 p-2 outline-blue-800 w-full"
                          value={sec.title}
                          onChange={(e) => {
                            SectionChangeHandler(sec, e);
                          }}
                        />
                        <div className="p-2 text-white flex flex-row items-center bg-blue-800 w-full gap-3 mt-2">
                          <span className="material-symbols-outlined">
                            image
                          </span>
                          <label>
                            <h1>Add Image</h1>
                          </label>
                          <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => {
                              const dta = { ...data };
                              const index = dta.section.indexOf(sec);
                              dta.section[index].imgObj = e.target.files[0];
                              const reader = new FileReader();
                              reader.readAsDataURL(e.target.files[0]);
                              reader.onload = () => {
                                dta.section[index].media = reader.result;
                                setData(dta);
                              };
                            }}
                          />
                        </div>
                        <input
                          placeholder="Add Text"
                          className="mt-2 p-2 w-full outline-blue-800"
                          onChange={(e) => {
                            handleText(sec, e.target.value);
                          }}
                          value={sec.text}
                        />
                      </div>
                    );
                  })
                  : ""}
              </div>
              <div className="p-2">
                <h1>Select Images For Image Gallery</h1>
                <div className="p-2 text-white flex flex-row items-center bg-blue-800 w-full gap-3 mt-2">
                  <span className="material-symbols-outlined">image</span>
                  <label>
                    <h1>Pick Images</h1>
                  </label>
                  <input type="file" accept="image/*" multiple onChange={(e) => {
                    const files = e.target.files;
                    for (let i = 0; i < files.length; i++) {
                      const reader = new FileReader();
                      galleryObj.push(files[i]);
                      reader.readAsDataURL(files[i]);
                      reader.onload = () => {
                        setGallery(prev => [...prev, reader.result]);
                      }
                    }
                    console.log(galleryObj);
                  }} />
                </div>
              </div>
              <div className="p-2">
                <h1>Choose images for Product Scrolling ShowCase</h1>
                <div className="p-2 text-white flex flex-row items-center bg-blue-800 w-full gap-3 mt-2">
                  <span className="material-symbols-outlined">image</span>
                  <label>
                    <h1>Pick Images</h1>
                  </label>
                  <input type="file" accept="image/*" multiple onChange={(e) => {
                    const files = e.target.files;
                    for (let i = 0; i < files.length; i++) {
                      const reader = new FileReader();
                      scrllGallObj.push(files[i]);
                      reader.readAsDataURL(files[i]);
                      reader.onload = () => {
                        setscrllGall(prev => [...prev, reader.result]);
                      }
                    }
                  }} />
                </div>
              </div>
              <div className="p-2">
                <h1>Choose images for Hospital Scrolling ShowCase</h1>
                <div className="p-2 text-white flex flex-row items-center bg-blue-800 w-full gap-3 mt-2">
                  <span className="material-symbols-outlined">image</span>
                  <label>
                    <h1>Pick Images</h1>
                  </label>
                  <input type="file" accept="image/*" multiple onChange={(e) => {
                    const files = e.target.files;
                    for (let i = 0; i < files.length; i++) {
                      const reader = new FileReader();
                      hsptlGallObj.push(files[i]);
                      hsptlGallObj.push(files[i]);
                      reader.readAsDataURL(files[i]);
                      reader.onload = () => {
                       sethsGall(prev=>[...prev,reader.result]);
                      }
                    }
                  }} />
                </div>
              </div>
            </div>
            {updateLoading ? (
              <div className="flex flex-row p-2 justify-center">
                <CircularProgress size={25} className="mx-auto" />
              </div>
            ) : (
              <button
                className="mt-2 bg-blue-700 text-white rounded p-2 hover:bg-blue-500 w-full"
                onClick={SubmitHandler}
              >
                Update
              </button>
            )}
          </div>
        </div>
      </div>}
    </div>
  );
};

export default ManageMyCard;
