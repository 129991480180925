import React, { useEffect, useState } from 'react';
import { TableContainer, Table, TableHead, TableRow, TableCell, Typography, TableBody, LinearProgress, Button, TextField } from '@mui/material'
import BASE from '../../api/apis';
import axios from 'axios';


const ManageCategories = () =>{
    const [categories,setCategories] = useState([]);
    const [loading,setLoading] = useState(true);
    const [category,setcategory] = useState({
        category:"",
        value:""
    });
    const FetchCategories = () =>{
        setLoading(true);
        axios.get(`${BASE}/category`).then(resp=>{
            setCategories(resp.data.categories);
            setLoading(false);
        }).catch(err=>{
            setLoading(false);
            console.log(err);
        })
    };
    const AddCategory = () =>{
        axios.post(`${BASE}/category`,category).then(resp=>{
            alert(resp.data.message);
            window.location.reload();
        })
    }
    const deleteCategory = cat =>{
        const c = [...categories];
        const index = c.indexOf(cat);
        c.splice(index,1);
        setCategories(c);
        axios.delete(`${BASE}/category/${cat._id}`).then(resp=>{
            alert(resp.data.message);
        })
    }
    useEffect(()=>{
        FetchCategories();
    },[]);
    return(
        <div className='mt-2'>
            <h1 className='text-center'>Manage Categories</h1>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell><Typography className='text-center'>category</Typography></TableCell>
                            <TableCell><Typography className='text-center'>Actions</Typography></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? <TableRow>
                            {<TableCell colSpan={2}>
                                <LinearProgress/>
                            </TableCell>}
                        </TableRow>  : <>
                            {
                                categories.map(cat=>{
                                    return(
                                        <TableRow>
                                            <TableCell><Typography className='text-center'>{cat.category}</Typography></TableCell>
                                            <TableCell><div className='flex flex-row justify-center'><button className='p-2 bg-red-500 text-white mx-auto' onClick={()=>deleteCategory(cat)}>Delete</button></div></TableCell>
                                        </TableRow>
                                    );
                                })
                            }
                        </>}
                        <TableRow>
                            <TableCell>
                                <TextField label="Enter Category" className='w-full' onChange={e=>{
                                    setcategory({category:e.target.value,value:e.target.value});
                                }}/>
                            </TableCell>
                            <TableCell>
                                <div className='flex flex-row justify-center'>
                                    <Button onClick={AddCategory}>Add Category</Button>
                                </div>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default ManageCategories;