import react from "react";
import Orders from "../admin/Orders";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Logo from '../../assets/VisitingCard/logo.jpg'

const ViewOrders = () => {
  const controllerLogin = useSelector((state) => state.controllerLogin);
  const navigate = useNavigate();

  if (!controllerLogin) {
    navigate("/");
  }

  return (
    <div>
      <div className="p-2 bg-theme flex flex-row gap-2 items-center">
        <img
          src={Logo}
          alt="logo"
          width="50px"
          height="50px"
          className="rounded-full"
        />
        <h1 className="text-white">Sree Medi Systems</h1>
      </div>
      <Orders />
    </div>
  );
};

export default ViewOrders;
