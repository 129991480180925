import React, { useEffect, useState } from "react";
import { Avatar, CircularProgress } from "@mui/material";
import { uploadBytes,getDownloadURL,ref } from 'firebase/storage';
import { storage } from "../../firebase/firebase";
import { v4 } from "uuid";
import axios from 'axios';
import BASE from "../../api/apis";

const Addproduct = () =>{

    const [imgbase,setimgBase] = useState("");
    const [loading,setLoading] = useState(false);
    const [feature,setFeature] = useState("");
    const [img,setImg] = useState({});
    const [categories,setcategories] = useState([]);

    useEffect(()=>{
        FetchCategories();
    },[])

    const [data,setData] = useState({
        img:"",
        Name:"",
        Make:"",
        Model:"",
        Description:"",
        Price:"",
        Warranty:"",
        Stock:"",
        Features:[],
        category:"",
        type:""
    });

    const ConvertBase64 = file =>{
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () =>{
            setimgBase(reader.result);
        }
    };

    const changeHandler = e =>{
        setData({...data,[e.target.name]:e.target.value});
    };

    const uploadData = async () =>{
        setLoading(true);
        if(imgbase){
            const imgRef = ref(storage,`products/${img.name+v4()}`);
            await uploadBytes(imgRef,img).then(async resp=>{
                await getDownloadURL(resp.ref).then(url=>{
                    axios.post(`${BASE}/product`,{...data,img:url}).then(resp=>{
                        if(resp.data.success){
                            setLoading(false);
                            alert(resp.data.message);
                            window.location.reload();
                        }
                    }).catch(err=>{
                        setLoading(false);
                        alert(err.response.data.message);
                    })
                })
            })
        }else{
            alert("product image is required !");
            setLoading(false);
        }
        
    };

    const FetchCategories = () =>{
        axios.get(`${BASE}/category`).then(resp=>{
            setcategories(resp.data.categories);
        })
    };

    const SubmitHandler = e =>{
        e.preventDefault();
        uploadData();
    }

    return(
        <div className="border">
            <h1 className="text-center mt-2">Add product</h1>
            <form className="flex flex-col p-3 gap-3 items-center" onSubmit={SubmitHandler}>
                <Avatar variant="square" sx={{width:"100px",height:"100px"}} className="mx-auto mt-1" src={imgbase ? imgbase : ""}/>
                <label htmlFor="file" className="p-2 bg-theme text-white cursor-pointer">Choose image</label>
                <input type="file" name="" id="file" className="mt-2" hidden onChange={(e)=>{ConvertBase64(e.target.files[0]);setImg(e.target.files[0]);}}/>
                <input type="text" placeholder="Product Name" className="w-70p border p-2 outline-theme" value={data.Name} name="Name" onChange={changeHandler}/>
                <input type="text" placeholder="Product Make" className="w-70p border p-2 outline-theme" value={data.Make} name="Make" onChange={changeHandler}/>
                <input type="text" placeholder="Product Model" className="w-70p border p-2 outline-theme" value={data.Model} name="Model" onChange={changeHandler}/>
                <textarea name="Description" id="" cols="30" rows="10" placeholder="Product Description" className="w-70p border p-2 outline-theme" value={data.Description} onChange={changeHandler}></textarea>
                <input type="number" placeholder="Product Price" className="w-70p border p-2 outline-theme" value={data.Price} name="Price" onChange={changeHandler}/>
                <input type="number" placeholder="Product Warranty" className="w-70p border p-2 outline-theme" value={data.Warranty} name="Warranty" onChange={changeHandler}/>
                <input type="number" placeholder="Product Stock" className="w-70p border p-2 outline-theme" value={data.Stock} name="Stock" onChange={changeHandler}/>
                <div className="w-70p">
                    <h1 className="text-sm uppercase text-gray-500">Category</h1>
                </div>
                <select className="w-70p border p-2 outline-theme" value={data.category} onChange={e=>{
                    setData({...data,category:e.target.value});
                }}>
                    <option value="select">Select</option>
                    {
                        categories.length > 0 && <>
                            {
                                categories.map(cat=>{
                                    return(
                                        <option value={cat.value}>{cat.category}</option>
                                    );
                                })
                            }
                        </>
                    }
                </select>
                <div className="w-70p">
                    <h1 className="text-sm uppercase text-gray-500">Product Type</h1>
                </div>
                <select className="w-70p border p-2 outline-theme" value={data.type} onChange={e=>{
                    setData({...data,type:e.target.value});
                }}>
                    <option value="rental">Regular</option>
                    <option value="regular">Rental</option>
                </select>
                <div className="w-70p">
                    <h1 className="text-xs text-gray-500 uppercase p-2">Features</h1>
                    {
                        data.Features.map(ft=>{
                            return(
                                <div className="flex flex-row items-center">
                                    <span className="material-symbols-outlined text-theme">arrow_right</span>
                                    <p>{ft}</p>
                                </div>
                            );
                        })
                    }
                    <div id="featureCon" className="flex flex-row items-center gap-2">
                        <input type="text" placeholder="Feature" className="w-70p border p-2 outline-theme" onChange={e=>{
                            setFeature(e.target.value);
                        }} value={feature}/>
                        <span className="material-symbols-outlined p-2 bg-theme text-white cursor-pointer hover:bg-blue-500" onClick={()=>{
                            if(feature!==""){
                                const dta = {...data};
                                dta.Features.push(feature);
                                setFeature("");
                                setData(dta);
                            }
                        }}>add</span>
                    </div>
                </div>
                {!loading? <input type="submit" className="p-2 bg-theme text-white hover:bg-blue-400 rounded-md cursor-pointer"/> : <CircularProgress size={20}/>}
            </form>
        </div>
    );
};

export default Addproduct;