import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import BASE from '../../api/apis';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

const ViewQuote = () =>{
    const { id } = useParams();
    const [Quote,setQuote] = useState();
    const [loading,setLoading] = useState(false);
    const FetchData = async () =>{
        setLoading(true);
        await fetch(`${BASE}/quote/${id}`,{
            headers:{
                "Content-Type":"application/json"
            }
        }).then(resp=>{
            resp.json().then(dta=>{
                setQuote(dta.Quote)
                setLoading(false);
            })
        })
    }

    useEffect(()=>{
        FetchData();
    },[]);

    return(
        <div>
            {
                Quote ? <div>
                    <img src={Quote.Product.img} style={{width:"200px",height:"auto"}} className='mx-auto mt-3'/>
                    <div>
                        <h1 className='text-center mt-4'>
                            <b>Product Details</b>
                        </h1>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Product Name</TableCell>
                                        <TableCell>Product Make</TableCell>
                                        <TableCell>Product Model</TableCell>
                                        <TableCell>Product Price</TableCell>
                                        <TableCell>Product Stock</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>{Quote.Product.Name}</TableCell>
                                        <TableCell>{Quote.Product.Make}</TableCell>
                                        <TableCell>{Quote.Product.Model}</TableCell>
                                        <TableCell>{Quote.Product.Price}</TableCell>
                                        <TableCell>{Quote.Product.Stock}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    <div>
                        <h1 className='text-center mt-4'>
                            <b>User Details</b>
                        </h1>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell>Mobile</TableCell>
                                        <TableCell>Whatsapp</TableCell>
                                        <TableCell>Quantity</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>{Quote.Name}</TableCell>
                                        <TableCell>{Quote.Email}</TableCell>
                                        <TableCell>{Quote.Mobile}</TableCell>
                                        <TableCell>{Quote.Whatsapp}</TableCell>
                                        <TableCell>{Quote.Quantity}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            :""}
        </div>
    );
};

export default ViewQuote;