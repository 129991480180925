import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import BASE from '../../api/apis';
import { Avatar, CircularProgress } from '@mui/material';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { storage } from '../../firebase/firebase';
import { v4 } from 'uuid';

const EditProduct = () =>{
    const { id } = useParams();
    const [loading,setLoading] = useState(false);
    const [feature,setFeature] = useState("");
    const [img,setImg] = useState({});

    useEffect(()=>{
        axios.get(`${BASE}/product/${id}`).then(resp=>{
            const dta = {...resp.data.prdct};
            setData(dta);
        }).catch(err=>{
            console.log(err);
        })
    },[id]);

    const [data,setData] = useState({
        img:"",
        Name:"",
        Make:"",
        Model:"",
        Description:"",
        Price:"",
        Warranty:"",
        Stock:"",
        Features:[],
        type:"",
        category:""
    });

    const ConvertBase64 = file =>{
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () =>{
            setData({...data,img:reader.result});
        }
    };

    const changeHandler = e =>{
        setData({...data,[e.target.name]:e.target.value});
    };

    const SubmitHandler = async e =>{
        setLoading(true);
        e.preventDefault();
        const imgRef = ref(storage,`products/${img.name+v4()}`);
        await uploadBytes(imgRef,img).then(async resp=>{
            await getDownloadURL(resp.ref).then(url=>{
                setData({...data,img:url});
                axios.put(`${BASE}/product/${id}`,data).then(resp=>{
                    alert(resp.data.message);
                    setLoading(false);
                }).catch(err=>{
                    setLoading(false);
                    console.log(err);
                })
            }).catch(err=>{
                console.log(err);
            })
        }).catch(err=>{
            console.log(err);
        })
    }
    return(
        <div>
            <h1 className='text-center mt-2'>Edit product</h1>
            <form className="flex flex-col p-3 gap-3 items-center" onSubmit={SubmitHandler}>
                <Avatar variant="square" sx={{width:"100px",height:"100px"}} className="mx-auto mt-1" src={data.img}/>
                <label htmlFor="file" className="p-2 bg-theme text-white cursor-pointer">Choose image</label>
                <input type="file" name="" id="file" className="mt-2" hidden onChange={(e)=>{ConvertBase64(e.target.files[0]);setImg(e.target.files[0]);}}/>
                <input type="text" placeholder="Product Name" className="w-70p border p-2 outline-theme" value={data.Name} name="Name" onChange={changeHandler}/>
                <input type="text" placeholder="Product Make" className="w-70p border p-2 outline-theme" value={data.Make} name="Make" onChange={changeHandler}/>
                <input type="text" placeholder="Product Model" className="w-70p border p-2 outline-theme" value={data.Model} name="Model" onChange={changeHandler}/>
                <textarea name="Description" id="" cols="30" rows="10" placeholder="Product Description" className="w-70p border p-2 outline-theme" value={data.Description} onChange={changeHandler}></textarea>
                <input type="number" placeholder="Product Price" className="w-70p border p-2 outline-theme" value={data.Price} name="Price" onChange={changeHandler}/>
                <input type="number" placeholder="Product Warranty" className="w-70p border p-2 outline-theme" value={data.Warranty} name="Warranty" onChange={changeHandler}/>
                <input type="number" placeholder="Product Stock" className="w-70p border p-2 outline-theme" value={data.Stock} name="Stock" onChange={changeHandler}/>
                <div className="w-70p">
                    <h1 className="text-sm uppercase text-gray-500">Category</h1>
                </div>
                <select className="w-70p border p-2 outline-theme" value={data.category} onChange={e=>{
                    setData({...data,category:e.target.value});
                }}>
                    <option value="select">Select</option>
                    <option value="cots">Cots</option>
                    <option value="Attender Couch">Attender Couch</option>
                    <option value="Examination Couch">Examination Couch</option>
                    <option value="Wheel Chairs">Wheel Chairs</option>
                    <option value="Trolley">Trolley</option>
                    <option value="Bed Side Locker">Bed Side Locker</option>
                    <option value="Foot Stool">Foot Stool</option>
                    <option value="Revolving Stools">Revolving Stools</option>
                    <option value="Bed Side Screens">Bed Side Screens</option>
                    <option value="Baby Cradle">Baby Cradle</option>
                    <option value="Saline Stand">Saline Stand</option>
                    <option value="Basin Stand">Basin Stand</option>
                    <option value="Other">Other</option>

                </select>
                <div className="w-70p">
                    <h1 className="text-sm uppercase text-gray-500">Product Type</h1>
                </div>
                <select className="w-70p border p-2 outline-theme" value={data.type} onChange={e=>{
                    setData({...data,type:e.target.value});
                }}>
                    <option value="select">Select</option>
                    <option value="regular">Regular`</option>
                    <option value="rental">Rental</option>
                </select>
                
                <div className="w-70p">
                    <h1 className="text-xs text-gray-500 uppercase p-2">Features</h1>
                    {
                        data.Features && data.Features.map(ft=>{
                            return(
                                <div className="flex flex-row items-center">
                                    <span className="material-symbols-outlined text-theme">arrow_right</span>
                                    <p>{ft}</p>
                                </div>
                            );
                        })
                    }
                    <div id="featureCon" className="flex flex-row items-center gap-2">
                        <input type="text" placeholder="Feature" className="w-70p border p-2 outline-theme" onChange={e=>{
                            setFeature(e.target.value);
                        }} value={feature}/>
                        <span className="material-symbols-outlined p-2 bg-theme text-white cursor-pointer hover:bg-blue-500" onClick={()=>{
                            const dta = {...data};
                            dta.Features.push(feature);
                            setFeature("");
                            setData(dta);
                        }}>add</span>
                    </div>
                </div>
                {!loading? <input type="submit" className="p-2 bg-theme text-white hover:bg-blue-400 rounded-md cursor-pointer"/> : <CircularProgress size={20}/>}
            </form>
        </div>
    );
};  

export default EditProduct;