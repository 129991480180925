import React, { useEffect, useState } from "react";
import Header from "../../components/Home/Header";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import BASE from "../../api/apis";
import { Table, TableCell, TableContainer, TableHead } from "@mui/material";

const MyOrders = () => {
  const userState = useSelector((state) => state.CustomerLogin);
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const navigate = useNavigate();
  const [customer, setCustomer] = useState();

  if (!userState) {
    navigate("/");
  }

  const DecodeUserData = async () => {
    setLoading(true);
    const cust = await JSON.parse(localStorage.getItem("SMS_USER_TK"));
    if (cust) {
      const dta = await jwtDecode(cust);
      setCustomer(dta.customer);
      axios.get(`${BASE}/customer/orders/${dta.customer._id}`).then((resp) => {
        if (resp.data.success) {
          setLoading(false);
          setOrders(resp.data.orders);
          console.log(resp.data)
        } else {
          setLoading(false);
          alert(resp.data.message);
        }
      });
    } else {
      navigate("/login");
    }
  };

  useEffect(() => {
    DecodeUserData();
  },[]);

  return (
    <div>
      <Header />
      <div className="p-2">
        <h1 className="font-bold text-center">My Orders</h1>
      </div>
      <div>
        <TableContainer>
            <Table>
                <TableHead>
                    <TableCell><p className="text-center"></p></TableCell>
                </TableHead>
            </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default MyOrders;
