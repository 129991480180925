import React from "react";
import Logo from '../../assets/VisitingCard/logo.jpg';
import ViewServices from "../admin/ViewService";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import GetAllServices from "../admin/GetAllServices";

const ViewService = () => {

    const state = useSelector(state=>state.serviceControllerLogin);
    const navigate = useNavigate();

    if(!state){
        navigate("/");
    }

  return (
    <div>
      <div className="p-2 bg-theme flex flex-row gap-2 items-center">
        <img
          src={Logo}
          alt="logo"
          width="50px"
          height="50px"
          className="rounded-full"
        />
        <h1 className="text-white">Sree Medi Systems</h1>
      </div>
      <div>
        <GetAllServices/>
      </div>
    </div>
  );
};

export default ViewService;
