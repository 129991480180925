import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BASE from "../../api/apis";
import OldTypicalPhone from "../../assets/VisitingCard/old-typical-phone.png";
import Mail from "../../assets/VisitingCard/mail.png";
import WhatsApp from "../../assets/VisitingCard/whatsapp.png";
import Web from "../../assets/VisitingCard/web.png";
import Iphone from "../../assets/VisitingCard/iphone.png";
import FaceBook from "../../assets/VisitingCard/facebook-circular-logo.png";
import Instagram from "../../assets/VisitingCard/instagram.png";
import linkedin from "../../assets/VisitingCard/linkedin.png";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { v4 } from "uuid";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  LinearProgress,
  Typography,
  Dialog,
  Box,
} from "@mui/material";
import { Splide, SplideSlide } from "@splidejs/react-splide";

const ViewCard = () => {
  const { id } = useParams();
  const [card, setCard] = useState("");
  const [loading, setLoading] = useState(false);
  const [share,setShare] = React.useState(false);
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${BASE}/visitingcard/${id}`)
      .then((resp) => {
        setLoading(false);
        if (resp.data.success) {
          setCard(resp.data.card);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);
  return (
    <div>
      <div className="h-16 bg-theme hidden md:block"></div>
      <div className=" bg-blue-200 h-64 flex-row items-end justify-end pr-20 pb-10 gap-5 hidden md:flex deskcover">
        <div className="flex flex-row items-center gap-2 cursor-pointer p-4 bg-theme rounded-md text-white">
          <span className="material-symbols-outlined">share</span>
          <p>Share</p>
        </div>
        <div className="flex flex-row items-center gap-2 cursor-pointer p-4 bg-theme rounded-md text-white">
          <span className="material-symbols-outlined">person_add</span>
          <p>Add to Contacts</p>
        </div>
        <Dialog open={share} onClose={() => setShare(false)}>
          <Box sx={{ width: "300px", height: "100px", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <a href={`whatsapp://send?text=${window.location.href}`} data-action="share/whatsapp/share"><div className="p-2"><WhatsAppIcon className="text-green-700 cursor-pointer" /> Share on Whatsapp</div></a>
          </Box>
        </Dialog>
      </div>
      {loading && <LinearProgress />}
      <div>
        {card ? (
          <div>
            <div className="border border-gray-300 md:w-96 bg-white rounded-lg md:-mt-32 mx-auto">
              <div className="border p-1 flex flex-row items-center gap-2">
                <img src={card.logo} alt="logo" width="50px" height="50px" className="rounded-full" />
                <p>{card.businessName}</p>
              </div>
              <div className="bg-blue-300">
                <img src={card.cover} alt="cover" height="40px" />
              </div>
              <div className="flex flex-row">
                <img
                  src={card.profile}
                  alt="profile"
                  width="110px"
                  height="110px"
                  className="outline-2 outline-double outline-offset-2 outline-blue-700 mt-2 mx-2"
                />
                <div className="">
                  <h1 className="text-2xl mt-5 mx-2">
                    {card.firstname} {card.lastname}
                  </h1>
                  {card.designation ? <p className="text-right text-xs">{card.designation}</p> : ""}
                  <h1 className="ml-1">{card.jobtitle}</h1>
                </div>
              </div>
              <div>
                <div className="flex flex-row justify-center pt-4 gap-5">
                  <a href={`tel:${card.office}`}>
                    <div className="flex flex-col items-center">
                      <img
                        src={OldTypicalPhone}
                        alt="Office"
                        width="40px"
                        height="40px"
                      />
                      <p className="text-sm">Office</p>
                    </div>
                  </a>
                  <a href={`mailto:${card.email}`}>
                    <div className="flex flex-col items-center">
                      <img src={Mail} alt="email" width="40px" height="40px" />
                      <p className="text-sm">Email</p>
                    </div>
                  </a>
                  <a
                    href={`https://api.whatsapp.com/send?phone=${card.whatsapp}&text=Hello !`}
                  >
                    <div className="flex flex-col items-center">
                      <img
                        src={WhatsApp}
                        alt="whatsapp"
                        width="40px"
                        height="40px"
                      />
                      <p className="text-sm">Whatsapp</p>
                    </div>
                  </a>
                  <a href={card.website} target="_blank">
                    <div className="flex flex-col items-center">
                      <img src={Web} alt="web" width="40px" height="40px" />
                      <p className="text-sm">Website</p>
                    </div>
                  </a>
                  <a href={`tel:${card.mobile}`}>
                    <div className="flex flex-col items-center">
                      <img
                        src={Iphone}
                        alt="phone"
                        width="40px"
                        height="40px"
                        className="ml-4"
                      />
                      <p className="text-sm">Mobile</p>
                    </div>
                  </a>
                </div>
                {/*second*/}
                <div className="flex flex-row justify-center pt-4 gap-3 pl-3">
                  <a href={card.facebook}>
                    <div className="flex flex-col items-center">
                      <img
                        src={FaceBook}
                        alt="Office"
                        width="40px"
                        height="40px"
                      />
                      <p className="text-sm">FaceBook</p>
                    </div>
                  </a>
                  <a href={card.instagram}>
                    <div className="flex flex-col items-center">
                      <img
                        src={Instagram}
                        alt="email"
                        width="40px"
                        height="40px"
                      />
                      <p className="text-sm">Instagram</p>
                    </div>
                  </a>
                  <a href={card.linkedin}>
                    <div className="flex flex-col items-center">
                      <img
                        src={linkedin}
                        alt="whatsapp"
                        width="40px"
                        height="40px"
                      />
                      <p className="text-sm">Linkedin</p>
                    </div>
                  </a>
                  <div className="flex flex-col items-center" onClick={()=>setShare(true)}>
                      <span className="material-symbols-outlined bg-theme p-2 rounded-full text-white">share</span>
                      <p className="text-sm">Share</p>
                    </div>
                </div>
              </div>
              <div>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>About</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>{card.businessDescription}</Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div>
                {card.section
                  ? card.section.map((sec) => {
                    return (
                      <div>
                        <h1 className="text-center mt-2 font-semibold">
                          {sec.title}
                        </h1>
                        {sec.media && <img src={sec.media} className="p-2" />}
                        {sec.video && (
                          <video
                            src={sec.video}
                            className="p-2"
                            autoPlay={true}
                          ></video>
                        )}
                        {sec.text && <p className="p-3">{sec.text}</p>}
                      </div>
                    );
                  })
                  : ""}
              </div>
              <div>
                {
                  card.hospitalsGallery.length > 0 ? <div>
                    <Splide options={{
                      type: "loop",
                      gap: "10px",
                      drag: "free",
                      arrows: false,
                      pagination: false,
                      perPage: 1,
                      autoScroll: {
                        pauseOnHover: false,
                        pauseOnFocus: false,
                        rewind: false,
                        speed: 3,
                      },
                    }}
                      extensions={{ AutoScroll }}>
                      {
                        card.hospitalsGallery.map(idf => {
                          return (
                            <SplideSlide>
                              <img src={idf} className="w-full h-40" />
                            </SplideSlide>
                          );
                        })
                      }
                    </Splide>
                  </div> : ""
                }
              </div>
              <div>
                {card.gallery.length > 0 ? (
                  <div>
                    <h1 className="p-2 font-semibold uppercase">Gallery</h1>
                    <div className="p-1 grid grid-cols-4 gap-1">
                      {card.gallery.map((itm) => {
                        return (
                          <img src={itm} className="w-24 h-24" key={v4()} />
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="p-3 bg-theme text-white">
                <h1 className="text-center text-xs">Powered By Sree Medi Systems</h1>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default ViewCard;
