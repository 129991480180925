import { Button, ThemeProvider, createTheme, CircularProgress } from '@mui/material';
import Logo from '../assets/VisitingCard/logo.jpg';
import React, { useState } from 'react';
import axios from 'axios';
import BASE from '../api/apis';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Actions } from '../redux/store';

const AdminPage = () =>{

    const theme = createTheme({
        palette:{
            primary:{
                main:'#00329B'
            }
        }
    });

    const [data,setData] = useState({
        Email:"",
        Password:""
    });

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading,setLoading] = useState(false);

    const ChangeHandler = e =>{
        setData({...data,[e.target.name]:e.target.value});
    };

    const SubmitHandler = () =>{
        setLoading(true);
        axios.post(`${BASE}/admin/login`,data).then(resp=>{
            setLoading(false);
            if(resp.data.success){
                dispatch(Actions.setAdmin(true));
                sessionStorage.setItem("admin",JSON.stringify(resp.data.token));
                navigate("/admin/home");
            }else{
                alert(resp.data.message);
            }
        }).catch(err=>{
            console.log(err);
        })
    };

    return(
        <div>
            <ThemeProvider theme={theme}>
                <div className='p-2 bg-theme flex flex-row gap-2 items-center'>
                    <img src={Logo} alt="logo" width="50px" height="50px" className='rounded-full'/>
                    <h1 className='text-white'>Sree Medi Systems</h1>
                </div>
                <div className="w-60 mx-auto mt-1 h-screen flex flex-col items-center gap-2">
                    <h1 className='text-center mt-3 font-bold rounded'>Login</h1>
                    <input type="email" placeholder='Enter Email' className='w-full p-1' value={data.Email} name="Email" onChange={ChangeHandler}/>
                    <input type="password" placeholder='Enter Password' className='w-full p-1' value={data.Password} name="Password" onChange={ChangeHandler}/>
                    {!loading ? <Button variant='contained' className='w-full' onClick={SubmitHandler}>Login</Button> : <CircularProgress className="mx-auto" size={20}/>}
                </div>
            </ThemeProvider>
        </div>
    );
};

export default AdminPage;
