import React, { useEffect, useState } from 'react';
import Logo from '../../assets/VisitingCard/logo.jpg';
import { Avatar, Box, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Cart = () =>{

    let [totalprice,setTotalPrice] = useState(0);
    const [cartItems,setCartItems] = useState([]);
    const customerLogin = useSelector(state=>state.CustomerLogin);
    const navigate = useNavigate();

    const FetchProducts = () =>{
        const data = JSON.parse(localStorage.getItem("cartitems"));
        setCartItems(data);
    }

    const RemoveFromCart = (itm) =>{
        const items = [...cartItems];
        const index = items.indexOf(itm);
        items.splice(index,1);
        setCartItems(items);
        localStorage.setItem("cartitems",JSON.stringify(items));
    }

    useEffect(()=>{
        FetchProducts();
    },[])

    return(
        <div>
            <div className='p-2 bg-theme flex flex-row gap-2 items-center'>
                <img src={Logo} alt="logo" width="50px" height="50px" className='rounded-full'/>
                <h1 className='text-white'>Sree Medi Systems</h1>
            </div>
            <div className='p-3'>
                <h1 className='text-center font-bold'>Cart Items</h1>
                {
                    (cartItems && cartItems.length > 0) ? <div>
                        {
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                       <TableRow>
                                        <TableCell>Image</TableCell>
                                            <TableCell>Name</TableCell>
                                                <TableCell>Model</TableCell>
                                            <TableCell><p className='text-center'>Quantity</p></TableCell>
                                            <TableCell><p className='text-center'>Price</p></TableCell>
                                            <TableCell>Action</TableCell>
                                       </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            cartItems.map(itm=>{
                                                if(itm.Quantity > 1){
                                                    totalprice += itm.Price * itm.Quantity;
                                                }else{
                                                    totalprice = totalprice+itm.Price;
                                                }
                                                return(
                                                    <TableRow>
                                                        <TableCell><Avatar src={itm.img}/></TableCell>
                                                        <TableCell>{itm.Name}</TableCell>
                                                        <TableCell><p>{itm.Model}</p></TableCell>
                                                        <TableCell>
                                                            <Box>
                                                                <p className='text-center'>{itm.Quantity}</p>
                                                                <div className='flex flex-row items-center justify-center gap-2'>
                                                                    <span className='material-symbols-outlined cursor-pointer rounded-full bg-theme text-white' onClick={()=>{
                                                                        const data = [...cartItems];
                                                                        const index = data.indexOf(itm);
                                                                        if(data[index].Quantity >= 1){
                                                                            data[index].Quantity+=1;
                                                                            totalprice = totalprice+itm.Price;
                                                                            setCartItems(data);
                                                                            localStorage.setItem("cartitems",JSON.stringify(cartItems));
                                                                            
                                                                        }
                                                                    }}>add</span>
                                                                    <span className='material-symbols-outlined cursor-pointer rounded-full bg-theme text-white' onClick={()=>{
                                                                        const data = [...cartItems];
                                                                        const index = data.indexOf(itm);
                                                                        if((data[index].Quantity >= 1) && (data[index].Quantity!=1)){
                                                                            data[index].Quantity-=1;
                                                                            totalprice = totalprice-itm.Price;
                                                                            setCartItems(data);
                                                                            localStorage.setItem("cartitems",JSON.stringify(cartItems));
                                                                        }
                                                                    }}>remove</span>
                                                                </div>
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell><p className='text-center'>Rs.{itm.Price}/-</p></TableCell>
                                                        <TableCell>
                                                            <div>
                                                                <span className='p-2 bg-red-800 text-white material-symbols-outlined cursor-pointer' onClick={()=>RemoveFromCart(itm)}>delete</span>
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })
                                        }
                                    </TableBody>
                                </Table>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><p className='font-bold uppercase'>subTotal</p></TableCell>
                                            <TableCell><p className='font-bold text-center'>Rs.{totalprice}/-</p></TableCell>
                                        </TableRow>
                                    </TableHead>
                                </Table>
                            </TableContainer>
                        }
                        <div className='flex flex-col gap-3 justify-center mt-5'>
                            <p className='text-xs text-center'>Tax included. Shipping calculated at checkout.</p>
                            <span className='p-2 bg-theme text-white mx-auto cursor-pointer' onClick={()=>{
                                sessionStorage.setItem("buy",JSON.stringify(cartItems));
                                customerLogin ? navigate("/checkout") : navigate("/login");
                            }}>Checkout</span>
                        </div>
                    </div>  : <div className='flex flex-col items-center'>
                        <h1 className='text-center mt-2'>Your Cart is Empty</h1>
                        <a href="/store" className='text-xs p-2 bg-theme text-white mt-3'>Continue Shopping</a>
                    </div>
                }
            </div>
        </div>
    );
};

export default Cart;