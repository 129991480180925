import React from "react";
import Logo from "../../assets/VisitingCard/logo.jpg";

const ShippingPolicy = () => {
  return (
    <div>
      <div className="p-2 bg-theme flex flex-row gap-2 items-center">
        <img
          src={Logo}
          alt="logo"
          width="50px"
          height="50px"
          className="rounded-full"
        />
        <h1 className="text-white">Sree Medi Systems</h1>
      </div>
      <h1 className="p-2 text-center font-bold">Shipping Policy</h1>
      <div className="p-2">
        <p className="p-2">Thank you for choosing sreemedisystems for your online shopping needs. We aim to provide a seamless and efficient shipping experience for our valued customers. Please read the following shipping policy to understand how we handle shipping and delivery.</p>
        <h1 className="p-2 font-bold">1. Shipping Locations:</h1> className="p-2 font-bold"
        <p className="p-2">We currently ship to All over india . If your location is not covered, please contact our customer support for assistance.</p>
        <h1 className="p-2 font-bold">2. Shipping Methods and Timelines:</h1>
        <p className="p-2">We offer several shipping options to meet your needs. The estimated delivery time and cost will be provided at checkout. Please note that delivery times may vary based on your location and the chosen shipping method.</p>
        <h1 className="p-2 font-bold">3. Order Processing Time:</h1>
        <p className="p-2">Orders are typically processed within 2-3 business days from the date of purchase. Please allow additional time during peak seasons or promotional periods.</p>
        <h1 className="p-2 font-bold">4. Shipping Costs:</h1>
        <p className="p-2">Shipping fees are calculated based on the weight of your order and the selected shipping method. The total shipping cost will be displayed at checkout before you complete your purchase.</p>
        <h1 className="p-2 font-bold">5. Free Shipping:</h1>
        <p className="p-2">Enjoy free standard shipping on orders. Note that free shipping may not be available during certain promotions or for specific locations and it is based on the purchased product and location.</p>
        <h1 className="p-2 font-bold">6. Order Tracking:</h1>
        <p className="p-2">Once your order has been shipped, you will receive an email with tracking information. Use this information to track your order's journey and estimated delivery date.</p>
        <h1 className="p-2 font-bold">7. Tracking Updates:</h1>
        <p className="p-2">Tracking updates may take some time to appear after your order has been shipped. If you encounter any issues with tracking or have questions, please contact our customer support team.</p>
        <h1 className="p-2 font-bold">8. Customs and Import Duties:</h1>
        <p className="p-2">Customers are responsible for any customs and import duties that may apply to their orders. These fees are determined by the destination country and are not included in the order total.</p>
        <h1 className="p-2 font-bold">10. Contact Information:</h1>
        <p className="p-2">If you have any questions or concerns about our shipping policy, please contact our customer support team at sreemedisystems@gmail.com  or 9849146432.</p>
        <p>Thank you for choosing sreemedisystems! We appreciate your business and strive to provide you with the best possible shopping experience.</p>
      </div>
    </div>
  );
};

export default ShippingPolicy;
