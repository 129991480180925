import { Avatar, CircularProgress, LinearProgress } from '@mui/material';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import React, { useEffect, useState } from 'react';
import { storage } from '../../firebase/firebase';
import BASE from '../../api/apis';
import axios from 'axios';
import { v4 } from 'uuid';
import { useParams } from 'react-router-dom';

const UpdateUser = () =>{

    const { id } = useParams();

    const [data,setData] = useState({
        Name:"",
        Email:"",
        Mobile:"",
        userType:"AMC",
        Profile:""
    });

    const FetchUser = async () =>{
        setLoad(true);
        axios.get(`${BASE}/user/${id}`).then(resp=>{
            setData(resp.data.user);
            setBase(resp.data.Profile)
            setLoad(false);
        }).catch(err=>{        
            setLoad(false);
            console.log(err);
        })
    }

    useEffect(()=>{
        FetchUser();
    },[])

    const [img,setImg] = useState();
    const [base,setBase] = useState();
    const [loading,setLoading] = useState(false);
    const [load,setLoad] = useState(false);

    const ConvertBase64 = file =>{
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () =>{
            setBase(reader.result);
        }
    }

    const changeHandler = e =>{
        setData({...data,[e.target.name]:e.target.value});
    }

    const SubmitHandler = async (e) =>{
        e.preventDefault();
        if((data.Password !== null)&&(data.Password !== data.ConfirmPassword)){
           alert("Passwords doesnot match"); 
        }else{
            if(img){
                setLoading(true);
                const refer = ref(storage,`/users/${img.name+v4()}`);
                await uploadBytes(refer,img).then(async resp=>{
                    await getDownloadURL(resp.ref).then(async url=>{
                        axios.put(`${BASE}/user/${id}`,{...data,Profile:url},{
                            withCredentials:true
                        }).then(resp=>{
                            setLoading(false);
                            if(resp.data.success){
                                alert(resp.data.message);
                            }else{
                                alert(resp.data.message);
                            }
                        })
                    })
                })
            }else{
                setLoading(true);
                axios.put(`${BASE}/user/${id}`,data,{
                    withCredentials:true
                }).then(resp=>{
                    setLoading(false);
                    if(resp.data.success){
                        alert(resp.data.message);
                    }else{
                        alert(resp.data.message);
                    }
                })
            }
        }
    }

    return(
        <div>
            {load && <LinearProgress/>}
            {!load && <div>
                <h1 className='text-center mt-2'>Update User</h1>
                <form className='flex flex-col p-3 gap-3 items-center' onSubmit={SubmitHandler}>
                    <Avatar variant="square" sx={{width:"100px",height:"100px"}} className="mx-auto mt-1" src={base ? base : ""}/>
                    <label htmlFor="file" className="p-2 bg-theme text-white cursor-pointer">Choose image</label>
                    <input type="file" name="" id="file" className="mt-2" hidden onChange={(e)=>{ConvertBase64(e.target.files[0]);setImg(e.target.files[0]);}} accept='image/*'/>
                    <input type="text" placeholder="Enter User Name" className="w-70p border p-2 outline-theme" name="Name" onChange={changeHandler} value={data.Name}/>
                    <input type="email" placeholder="Enter Email" className="w-70p border p-2 outline-theme" name="Email" onChange={changeHandler} value={data.Email}/>
                    <input type="number" placeholder="Enter Mobile Number" className="w-70p border p-2 outline-theme" name="Mobile" onChange={changeHandler} value={data.Mobile}/>
                    <select className="w-70p border p-2 outline-theme" value={data.userType} onChange={changeHandler} name="userType" >
                        <option value="AMC">AMC</option>
                        <option value="CMC">CMC</option>
                        <option value="ENGINEER">Engineer</option>
                    </select>
                    {!loading ? <input type="submit" className="w-auto border p-2 bg-theme text-white hover:bg-blue-700 cursor-pointer"/> : <CircularProgress size={25}/>}
                </form>
            </div>}
        </div>
    );
};

export default UpdateUser;