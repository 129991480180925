import React from "react";
import Logo from "../../assets/VisitingCard/logo.jpg";
import { Rating } from "@mui/material";
import { Avatar}  from "@mui/material";

const Ratings = () => {
  return (
    <div>
      <div className="flex flex-row gap-2 bg-blue-700 items-center p-2">
        <img src={Logo} width="40px" height="40px" className="rounded-full" />
        <h1 className="text-white">Sree Medi Systems</h1>
      </div>
      <h1 className="text-center mt-2 font-semibold">Ratings & Reviews</h1>
      <hr className="mt-1 border-blue-700 border-2 w-72 mx-auto" />
      <div className="p-2 flex flex-row flex-wrap gap-3 justify-center">
        <div className="p-2 flex flex-row gap-4 border w-72">
          <Avatar />
          <div>
            <h1>P Sai Prasad</h1>
            <Rating readOnly value={5} size="small" />
            <p className="text-xs">Quality products & Good Service</p>
          </div>
        </div>
        <div className="p-2 flex flex-row gap-4 border w-72">
          <Avatar />
          <div>
            <h1>Chitra</h1>
            <Rating readOnly value={5} size="small" />
            <p className="text-xs">Good Service</p>
          </div>
        </div>
        <div className="p-2 flex flex-row gap-4 border w-72">
          <Avatar />
          <div>
            <h1>Ravikumar</h1>
            <Rating readOnly value={5} size="small" />
          </div>
        </div>
        <div className="p-2 flex flex-row gap-4 border w-72">
          <Avatar />
          <div>
            <h1>Avinash</h1>
            <Rating readOnly value={5} size="small" />
            <p className="text-xs">Good Quality !</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ratings;
