import React, { useEffect, useState } from 'react';
import HMPER from '../../assets/Home/hmprsn.png';
import HMPER2 from '../../assets/Home/hmprsn2.png';
import HM3 from '../../assets/Home/hm3.jpg';
import Copy from '../../assets/Home/copy.png';
import Walker from '../../assets/Home/walker.png';
import Cup from '../../assets/Home/cup.png';
import Note from '../../assets/Home/sticky-note.png';
import Tsk from '../../assets/Home/goal.png';
import Quality from '../../assets/Home/quality.png';
import { Product_Categories } from '../../data/Equipment';
import ContactTab from '../../components/Home/ContactTab';
import Header from '../../components/Home/Header';
import Footer from '../../components/Home/Footer';
import axios from 'axios';
import BASE from '../../api/apis';
import { v4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../components/Home/Sidebar';

const Homepage = () => {

    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const navigate = useNavigate();


    const FetchData = () => {
        setLoading(true);
        axios.get(`${BASE}/product/getlimitedproduct`).then(dta => {
            setLoading(false);
            setProducts(dta.data.products);
        }).catch(err=>{
            alert(err.message)
        })
    }

    useEffect(() => {
        FetchData();
    }, []);

    return (
        <React.Fragment>
            <ContactTab />
            <Sidebar/>
            <Header />
            <div className='hmCon'>
                <div className="columns-lg mx-auto lg:h-700 container h-500">
                    <div className="flex flex-col justify-center items-center lg:pt-48">
                        <div>
                            <h1 className="lg:text-5xl font-bold text-2xl pt-5 greetText">WELCOME TO <br /> <span className='lg:text-6xl text-bgTheme text-3xl'>S</span>REE MEDI SYSTEMS</h1>
                            <p>Medical Equipment , Manufacturer and service provider.</p>
                        </div>
                    </div>
                    <div className="flex items-center justify-center lg:pt-20 pt-10">
                        <img src={HMPER} alt="hmper" className='w-80 lg:w-auto' />
                    </div>
                </div>
            </div>
            <div>
                <h1 className='text-center text-3xl mt-4'>Our Products</h1>
                <hr className='w-60 mx-auto mt-2 border-none h-1 bg-bgTheme' />
                <div className="container mx-auto">
                    <p className='text-center mt-2 p-2'>We, Sree Medi Systems (Since 2000) is one of the Prominent Distributors for Anaesthesia Machines, <br />Anaesthesia Workstation, OT Tables, Lights, Diathermy Machines, Monitors, X-Ray Machines, Ventilators and Associated Accessories.</p>
                </div>
            </div>
            <div className='pb-4'>
                <div className='flex overflow-scroll mx-auto container items-center lg:justify-center'>
                    <div className='flex flex-row gap-4'>
                        {
                            Product_Categories.slice(0, 4).map(eq => {
                                return (
                                    <div className='flex flex-col w-80 border ml-2 shadow-xl rounded-xl cursor-pointer' key={eq.name}>
                                        <img src={eq.image} alt="equip" className='w-full h-80' />
                                        <h1 className='mt-3 text-center font-bold'>{eq.name}</h1>
                                        <hr className='mt-2 h-1 border-none bg-bgTheme' />
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
                <div className='flex overflow-scroll mx-auto container mt-3 items-center lg:justify-center'>
                    <div className='flex flex-row gap-4 justify-center '>
                        {
                            Product_Categories.slice(4, 8).map(eq => {
                                return (
                                    <div className='flex flex-col w-80 border ml-2 shadow-xl rounded-xl cursor-pointer' key={eq.name}>
                                        <img src={eq.image} alt="equip" className='w-96 h-80' />
                                        <h1 className='mt-3 text-center font-bold'>{eq.name}</h1>
                                        <hr className='mt-2 h-1 border-none bg-bgTheme' />
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
            <div>
                <hr className='border border-blue-800 h-2 bg-theme' />
                <br /><br />
                <div className='flex flex-row overflow-scroll'>
                    {
                        (!loading && products) ? <div className=' gap-2 justify-center grid grid-cols-2 sm:grid-cols-4 md:grid-cols-4 xl:grid-cols-6 2xl:grid-cols-6 mx-auto'>
                            {
                                products.map(prdct => {
                                    return (
                                        <div
                                            className="w-48 border hover:border-gray-400 hover:shadow-2xl p-3 "
                                            key={v4()}
                                            onClick={()=>navigate(`/product/${prdct._id}`)}>
                                            <img src={prdct.img} width="100%" className="h-40" />
                                            <h1 className="p-1 text-xs">
                                                <span className="text-blue-800 font-bold">
                                                    Name :
                                                </span>{" "}
                                                {prdct.Name}
                                            </h1>
                                            <h1 className="text-xs p-1">
                                                <span className="text-blue-800 font-bold">
                                                    Make :{" "}
                                                </span>
                                                {prdct.Make}
                                            </h1>
                                            {prdct.Price && <h1 className="text-xs p-1">
                                                <span className="text-blue-800 font-bold">
                                                    Price : {" "}
                                                </span>
                                                ₹ {prdct.Price} /-
                                            </h1>}
                                            <h1 className="text-xs p-1">
                                                <span className="text-blue-800 font-bold">
                                                    Stock : {" "}
                                                </span>
                                                {prdct.Stock}
                                            </h1>
                                            <h1 className="text-xs p-1">
                                                <span className="text-blue-800 font-bold">
                                                    Model :{" "}
                                                </span>
                                                {prdct.Model}
                                            </h1>
                                            <div className=''>
                                                <button className='bg-blue-800 w-full p-2 text-white'>Buy</button>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div> : ""
                    }
                </div>
                <div className='p-4 bg-theme mt-4 text-white w-44 text-center mx-auto cursor-pointer hover:bg-white hover:text-black hover:border hover:border-bgTheme hover:border-1 transition-all mb-2' onClick={()=>navigate("/store")}>
                    <p>View All Products</p>
                </div>
                <hr className='border border-blue-800 h-2 bg-theme' />
                <br /><br />
            </div>
            <div className='hmCon3'>
                <div className="columns-lg mx-auto lg:h-700 container h-600">
                    <div className="flex flex-col justify-center items-center lg:pt-48">
                        <div className='text-white'>
                            <h1 className="lg:text-5xl font-bold text-2xl pt-5 greetText text-center">WE OFFER YOU THE BEST <br />QUALITY SERVICES</h1>
                            <p className='mt-2 text-sm  p-4'>With just a little bit over 24 years of a history as a practice and decades of combined experience our staff members do have, we are the ultimate! Thankfully the number of our visitors is so big, that we were always able to adjust our pricing.</p>
                            <a href="/contact">
                                <div className='p-4 border-2 border-white mt-4 text-white w-32  cursor-pointer hover:bg-white hover:text-black hover:border hover:border-bgTheme hover:border-1 transition-all mx-auto'>
                                    <p>Read More</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="flex items-center justify-center lg:pt-20 pt-10">
                        <img src={HMPER2} alt="hmper" className='w-80 lg:w-auto' />
                    </div>
                </div>
            </div>
            <div>
                <h1 className='text-center text-3xl mt-4'>About us</h1>
                <hr className='w-60 mx-auto mt-2 border-none h-1 bg-bgTheme' />
                <h1 className='text-center mt-2 p-3'>Some of the major factors that have made <span className='font-bold'>Sree Medi Systems</span>. the primal choice of clients are:</h1>
                <div className="container mx-auto columns-lg">
                    <div>
                        <img src={HM3} alt="im" className='' />
                    </div>
                    <div className='flex flex-col gap-4 lg:p-20 p-10'>
                        <div className='flex flex-row gap-3'>
                            <div>
                                <img src={Copy} alt="copy" className='w-16' />
                            </div>
                            <div>
                                <h1 className='font-bold font-signika'>OVER 24 YEARS EXPERIENCE</h1>
                                <p>We combine quality workmanship, superior knowledge and low prices to provide you with service unmatched by our competitors.</p>
                            </div>
                        </div>
                        <div className='flex flex-row gap-3'>
                            <div>
                                <img src={Walker} alt="copy" className='w-16' />
                            </div>
                            <div>
                                <h1 className='font-bold font-signika'>BEST MATERIALS</h1>
                                <p>We have the experience, personel and resources to make the project run smoothly. We can ensure a job is done on time.</p>
                            </div>
                        </div> <div className='flex flex-row gap-3'>
                            <div>
                                <img src={Cup} alt="copy" className='w-12' />
                            </div>
                            <div>
                                <h1 className='font-bold font-signika'>PROFESSIONAL STANDARDS</h1>
                                <p>Work with us involve a carefully planned <br />series of steps, centered around a schedule we stick to and daily communication.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-blue-800 text-white pb-8'>
                <h1 className='text-center text-3xl mt-6 pt-2 uppercase'>Why to Choose us</h1>
                <hr className='w-60 mx-auto mt-2 border-none h-1 bg-bgTheme' />
                <div className="container mx-auto">
                    <p className='p-4'>
                        Sree Medi Systems., are a customer-centric organization, and focused on delivering innovative medical life care products and medical equipment to our clients. An SSI Unit & SME – CRISIL Verified company, we have been a trusted Manufacturer, Service Provider, Wholesaler/Distributor, Supplier and Trader of aforesaid products since 2000.
                    </p>
                    <div className='flex flex-row flex-wrap justify-evenly gap-4'>
                        <div className='flex flex-col items-center'>
                            <img src={Tsk} alt="tsk" className='w-14' />
                            <h1 className='font-bold text-xl'>Our Goals</h1>
                            <ul className='list-disc'>
                                <li>To offer quality products</li>
                                <li>To be innovative</li>
                                <li>To be Customer Oriented</li>
                                <li>To be Competitive</li>
                            </ul>
                        </div>
                        <div className='flex flex-col items-center'>
                            <img src={Note} alt="note" className='w-14' />
                            <h1 className='font-bold text-xl'>Our Mission</h1>
                            <p>To provide world class products,<br /> which are of superior <br />quality and available at affordable prices</p>
                        </div>
                        <div className='flex flex-col items-center'>
                            <img src={Cup} alt="cup" className='w-14' />
                            <h1 className='font-bold text-xl'>Our Vision</h1>
                            <ul className='list-disc'>
                                <li>A vision, that you are in the hand’s of <br /> professional people</li>
                                <li> A vision to be given with specific goals</li>
                                <li>A vision that ”team is strength and <br /> strength is success”</li>
                                <li>A vision of leadership in respective field</li>
                            </ul>
                        </div>
                        <div className='flex flex-col items-center'>
                            <img src={Quality} alt="quality" className='w-14' />
                            <h1 className='font-bold text-xl'>Our Values</h1>
                            <ul className='list-disc'>
                                <li>Honesty</li>
                                <li>Discipline</li>
                                <li>Teamwork</li>
                                <li>On Time Delivery</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </React.Fragment>
    );
};

export default Homepage;