import React, { useState } from "react";
import ContactTab from "../../components/Home/ContactTab";
import Header from "../../components/Home/Header";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../components/Home/Sidebar";
import axios from "axios";
import BASE from "../../api/apis";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Register = () => {
  const navigate = useNavigate();
  const [data,setData] = useState({
    FirstName:"",
    LastName:"",
    Email:"",
    MobileNumber:"",
    Address:{
        Country:"india",
        State:"",
        Landmark:"",
        City:"",
        Pincode:"",
        hno:"",
        street:""
    },
    Password:"",
    ConfirmPassword:""
  });

  const ChangeHandler = e =>{
    setData({...data,[e.target.name]:e.target.value});
  }
  const ChangeHandler2 = e =>{
    setData({...data,Address:{...data.Address,[e.target.name]:e.target.value}});
  }

  const SubmitHandler = e =>{
    e.preventDefault();
    if(data.Password === data.ConfirmPassword){
        if(data.Password.length > 8){
            axios.post(`${BASE}/customer/signup`,data).then(resp=>{
                if(resp.data.success){
                    alert("Registration success")
                    navigate("/login");
                }else{
                    toast(resp.data.message,{type:"error"});
                }
            }).catch(err=>{
                console.log(err);
            })
        }else{
            toast("Password must be greater than 8 digits");
        }
    }else{
        toast("Password and confirmpassword must be equal")
    }
  }

  return (
    <div>
      <ToastContainer/>
      <ContactTab />
      <Sidebar/>
      <Header />
      <div className="p-4">
        <form action="" className="flex flex-col gap-2 items-center" onSubmit={SubmitHandler}>
          <h1 className="text-2xl">Register</h1>
          <input
            type="text"
            placeholder="Enter FirstName"
            className="w-72 border p-2 outline-theme"
            name="FirstName"
            onChange={ChangeHandler}
            required
          />
          <input
            type="text"
            placeholder="Enter LastName"
            className="w-72 border p-2 outline-theme"
            name="LastName"
            onChange={ChangeHandler}
            required
          />
          <input
            type="email"
            placeholder="Enter Email"
            className="w-72 border p-2 outline-theme"
            name="Email"
            onChange={ChangeHandler}
            required
          />
          <input
            type="password"
            placeholder="Enter Password"
            className="w-72 border p-2 outline-theme"
            name="Password"
            onChange={ChangeHandler}
            required
          />
          <input
            type="password"
            placeholder="Confirm Password"
            className="w-72 border p-2 outline-theme"
            name="ConfirmPassword"
            onChange={ChangeHandler}
            required
          />
          <input
            type="number"
            placeholder="Enter MobileNumber"
            className="w-72 border p-2 outline-theme"
            name="MobileNumber"
            onChange={ChangeHandler}
            required
          />
          <div className="flex flex-col">
            <label htmlFor="cntry" className="text-xs">
              Select Country
            </label>
            <select
              name="Country"
              id="cntry"
              className="w-72 border p-2 outline-theme"
              required
              onChange={ChangeHandler2}
            >
              <option value="india">India</option>
            </select>
          </div>
          <div className="flex flex-col">
            <label htmlFor="state" className="text-xs text-gray-700">
              Select State
            </label>
            <select
              name="State"
              id="state"
              className="w-72 border p-2 outline-theme"
              required
              onChange={ChangeHandler2}
            >
              <option value="Andhra Pradesh">Andhra Pradesh</option>
              <option value="Arunachal Pradesh">Arunachal Pradesh</option>
              <option value="Assam">Assam</option>
              <option value="Bihar">Bihar</option>
              <option value="Chhattisgarh">Chhattisgarh</option>
              <option value="Goa">Goa</option>
              <option value="Gujarat">Gujarat</option>
              <option value="Haryana">Haryana</option>
              <option value="Himachal Pradesh">Himachal Pradesh</option>
              <option value="Jharkhand">Jharkhand</option>
              <option value="Karnataka">Karnataka</option>
              <option value="Kerala">Kerala</option>
              <option value="Madhya Pradesh">Madhya Pradesh</option>
              <option value="Maharashtra">Maharashtra</option>
              <option value="Manipur">Manipur</option>
              <option value="Meghalaya">Meghalaya</option>
              <option value="Mizoram">Mizoram</option>
              <option value="Nagaland">Nagaland</option>
              <option value="Odisha">Odisha</option>
              <option value="Punjab">Punjab</option>
              <option value="Rajasthan">Rajasthan</option>
              <option value="Sikkim">Sikkim</option>
              <option value="Tamil Nadu">Tamil Nadu</option>
              <option value="Telangana">Telangana</option>
              <option value="Tripura">Tripura</option>
              <option value="Uttar Pradesh">Uttar Pradesh</option>
              <option value="Uttarakhand">Uttarakhand</option>
              <option value="West Bengal">West Bengal</option>
              <option value="Andaman and Nicobar Islands">
                Andaman and Nicobar Islands
              </option>
              <option value="Chandigarh">Chandigarh</option>
              <option value="Dadra and Nagar Haveli and Daman and Diu">
                Dadra and Nagar Haveli and Daman and Diu
              </option>
              <option value="Lakshadweep">Lakshadweep</option>
              <option value="Delhi">Delhi</option>
              <option value="Puducherry">Puducherry</option>
            </select>
          </div>
          <input
            type="text"
            placeholder="Enter City"
            className="w-72 border p-2 outline-theme"
            name="City"
            required
            onChange={ChangeHandler2}
          />
          <textarea name="hno" id="" cols="10" rows="3" placeholder="Hno / Flatno / Doorno" className="w-72 border p-2 outline-theme" onChange={ChangeHandler2}></textarea>
          <input
            type="text"
            placeholder="Enter Street"
            className="w-72 border p-2 outline-theme"
            name="street"
            required
            onChange={ChangeHandler2}/>
           <input
            type="number"
            placeholder="Enter Pincode"
            className="w-72 border p-2 outline-theme"
            name="Pincode"
            required
            onChange={ChangeHandler2}
          />
          <input
            type="submit"
            value="Register"
            className="p-2 bg-theme text-white hover:bg-blue-400 rounded-md cursor-pointer w-24"
          />
          <p>
            Have an account ?{" "}
            <span
              className="text-blue-900 cursor-pointer"
              onClick={() => navigate("/login")}
            >
              Login
            </span>
          </p>
        </form>
      </div>
    </div>
  );
};

export default Register;
