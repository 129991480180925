import React from 'react';
import { useNavigate } from 'react-router-dom';


const Sidebar = () =>{

    const navigate = useNavigate();

    return(
        <div id="sidebar" className='h-full'>
            <div className='flex flex-row justify-end'>
                <span className='material-symbols-outlined p-2' onClick={()=>{
                    document.getElementById("sidebar").style.marginLeft = "-100%";
                }}>close</span>
            </div>
                <h1 className='text-center text-xl text-gray-600'>SREE MEDI SYSTEMS</h1>
                <p className='text-xs text-center p-2'>Medical Equipment Sales & Services</p>
            <div>
                <div className='flex flex-row p-2 gap-4' onClick={()=>navigate("/")}>
                    <span class="material-symbols-outlined text-blue-900">home</span>
                    <h1>Home</h1>
                </div>
                <div className='flex flex-row p-2 gap-4' onClick={()=>navigate("/services/login")}>
                    <span class="material-symbols-outlined text-blue-900">medical_services</span>
                    <h1>Services</h1>
                </div>
                <div className='flex flex-row p-2 gap-4' onClick={()=>navigate("/store")}>
                    <span class="material-symbols-outlined text-blue-900">store</span>
                    <h1>Store</h1>
                </div>
                <div className='flex flex-row p-2 gap-4' onClick={()=>navigate("/mycard")}>
                    <span class="material-symbols-outlined text-blue-900">contacts</span>
                    <h1>Contact</h1>
                </div>
                <div className='flex flex-row p-2 gap-4' onClick={()=>navigate("/login")}>
                    <span class="material-symbols-outlined text-blue-900">passkey</span>
                    <h1>Login</h1>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;