import axios from "axios";
import React, { useEffect, useState } from "react";
import BASE from "../../api/apis";
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  LinearProgress,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const FetchOrders = () => {
    setLoading(true);
    axios
      .get(`${BASE}/orders/`)
      .then((resp) => {
        setLoading(false);
        setOrders(resp.data.orders);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    FetchOrders();
  }, []);

  return (
    <div>
      <h1 className="text-center mt-2">Orders</h1>
      <TableContainer>
        {loading && <LinearProgress/>}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <p className="text-center">Order id</p>
              </TableCell>
              <TableCell>
                <p className="text-center">Name</p>
              </TableCell>
              <TableCell>
                <p className="text-center">Email</p>
              </TableCell>
              <TableCell>
                <p className="text-center">Mobile Number</p>
              </TableCell>
              <TableCell>
                <p className="text-center">State</p>
              </TableCell>
              <TableCell>
                <p className="text-center">Amount</p>
              </TableCell>
              <TableCell>
                <p className="text-center">Actions</p>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
                orders && orders.map(ord=>{
                    if(ord.success){
                        return(
                            <TableRow>
                                <TableCell><p className="text-center">{ord._id}</p></TableCell>
                                <TableCell><p className="text-center">{ord.Name}</p></TableCell>
                                <TableCell><p className="text-center">{ord.Email}</p></TableCell>
                                <TableCell><p className="text-center">{ord.MobileNumber}</p></TableCell>
                                <TableCell>{ord.success ? <p className="text-center text-green-800 bg-green-200">{ord.state}</p> : <p className="text-center text-red-800 bg-red-200">{ord.state}</p>}</TableCell>
                                <TableCell><p className="text-center">{ord.totalAmount}</p></TableCell>
                                <TableCell>
                                    <Box>
                                        <button className="p-2 text-white bg-theme hover:bg-blue-600" onClick={()=>{
                                          navigate(`/admin/home/orders/vieworder/${ord._id}`);
                                        }}>View</button>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        );
                    }
                })
            }
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Orders;
