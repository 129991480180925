import { Button, ThemeProvider, createTheme, CircularProgress } from '@mui/material';
import Logo from '../../assets/VisitingCard/logo.jpg';
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Actions } from '../../redux/store';
import BASE from '../../api/apis';

const Login = () =>{

    const theme = createTheme({
        palette:{
            primary:{
                main:'#00329B'
            }
        }
    });

    const [data,setData] = useState({
        Email:"",
        Password:""
    });

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading,setLoading] = useState(false);

    const ChangeHandler = e =>{
        setData({...data,[e.target.name]:e.target.value});
    };

    const SubmitHandler = (e) =>{
        e.preventDefault();
        setLoading(true);
        axios.post(`${BASE}/user/login`,{...data},{
            withCredentials:true
        }).then(resp=>{
            setLoading(false);
            sessionStorage.setItem("SMS_TK",resp.data.token);
            if(resp.data.success){
                if(resp.data.userType === "ENGINEER"){
                    navigate("/engineer")
                }else if(resp.data.userType === "ORDERCONTROLLER"){
                    dispatch(Actions.setControllerLogin(true));
                    navigate("/controller/vieworders");
                }else if(resp.data.userType === "SERVICECONTROLLER"){
                    dispatch(Actions.setServiceControllerLogin(true));
                    navigate("/controller/viewservices");
                }else{
                    dispatch(Actions.setUserLogin(true));
                    navigate("/services");
                }
            }else{
                alert(resp.data.message)
            }
        })
    };

    return(
        <div>
            <ThemeProvider theme={theme}>
                <div className='p-2 bg-theme flex flex-row gap-2 items-center'>
                    <img src={Logo} alt="logo" width="50px" height="50px" className='rounded-full'/>
                    <h1 className='text-white'>Sree Medi Systems</h1>
                </div>
                <form onSubmit={SubmitHandler}>
                    <div className="w-60 mx-auto mt-1 h-screen flex flex-col items-center gap-2">
                        <h1 className='text-center mt-3 font-bold rounded'>Login</h1>
                        <input type="email" placeholder='Enter Email' className='w-full p-1' value={data.Email} name="Email" onChange={ChangeHandler}/>
                        <input type="password" placeholder='Enter Password' className='w-full p-1' value={data.Password} name="Password" onChange={ChangeHandler} autoComplete='false'/>
                        {!loading ? <Button variant='contained' className='w-full' type='submit'>Login</Button> : <CircularProgress className="mx-auto" size={20}/>}
                    </div>
                </form>
            </ThemeProvider>
        </div>
    );
};

export default Login;
