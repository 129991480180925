import React from "react";
import { useNavigate } from "react-router-dom";
import { v4 } from "uuid";

const Product = (props) => {

  const navigate = useNavigate();

  return (
    <div className="border w-44" onClick={()=>navigate(`/product/${props.data._id}`)}>
      <img src={props.data.img} alt="product" width="100%" className="h-40" />
      <h1 className="p-1 text-xs">
        <span className="text-blue-800 font-bold">Name :</span> {props.data.Name}
      </h1>
      <h1 className="text-xs p-1">
        <span className="text-blue-800 font-bold">Make : </span>
        {props.data.Make}
      </h1>
      <h1 className="text-xs p-1">
        <span className="text-blue-800 font-bold">Price : </span>
        {props.data.Price}
      </h1>
      <h1 className="text-xs p-1">
        <span className="text-blue-800 font-bold">Model : </span>
        {props.data.Model}
      </h1>
      <h1 className="text-xs p-1">Features : </h1>
      <ul>
        {props.data.Features.map((ft) => {
          return (
            <div className="flex flex-row" key={v4()}>
              <span className="material-symbols-outlined text-blue-900">
                arrow_right
              </span>
              <h1 className="text-xs">{ft}</h1>
            </div>
          );
        })}
      </ul>
    </div>
  );
};

export default Product;
