import React, { useEffect, useState } from "react";
import Logo from "../../assets/VisitingCard/logo.jpg";
import axios from "axios";
import BASE from "../../api/apis";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { CircularProgress } from "@mui/material";


const Checkout = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [customer, setCustomer] = useState();
  const [products, setProducts] = useState([]);
  let [total, setTotal] = useState(0);
  
    const [data, setData] = useState({
      Email: "",
      Name: "",
      Company: "",
      MobileNumber: "",
      AlternateMobile: "",
      Address:{},
      OrderItems: [],
      totalAmount: 0,
      ShippingCharges: "",
      DeliveryDate: "",
      OrderDate: "",
      userID:"",
      orderID:""
    });

  const FetchProduct = () => {
    const products = JSON.parse(sessionStorage.getItem("buy"));
    setProducts(products);
  };

  useEffect(() => {
    FetchProduct();
  },[]);

  const changeHandler = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const ChangeHandler2 = e =>{
    setData({...data,Address:{...data.Address,[e.target.name]:e.target.value}})
  }

  const SubmitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    data.userID = customer._id;
    const dta = {...data};
    products.map(itm=>{
      const index = dta.OrderItems.findIndex(obj=>obj===itm._id);
      if(index === -1){
        dta.OrderItems.push({id:itm._id,Quantity:itm.Quantity});
      }
    })
    dta.totalAmount = total;
    axios.post(`${BASE}/orders/`,dta).then(resp=>{
      if(resp.data.success){
        axios
          .post(`${BASE}/payment/pay`, { ...data, TotalAmount: total, orderID:resp.data.orderID })
          .then((resp) => {
            window.location.href = resp.data;
          })
          .catch((err) => {
            console.log(err);
          });
      }else{
        setLoading(false);
        toast(resp.data.message);
      }
    }).catch(err=>{
      setLoading(false);
      console.log(err);
    })
  };
  const DecodeUserData = () => {
    const customer = JSON.parse(localStorage.getItem("SMS_USER_TK"));
    if (customer) {
      const dta = jwtDecode(customer);
      setCustomer(dta.customer);
      setData({...data,Email:dta.customer.Email,Name:dta.customer.FirstName,Address:dta.customer.Address,MobileNumber:dta.customer.MobileNumber,Pincode:dta.customer.Address.Pincode});
    } else {
      navigate("/login");
    }
  };

  useEffect(() => {
    DecodeUserData();
  }, []);

  return (
    <div>
      <ToastContainer/>
      <div className="p-2 bg-theme flex flex-row gap-2 items-center">
        <img
          src={Logo}
          alt="logo"
          width="50px"
          height="50px"
          className="rounded-full"
        />
        <h1 className="text-white">Sree Medi Systems</h1>
      </div>
      <div className="flex md:flex-row flex-col-reverse">
        <div>
          <h1 className="p-3 text-2xl">Contact</h1>
          <form
            action=""
            className="p-3 flex flex-col gap-2"
          >
            <input
              type="email"
              name="Email"
              id=""
              placeholder="Enter Email"
              className="w-full md:w-70p border p-2 outline-theme"
              onChange={changeHandler}
              required
              value={data.Email}
            />
            <h1 className="p-2 text-xs text-gray-700">
              We Will Reach you through the email that you have provided
            </h1>
            <h1 className="p-3 text-2xl">Delivery</h1>
            <div>
              <label htmlFor="sel" className="text-xs text-gray-700 p-3">
                Country
              </label>
              <select
                name="Country"
                id="sel"
                className="w-70p border p-2 outline-theme"
                onChange={ChangeHandler2}
              >
                <option value="india">india</option>
              </select>
              <div className="flex flex-row gap-2 mt-2">
                <input
                  type="text"
                  name="Name"
                  id=""
                  className="w-70p border p-2 outline-theme"
                  placeholder="Name"
                  onChange={changeHandler}
                  value={data.Name}
                />
              </div>
              <input
                type="text"
                placeholder="Company (Optional)"
                className="w-full md:w-70p border p-2 outline-theme mt-2"
                name="Company"
                onChange={changeHandler}
              />
              <input
                type="number"
                placeholder="Enter Mobile Number"
                className="w-full md:w-70p border p-2 outline-theme mt-2"
                name="MobileNumber"
                onChange={changeHandler}
                value={data.MobileNumber}
              />
              <textarea
                name="hno"
                id=""
                cols="30"
                rows="3"
                placeholder="Enter Hno / Door / Flat no"
                className="w-full md:w-70p border p-2 outline-theme mt-2"
                onChange={ChangeHandler2}
                value={data.Address.hno}
              ></textarea>
              <input
                type="text"
                placeholder="Enter City"
                className="w-full md:w-70p border p-2 outline-theme mt-2"
                name="City"
                onChange={ChangeHandler2}
                value={data.Address.City}
              />
              <br />
              <label htmlFor="state" className="text-xs text-gray-700 p-3">
                Select State
              </label>
              <select
                name="State"
                id="state"
                className="w-full md:w-70p border p-2 outline-theme mt-2"
                onChange={ChangeHandler2}
                value={data.Address.State}
              >
                <option value="Andhra Pradesh">Andhra Pradesh</option>
                <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                <option value="Assam">Assam</option>
                <option value="Bihar">Bihar</option>
                <option value="Chhattisgarh">Chhattisgarh</option>
                <option value="Goa">Goa</option>
                <option value="Gujarat">Gujarat</option>
                <option value="Haryana">Haryana</option>
                <option value="Himachal Pradesh">Himachal Pradesh</option>
                <option value="Jharkhand">Jharkhand</option>
                <option value="Karnataka">Karnataka</option>
                <option value="Kerala">Kerala</option>
                <option value="Madhya Pradesh">Madhya Pradesh</option>
                <option value="Maharashtra">Maharashtra</option>
                <option value="Manipur">Manipur</option>
                <option value="Meghalaya">Meghalaya</option>
                <option value="Mizoram">Mizoram</option>
                <option value="Nagaland">Nagaland</option>
                <option value="Odisha">Odisha</option>
                <option value="Punjab">Punjab</option>
                <option value="Rajasthan">Rajasthan</option>
                <option value="Sikkim">Sikkim</option>
                <option value="Tamil Nadu">Tamil Nadu</option>
                <option value="Telangana">Telangana</option>
                <option value="Tripura">Tripura</option>
                <option value="Uttar Pradesh">Uttar Pradesh</option>
                <option value="Uttarakhand">Uttarakhand</option>
                <option value="West Bengal">West Bengal</option>
                <option value="Andaman and Nicobar Islands">
                  Andaman and Nicobar Islands
                </option>
                <option value="Chandigarh">Chandigarh</option>
                <option value="Dadra and Nagar Haveli and Daman and Diu">
                  Dadra and Nagar Haveli and Daman and Diu
                </option>
                <option value="Lakshadweep">Lakshadweep</option>
                <option value="Delhi">Delhi</option>
                <option value="Puducherry">Puducherry</option>
              </select>
              <input
                type="text"
                placeholder="Enter Landmark"
                className="w-full md:w-70p border p-2 outline-theme mt-2"
                name="Landmark"
                onChange={ChangeHandler2}
              />
              <input
                type="number"
                placeholder="Enter Pincode"
                className="w-70p border p-2 outline-theme mt-2"
                name="Pincode"
                required
                onChange={ChangeHandler2}
                value={data.Address.Pincode}
              />
              <input
                type="number"
                placeholder="Enter Alternate Phoneno"
                className="w-full md:w-70p border p-2 outline-theme mt-2"
                name="AlternateMobile"
                onChange={changeHandler}
              />
            </div>
          </form>
        </div>
        <div className="p-5">
          {products.map((prdct) => {
            if (prdct.Quantity > 1) {
              total += prdct.Price * prdct.Quantity;
            } else {
              total = total + prdct.Price;
            }
            return (
              <div className="flex flex-row items-center gap-7 mt-3">
                <img src={prdct.img} alt="" className="w-24" />
                <div className="flex flex-col gap-4">
                  <h1 className="text-xs">{prdct.Name}</h1>
                  <h1 className="text-xs">
                    Quantity :{" "}
                    <span
                      className="material-symbols-outlined text-xs p-1 bg-theme text-white rounded-full cursor-pointer"
                      onClick={() => {
                        const index = products.indexOf(prdct);
                        if (products[index].Quantity >= 1) {
                          const prdcts = [...products];
                          prdcts[index].Quantity += 1;
                          total = total + prdct.Price;
                          setProducts(prdcts);
                        }
                      }}
                    >
                      add
                    </span>{" "}
                    {prdct.Quantity}{" "}
                    <span
                      className="material-symbols-outlined text-xs p-1 bg-theme text-white rounded-full cursor-pointer"
                      onClick={() => {
                        const index = products.indexOf(prdct);
                        if (
                          products[index].Quantity >= 1 &&
                          products[index].Quantity != 1
                        ) {
                          const prdcts = [...products];
                          prdcts[index].Quantity -= 1;
                          total = total - prdct.Price;
                          setProducts(prdcts);
                        }
                      }}
                    >
                      remove
                    </span>
                  </h1>
                </div>
                <h1 className="text-xs">₹ {prdct.Price}/-</h1>
              </div>
            );
          })}
          <div>
            <h1 className="text-sm mt-2 text-gray-800 font-bold">
              Shipping :
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <span className="font-normal">
                Shipping at actuals and depends on Quantity.
              </span>
            </h1>
            <h1 className="text-sm mt-2 text-gray-800 font-bold">
              Total :
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <span className="font-normal">₹{total}/-</span>
            </h1>
            <h1 className="text-xs mt-2 text-gray-800">
              Including ₹329.86 in taxes
            </h1>
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-center mb-4">
        {!loading ? <input type="submit" className="p-2 bg-theme text-white cursor-pointer" value="Proceed to pay" onClick={SubmitHandler}/> : <CircularProgress size={24}/>}
      </div>
    </div>
  );
};

export default Checkout;
