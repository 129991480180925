import React, { useEffect, useState } from "react";
import { Badge } from "@mui/material";
import Logo from "../../assets/VisitingCard/logo.jpg";
import { useNavigate, useParams } from "react-router-dom";
import BASE from "../../api/apis";
import axios from "axios";
import { v4 } from "uuid";
import Footer from "../../components/Home/Footer";

const Search = () => {
  const { keyword } = useParams();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cartItems , setCartItems] = useState(JSON.parse(localStorage.getItem("cartitems"))?.length);

  const navigate = useNavigate();

  const FetchData = async () => {
    setLoading(true);
    await axios
      .get(`${BASE}/product/search/${keyword}`)
      .then((resp) => {
        setLoading(false);
        setProducts(resp.data.products);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    FetchData();
  }, []);

  return (
    <div>
      <div className="p-2 flex flex-row justify-between items-center">
        <div className="flex flex-row gap-3 items-center">
          <img
            src={Logo}
            alt="logo"
            width="50px"
            height="50px"
            className="rounded-full"
          />
          <h1 className="uppercase">Sree Medi Systems</h1>
        </div>
        <div className="pr-3 flex flex-row gap-2">
          <Badge badgeContent={cartItems} color="primary">
            <span className="material-symbols-outlined text-blue-900">
              shopping_bag
            </span>
          </Badge>
        </div>
      </div>
      {!loading && products.length > 0 ? (
        <div>
          <h1 className="text-center p-2">Showing Results of "{keyword}"</h1>
          <div className="flex flex-row mt-2 overflow-scroll md:justify-center">
            <div className="flex flex-row flex-wrap justify-center gap-2">
              {products.map((prdct) => {
                return (
                  <div
                    className="w-44 border hover:border-gray-400 hover:shadow-2xl p-3 "
                    key={v4()}
                    onClick={() => navigate(`/product/${prdct._id}`)}
                  >
                    <img src={prdct.img} width="100%" className="h-40" />
                    <h1 className="p-1 text-xs">
                      <span className="text-blue-800 font-bold">Name :</span>{" "}
                      {prdct.Name}
                    </h1>
                    <h1 className="text-xs p-1">
                      <span className="text-blue-800 font-bold">Make : </span>
                      {prdct.Make}
                    </h1>
                    {prdct.Price && (
                      <h1 className="text-xs p-1">
                        <span className="text-blue-800 font-bold">
                          Price :{" "}
                        </span>
                        ₹ {prdct.Price} /-
                      </h1>
                    )}
                    <h1 className="text-xs p-1">
                      <span className="text-blue-800 font-bold">Stock : </span>
                      {prdct.Stock}
                    </h1>
                    <h1 className="text-xs p-1">
                      <span className="text-blue-800 font-bold">Model : </span>
                      {prdct.Model}
                    </h1>
                    <div className="">
                      <button className="bg-blue-800 w-full p-2 text-white">
                        Buy
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        <p className="text-center p-2">
          No Results Found of search keyword " {keyword} "
        </p>
      )}
      <Footer />
    </div>
  );
};

export default Search;
