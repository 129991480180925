import React, { useEffect, useState } from 'react';
import { Avatar, CircularProgress, LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Logo from "../../assets/VisitingCard/logo.jpg"
import axios from 'axios';
import BASE from '../../api/apis';
import { jwtDecode }from 'jwt-decode';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { storage } from '../../firebase/firebase';
import { v4 } from 'uuid';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

const PostService = () => {

    const [imgbaseArr,setimgBaseArr] = useState([]);
    const [User,setUser] = useState({});
    const [loading,setLoading] = useState(false);
    const navigate = useNavigate();
    const [userServices,setUserServices] = useState([]);
    const [servicesLoading,setservicesLoading] = useState(false);

    const FetchUserData = (id) =>{
        setservicesLoading(true);
        axios.get(`${BASE}/user/${id}`).then(resp=>{
            if(resp.data.succes){
                setUserServices(resp.data.user.RaisedComplaints);
                setservicesLoading(false);
            }else{
                alert(resp.data.message)
                setservicesLoading(false);
            }
        }).catch(err=>{
            console.log(err);
        })
    }

    const GetUserData = ()=>{
        const token = sessionStorage.getItem("SMS_TK");
        if(token){
            const user = jwtDecode(token);   
            if(user._doc){
                setUser({...user._doc,id:user._doc._id,serviceType:user._doc.userType});
            }
            if(user.serviceType){
                setUser(user);
            }
            FetchUserData(user.id);
        }else{
            navigate("/services/login");
        }
    }

    const [data,setData] = useState({
        img:"",
        Description:"",
        user:"",    
        serviceType:"",
        status:false
    });

    const [imgs,setImgs] = useState([]);
    const [urls,setUrls] = React.useState([]);

    const ConvertBase64 = files =>{

        for(let i=0;i<files.length;i++){
            setImgs(prev=>[...prev,files[i]]);
            const reader = new FileReader();
            reader.readAsDataURL(files[i]);
            reader.onload = () =>{
                setimgBaseArr(prev=>[...prev,reader.result]);
            }
        }
        
    };

    const SubmitHandler = async (e) =>{
        e.preventDefault();
        if(imgs.length>0){
            setLoading(true);
            for(var i=0;i<imgs.length;i++){
                const refer = ref(storage,`/services/${imgs[i].name+v4()}`);
                await uploadBytes(refer,imgs[i]).then(async resp=>{
                    await getDownloadURL(resp.ref).then(url=>{
                        urls.push(url);
                    })
                })
            }
            if(urls.length > 0){
                axios.post(`${BASE}/service/${User.id}`,{...data,img:urls,user:User.id,serviceType:User.serviceType},{
                    withCredentials:true,
                }).then(dta=>{
                    setLoading(false);
                    if(dta.data.success){
                        toast("Complaint Raised ",{
                            type:"success"
                        })
                        setTimeout(()=>{
                            window.location.reload();
                        },2000);
                    }else{
                        toast(dta.data.message,{
                            type:"error"
                        })
                    }
                })
            }
        }else{
            alert("please upload image ! ")
        }
    }

    const ChangeHandler = e =>{
        setData({...data,[e.target.name]:e.target.value});
    }

    useEffect(()=>{
        GetUserData();
    },[])

    return (
        <div>
            <ToastContainer/>
            <div className='p-2 bg-theme flex flex-row gap-2 items-center'>
                <img src={Logo} alt="logo" width="50px" height="50px" className='rounded-full' />
                <h1 className='text-white'>Sree Medi Systems</h1>
            </div>
            <h1 className='text-center font-bold mt-2'>Complaint</h1>
            <form className='flex flex-col p-3 gap-3 items-center' onSubmit={SubmitHandler}>
                {
                    imgbaseArr.length > 0 &&<div className='flex flex-row gap-2 flex-wrap'>
                        {
                            imgbaseArr.map(img=>{
                                return(
                                    <Avatar variant="square" sx={{width:"200px",height:"200px"}} className="mx-auto mt-1" src={img}/>
                                );
                            })
                        }
                    </div>
                }     
                <label htmlFor="file" className="p-2 bg-theme text-white cursor-pointer">Choose images</label>
                <input type="file" name="" id="file" className="mt-2" hidden onChange={(e)=>{ConvertBase64(e.target.files);}} multiple accept='image/*'/>
                <textarea placeholder="Write a Description" className="w-70p border border-gray-300 p-2 outline-theme" name="Description" onChange={ChangeHandler}/>
                {!loading ? <input type="submit" className="p-2 bg-theme text-white hover:bg-blue-400 rounded-md cursor-pointer w-52"/> : <CircularProgress size={25}/>}
            </form>
            <div>
                <h1 className='text-center mt-2 text-xs'>Raised Complaints & Status </h1>
                <div>
                    {
                        servicesLoading ? <LinearProgress/> : <div>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Description</TableCell>
                                            <TableCell>Image</TableCell>
                                            <TableCell>Status</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            userServices.map(srv=>{
                                                return(
                                                    <TableRow>
                                                        <TableCell>{srv.Description}</TableCell>
                                                        <TableCell><Avatar src={srv.img}/></TableCell>
                                                        <TableCell>{srv.status === false ? <span className='text-red-800 font-bold text-center'>Pending</span> : <span className='text-green-800 font-bold text-center'>Serviced</span>}</TableCell>
                                                    </TableRow>
                                                );
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default PostService;