import React from "react";
import Logo from '../../assets/VisitingCard/logo.jpg'
import SideBar from "../../components/admin/SideBar";
import { Outlet } from "react-router-dom";

const AdminHome = () => {
  return (
    <div>
      <div className="p-2 bg-theme flex flex-row gap-2 items-center">
        <span className="material-symbols-outlined text-white cursor-pointer" onClick={()=>{
          document.getElementById("adminsidebar").style.marginLeft = 0;
        }}>menu</span>
        <img
          src={Logo}
          alt="logo"
          width="50px"
          height="50px"
          className="rounded-full"
        />
        <h1 className="text-white">Sree Medi Systems</h1>
      </div>
      <div className="flex flex-row">
        <div>
            <SideBar/>
        </div>
        <div className="adminRight">
            <Outlet/>
        </div>
      </div>
    </div>
  );
};

export default AdminHome;
