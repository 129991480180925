import { Avatar, CircularProgress } from '@mui/material';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import React, { useState } from 'react';
import { storage } from '../../firebase/firebase';
import BASE from '../../api/apis';
import axios from 'axios';
import { v4 } from 'uuid';

const AddUser = () =>{

    const [data,setData] = useState({
        Name:"",
        Email:"",
        Password:"",
        ConfirmPassword:"",
        Mobile:"",
        userType:"AMC",
        Profile:""
    });

    const [img,setImg] = useState();
    const [base,setBase] = useState();
    const [loading,setLoading] = useState(false);

    const ConvertBase64 = file =>{
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () =>{
            setBase(reader.result);
        }
    }

    const changeHandler = e =>{
        setData({...data,[e.target.name]:e.target.value});
    }

    const SubmitHandler = async (e) =>{
        e.preventDefault();
        if(data.Password !== data.ConfirmPassword){
           alert("Passwords doesnot match"); 
        }else{
            if(img){
                setLoading(true);
                const refer = ref(storage,`/users/${img.name+v4()}`);
                await uploadBytes(refer,img).then(async resp=>{
                    await getDownloadURL(resp.ref).then(async url=>{
                        axios.post(`${BASE}/user/`,{...data,Profile:url},{
                            withCredentials:true
                        }).then(resp=>{
                            setLoading(false);
                            if(resp.data.success){
                                alert("User Saved !");
                            }else{
                                alert(resp.data.message);
                            }
                        })
                    })
                })
            }else{
                setLoading(true);
                axios.post(`${BASE}/user/`,data,{
                    withCredentials:true
                }).then(resp=>{
                    setLoading(false);
                    if(resp.data.success){
                        alert("User Saved !");
                    }else{
                        alert(resp.data.message);
                    }
                })
            }
        }
    }

    return(
        <div>
            <h1 className='text-center mt-2'>Add User</h1>
            <form className='flex flex-col p-3 gap-3 items-center' onSubmit={SubmitHandler}>
                <Avatar variant="square" sx={{width:"100px",height:"100px"}} className="mx-auto mt-1" src={base ? base : ""}/>
                <label htmlFor="file" className="p-2 bg-theme text-white cursor-pointer">Choose image</label>
                <input type="file" name="" id="file" className="mt-2" hidden onChange={(e)=>{ConvertBase64(e.target.files[0]);setImg(e.target.files[0]);}} accept='image/*'/>
                <input type="text" placeholder="Enter User Name" className="w-70p border p-2 outline-theme" name="Name" onChange={changeHandler}/>
                <input type="email" placeholder="Enter Email" className="w-70p border p-2 outline-theme" name="Email" onChange={changeHandler}/>
                <input type="password" placeholder="Enter Password" className="w-70p border p-2 outline-theme" name="Password" onChange={changeHandler}/>
                <input type="password" placeholder="Confirm Password" className="w-70p border p-2 outline-theme" name="ConfirmPassword" onChange={changeHandler}/>
                <input type="number" placeholder="Enter Mobile Number" className="w-70p border p-2 outline-theme" name="Mobile" onChange={changeHandler}/>
                <select className="w-70p border p-2 outline-theme" value={data.userType} onChange={changeHandler} name="userType">
                    <option value="AMC">AMC</option>
                    <option value="CMC">CMC</option>
                    <option value="ENGINEER">Engineer</option>
                    <option value="ORDERCONTROLLER">Order Controller</option>
                    <option value="SERVICECONTROLLER">Service Controller</option>
                </select>
                {!loading ? <input type="submit" className="w-auto border p-2 bg-theme text-white hover:bg-blue-700 cursor-pointer"/> : <CircularProgress size={25}/>}
            </form>
        </div>
    );
};

export default AddUser;