import React, { useEffect } from 'react';
import { NavLink } from "react-router-dom";
import { Avatar, IconButton } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import Logo from '../../assets/VisitingCard/logo.jpg';
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from '../../redux/store';
const Header = () =>{

    const userState = useSelector(state=>state.CustomerLogin);
    const dispatch = useDispatch();

    return(
        <div className="h-20 border flex flex-row items-center md:justify-evenly justify-start">
            <div className='flex flex-row gap-3'>
                <div className="visible md:hidden">
                    <IconButton onClick={()=>{
                        document.getElementById("sidebar").style.marginLeft = 0;
                    }}>
                        <MenuIcon/>
                    </IconButton>
                </div>
                <div className='flex flex-row gap-2'>
                    <Avatar src={Logo}/>
                    <h1 className="text-gray-600 font-signika uppercase text-2xl mt-1">Sree Medi Systems</h1>
                </div>
            </div>
            <div className="flex-row gap-3 hidden md:flex">
                <NavLink to="/" className="text-lg">Home</NavLink>
                <NavLink to="/services/login" className="text-lg">Services</NavLink>
                <NavLink to="/store" className="text-lg">Store</NavLink>
                <NavLink to="/mycard" className="text-lg">Contact</NavLink>
                {!userState ? <NavLink to="/login" className="text-lg">Login</NavLink> : <button className='text-lg' onClick={()=>{
                    localStorage.removeItem("SMS_USER_TK");
                    dispatch(Actions.setCustomerLogin(false));
                }}>Logout</button>}
                {userState && <NavLink to="/myorders" className="text-lg">My Orders</NavLink>}
            </div>
        </div>
    );
};
export default  Header;