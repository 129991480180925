import React, { useEffect } from 'react';
import Header from '../../components/Home/Header';
import ContactTab from '../../components/Home/ContactTab';
import { useNavigate, useParams } from 'react-router-dom';
import Order from '../../assets/order.png';
import failure from '../../assets/failure.png'
const Failure =  () =>{
    const navigate = useNavigate();

    const { id } = useParams();

    useEffect(()=>{
        if(!id){
            navigate("/");
        }
    },[]);
    return(
        <div>
            <ContactTab/>
            <Header/>
            <div className='flex flex-col justify-center items-center'>
                <img src={failure} alt="" className='w-32 mt-40'/>
                <h1 className='text-2xl text-gray-800'>Order Failed !</h1>
                <h1>order ID : {id}</h1>
                <h1>Payment Failed</h1>
                <h1 className='p-2 bg-theme text-white mt-4 cursor-pointer' onClick={()=>navigate("/")}> Go Home </h1>
            </div>
        </div>
    );
};

export default Failure;