import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import axios from "axios";
import BASE from "../../api/apis";
import { useNavigate } from "react-router-dom";

const ManageRental = () => {
  const [products, setProducts] = useState([]);
  useEffect(() => {
    axios
      .get(`${BASE}/product/rental`)
      .then((resp) => {
        setProducts(resp.data.products);
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  });
  const navigate = useNavigate();
  const DeleteProduct = (prdct) => {
    const index = products.indexOf(prdct);
    const pdc = [...products];
    pdc.splice(index,1);
    setProducts(pdc);
    axios
      .delete(`${BASE}/product/${prdct.id}`)
      .then((resp) => {
        alert(resp.data.message);
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };
  return (
    <div>
      <h1 className="text-center mt-3 uppercase font-bold">products</h1>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <h1>IMAGE</h1>
              </TableCell>
              <TableCell>
                <h1>ID</h1>
              </TableCell>
              <TableCell>
                <h1>NAME</h1>
              </TableCell>
              <TableCell>
                <h1>DESCRIPTION</h1>
              </TableCell>
              <TableCell>
                <h1>STOCK</h1>
              </TableCell>
              <TableCell>
                <h1>PRICE</h1>
              </TableCell>
              <TableCell>
                <h1>ACTION</h1>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products ? (
              <>
                {products.map((prdct) => {
                  return (
                    <TableRow>
                      <TableCell>
                        <img
                          src={prdct.img}
                          alt="prdct"
                          width="40px"
                          height="40px"
                        />
                      </TableCell>
                      <TableCell>{prdct._id}</TableCell>
                      <TableCell>{prdct.Name}</TableCell>
                      <TableCell>
                        <h1 className="text-center">
                          {prdct.Description ? prdct.Description : "-"}
                        </h1>
                      </TableCell>
                      <TableCell>
                        <h1 className="text-center">{prdct.Stock}</h1>
                      </TableCell>
                      <TableCell>
                        <h1 className="text-center">
                          {prdct.Price ? prdct.Price : "-"}
                        </h1>
                      </TableCell>
                      <TableCell>
                        <div className="flex flex-row gap-2">
                          <button className="p-2 bg-theme text-white hover:bg-blue-500" onClick={()=>{
                            navigate(`/admin/home/editproduct/${prdct._id}`);
                          }}>Edit</button>
                          <button
                            className="p-2 bg-red-500 text-white hover:bg-blue-500"
                            onClick={() => {
                              DeleteProduct(prdct);
                            }}
                          >
                            Delete
                          </button>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </>
            ) : (
              <h1 className="text-center">No products</h1>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ManageRental;
