import React, { useState } from 'react';
import Header from '../../components/Home/Header';
import ContactTab from '../../components/Home/ContactTab';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../components/Home/Sidebar';
import axios from 'axios';
import BASE from '../../api/apis';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { Actions } from '../../redux/store';

const Login = () =>{
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [data,setData] = useState({
        Email:"",
        Password:""
    });

    const ChangeHandler = e=>{
        setData({...data,[e.target.name]:e.target.value});
    }

    const SubmitHandler = e =>{
        e.preventDefault();
        axios.post(`${BASE}/customer/signin`,data).then(resp=>{
            if(resp.data.success){
                localStorage.setItem("SMS_USER_TK",JSON.stringify(resp.data.token));
                dispatch(Actions.setCustomerLogin(true));
                const item = sessionStorage.getItem("buy");
                if(item){
                    navigate("/checkout");
                }else{
                    navigate("/");
                }
            }else{
                toast(resp.data.message,{type:"error"});
            }
        }).catch(err=>{
            console.log(err);
        })
    }

    return(
        <div>
            <ToastContainer/>
            <ContactTab/>
            <Header/>
            <Sidebar/>
            <div className='p-4'>
                <form action="" className='flex flex-col justify-center gap-2 items-center' onSubmit={SubmitHandler}>
                    <h1 className='text-2xl'>Login</h1>
                    <input type="email" placeholder='Enter Email' className='w-72 border p-2 outline-theme' onChange={ChangeHandler} name="Email" required/>
                    <input type="password" placeholder='Enter Password' className='w-72 border p-2 outline-theme' onChange={ChangeHandler} name="Password" required/>
                    <input type="submit" value="Login" className='p-2 bg-theme text-white hover:bg-blue-400 rounded-md cursor-pointer w-24'/>
                    <p>Create an account ? <span className='text-blue-900 cursor-pointer' onClick={()=>navigate("/register")}>Register</span></p>
                </form>
            </div>
        </div>
    );
};

export default Login;