import React, { useEffect, useState } from "react";
import BASE from "../../api/apis";
import axios from "axios";
import {
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Table,
  TableBody,
  Avatar,
  Box,
  LinearProgress,
} from "@mui/material";
import { v4 } from "uuid";
import { useNavigate } from "react-router-dom";

const ManageCards = () => {
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const DeleteCard = (id, obj) => {
    const dta = [...cards];
    const index = dta.indexOf(obj);
    dta.splice(index, 1);
    setCards(dta);
    axios
      .delete(`${BASE}/visitingcard/${id}`)
      .then((resp) => {
        alert(resp.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${BASE}/visitingcard`)
      .then((resp) => {
        setLoading(false);
        setCards(resp.data.cards);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div>
      <h1 className="p-2 text-center font-bold uppercase">Visiting Cards</h1>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Logo</TableCell>
              <TableCell>Profile</TableCell>
              <TableCell>Firstname</TableCell>
              <TableCell>Lastname</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && (
              <TableRow>
                <TableCell colSpan={6}>
                    <LinearProgress />
                </TableCell>
              </TableRow>
            )}
            {cards.length > 0 && (
              <>
                {cards.map((crd) => {
                  return (
                    <TableRow key={v4()}>
                      <TableCell>{crd._id}</TableCell>
                      <TableCell>
                        <Avatar src={crd.logo} />
                      </TableCell>
                      <TableCell>
                        <Avatar src={crd.profile} />
                      </TableCell>
                      <TableCell>
                        <p>{crd.firstname}</p>
                      </TableCell>
                      <TableCell>
                        <p>{crd.lastname}</p>
                      </TableCell>
                      <TableCell>
                        <Box className="flex flex-row gap-2">
                          <button
                            className="p-2 text-white text-center bg-blue-800"
                            onClick={() => navigate(`/viewcard/${crd._id}`)}
                          >
                            View
                          </button>
                          <button
                            className="p-2 text-white text-center bg-red-800"
                            onClick={() => DeleteCard(crd._id, crd)}
                          >
                            Delete
                          </button>
                          <button className="p-2 text-white text-center bg-blue-800" onClick={()=>navigate(`/admin/home/editcard/${crd._id}`)}>
                            Edit
                          </button>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ManageCards;
